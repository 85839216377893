import * as L from 'leaflet';

export default L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
const ASSET_URL = "../../public/";

export const BASE_URL = "https://limspakistan.com/admin/api";
export const END_POINT ="/contactUs";
export const registerFarm ="/registerFarm";
export const getUserFarm ="/getUserFarm";
export const capSiteKey = "6LcU3lgoAAAAANQJflu8EL_EmVZWGIoZirN67Ag2"

export const ASSET_IMAGE = "https://dmenjsnbha54b.cloudfront.net/images"
export const TUTORIAL_IMAGE = "https://dmenjsnbha54b.cloudfront.net/tutorials"

export const NDREColorMap = "fae7389e984057b9750bfd0dc623f758" 
export const NDRELevel = "-1,1"
 


export const NDVIColorMap = "a9bc6eceeef2a13bb88a7f641dca3aa0" 
 

export const NDVILevel = "-1,1"
export const NDVICOLORLEGEND = {
  "colors": [
      [
          0.95, 1.00,
          "#00693b",
          "Better to use NDRE"
      ],
      [
          0.90, 0.95,
          "#007e47",
          "Dense vegetation"
      ],
      [
          0.85, 0.90,
          "#009755",
          "Dense vegetation"
      ],
      [
          0.80, 0.85,
          "#14aa60",
          "Dense vegetation"
      ],
      [
          0.75, 0.80,
          "#53bd6b",
          "Dense vegetation"
      ],
      [
          0.70, 0.75,
          "#77ca6f",
          "Dense vegetation"
      ],
      [
          0.65, 0.70,
          "#9bd873",
          "Dense vegetation"
      ],
      [
          0.60, 0.65,
          "#b9e383",
          "Dense vegetation"
      ],
      [
          0.55, 0.60,
          "#d5ef94",
          "Moderate vegetation"
      ],
      [
          0.50, 0.55,
          "#eaf7ac",
          "Moderate vegetation"
      ],
      [
          0.45, 0.50,
          "#fdfec2",
          "Moderate vegetation"
      ],
      [
          0.40, 0.45,
          "#ffefab",
          "Moderate vegetation"
      ],
      [
          0.35, 0.40,
          "#ffe093",
          "Sparse vegetation"
      ],
      [
          0.30, 0.35,
          "#ffc67d",
          "Sparse vegetation"
      ],
      [
          0.25, 0.30,
          "#ffab69",
          "Sparse vegetation"
      ],
      [
          0.20, 0.25,
          "#ff8d5a",
          "Sparse vegetation"
      ],
      [
          0.15, 0.20,
          "#fe6c4a",
          "Open soil"
      ],
      [
          0.10, 0.15,
          "#ef4c3a",
          "Open soil"
      ],
      [
          0.05, 0.10,
          "#e02d2c",
          "Open soil"
      ],
      [
          -0.05, 0.05,
          "#c5142a",
          "Open soil"
      ],
      [
           ,  ,
          "#fff",
          "Clouds"
      ]
  ],
  "type": "discrete",
  "thresholds_type": "absolute",
  "colors_quantity": 22
};


export const MSAVIColorMap = "5792d8fc39a4598f8a2c67b300982076" 
export const MSAVILevel = "-1,1"
export const MSAVICOLORLEGEND = {
  "colors": [
      [
          -1.0,
          "#ad0028"
      ],
      [
          0.0,
          "#fe6c4a"
      ],
      [
          0.02,
          "#ff8d5a"
      ],
      [
          0.04,
          "#ffab69"
      ],
      [
          0.06,
          "#ffc67d"
      ],
      [
          0.08,
          "#ffe093"
      ],
      [
          0.1,
          "#ffefab"
      ],
      [
          0.12,
          "#fdfec2"
      ],
      [
          0.15,
          "#eaf7ac"
      ],
      [
          0.17,
          "#d5ef94"
      ],
      [
          0.2,
          "#b9e383"
      ],
      [
          0.22,
          "#9bd873"
      ],
      [
          0.25,
          "#77ca6f"
      ],
      [
          0.27,
          "#53bd6b"
      ],
      [
          0.3,
          "#14aa60"
      ],
      [
          0.35,
          "#009755"
      ],
      [
          0.4,
          "#007e47"
      ],
      [
          0.5,
          "#00673a"
      ],
      [
          0.6,
          "#005530"
      ],
      [
          0.7,
          "#004728"
      ],
      [
          1.0,
          "#004728"
      ]
  ],
  "type": "discrete",
  "thresholds_type": "absolute",
  "colors_quantity": 21
}


export const RECIColorMap = "fb5d32c26f571d52ea663a4a8f1e38e0" 
export const RECILevel = "0,10"
export const RECICOLORLEGEND = {
  "colors": [
      [
          0.0,
          "#a50026"
      ],
      [
          0.5,
          "#bc1626"
      ],
      [
          1.0,
          "#d62f26"
      ],
      [
          1.5,
          "#e54d34"
      ],
      [
          2.0,
          "#f46d43"
      ],
      [
          2.5,
          "#f88e52"
      ],
      [
          3.0,
          "#fcac60"
      ],
      [
          3.5,
          "#fdc675"
      ],
      [
          4.0,
          "#fee08b"
      ],
      [
          4.5,
          "#feefa5"
      ],
      [
          5.0,
          "#fefebd"
      ],
      [
          5.5,
          "#ecf7a5"
      ],
      [
          6.0,
          "#d9ef8b"
      ],
      [
          6.5,
          "#bfe37a"
      ],
      [
          7.0,
          "#a4d869"
      ],
      [
          7.5,
          "#84ca66"
      ],
      [
          8.0,
          "#66bd63"
      ],
      [
          8.5,
          "#3faa59"
      ],
      [
          9.0,
          "#19974f"
      ],
      [
          9.5,
          "#0c7e42"
      ],
      [
          10.0,
          "#0c7e42"
      ]
  ],
  "type": "discrete",
  "thresholds_type": "absolute",
  "colors_quantity": 21
}


export const NDMIColorMap = "a53f2c53d3cbc1d424c35ac8dc883644" 
export const NDMILevel = "0,1"
export const NDMICOLORLEGEND = {
  "colors": [
      [
          -1,
          "#af998c"
      ],
      [
          -0.9,
          "#b49e95"
      ],
      [
          -0.8,
          "#baa49e"
      ],
      [
          -0.7,
          "#bfaaa8"
      ],
      [
          -0.6,
          "#c5b0b2"
      ],
      [
          -0.5,
          "#cbb6bc"
      ],
      [
          -0.4,
          "#d0bbc5"
      ],
      [
          -0.3,
          "#d6c1cf"
      ],
      [
          -0.2,
          "#cbb9d2"
      ],
      [
          -0.1,
          "#baadd3"
      ],
      [
          0,
          "#a8a0d5"
      ],
      [
          0.1,
          "#9894d6"
      ],
      [
          0.2,
          "#8788d7"
      ],
      [
          0.3,
          "#767bd8"
      ],
      [
          0.4,
          "#646ed9"
      ],
      [
          0.5,
          "#5362da"
      ],
      [
          0.6,
          "#4356db"
      ],
      [
          0.7,
          "#3249dc"
      ],
      [
          0.8,
          "#213ddd"
      ],
      [
          0.9,
          "#0f30de"
      ],
      [
          1,
          "#0f30de"
      ]
  ],
  "type": "discrete",
  "thresholds_type": "absolute",
  "colors_quantity": 21
}


export const NDVIColor = {"colors": [[-1.0, "#ad0028"], [0.05, "#c5142a"], [0.1, "#e02d2c"], [0.15, "#ef4c3a"], [0.2, "#fe6c4a"], [0.25, "#ff8d5a"], [0.3, "#ffab69"], [0.35, "#ffc67d"], [0.4, "#ffe093"], [0.45, "#ffefab"], [0.5, "#fdfec2"], [0.55, "#eaf7ac"], [0.6, "#d5ef94"], [0.65, "#b9e383"], [0.7, "#9bd873"], [0.75, "#77ca6f"], [0.8, "#53bd6b"], [0.85, "#14aa60"], [0.9, "#009755"], [0.95, "#007e47"], [1.0, "#007e47"]], "type": "discrete", "thresholds_type": "absolute", "colors_quantity": 21}
export const NDVILegend = {
  "colors": [
    ["0.95-1.00", "Better to use NDRE", "#007e47"],
    ["0.90-0.95", "Dense vegetation", "#009755"],
    ["0.85-0.90", "Dense vegetation", "#14aa60"],
    ["0.80-0.85", "Dense vegetation", "#53bd6b"],
    ["0.75-0.80", "Dense vegetation", "#77ca6f"],
    ["0.70-0.75", "Dense vegetation", "#9bd873"],
    ["0.65-0.70", "Dense vegetation", "#b9e383"],
    ["0.60-0.65", "Dense vegetation", "#d5ef94"],
    ["0.55-0.60", "Moderate vegetation", "#eaf7ac"],
    ["0.50-0.55", "Moderate vegetation", "#fdfec2"],
    ["0.45-0.50", "Moderate vegetation", "#ffefab"],
    ["0.40-0.45", "Moderate vegetation", "#ffe093"],
    ["0.35-0.40", "Sparse vegetation", "#ffc67d"],
    ["0.30-0.35", "Sparse vegetation", "#ffab69"],
    ["0.25-0.30", "Sparse vegetation", "#ff8d5a"],
    ["0.20-0.25", "Sparse vegetation", "#fe6c4a"],
    ["0.15-0.20", "Open soil", "#ef4c3a"],
    ["0.10-0.15", "Open soil", "#e02d2c"],
    ["0.05-0.10", "Open soil", "#c5142a"],
    ["-1.00-0.05", "Open soil", "#ad0028"],
    [" ", "Clouds", "#fff"]
  ]
}

export const NDMILegend = {
  "colors": [
    ["0.90-1.00", "", "#0f30de"],
    ["0.80-0.90", "", "#0f30de"],
    ["0.70-0.80", "", "#213ddd"],
    ["0.60-0.70", "", "#3249dc"],
    ["0.50-0.60", "", "#4356db"],
    ["0.40-0.50", "", "#5362da"],
    ["0.30-0.40", "", "#646ed9"],
    ["0.20-0.30", "", "#767bd8"],
    ["0.10-0.20", "", "#8788d7"],
    ["0.00-0.10", "", "#9894d6"],
    ["-0.10-0.00", "", "#af998c"],
    ["-0.20--0.10", "", "#cbb9d2"],
    ["-0.30--0.20", "", "#d6c1cf"],
    ["-0.40--0.30", "", "#d0bbc5"],
    ["-0.50--0.40", "", "#cbb6bc"],
    ["-0.60--0.50", "", "#c5b0b2"],
    ["-0.70--0.60", "", "#bfaaa8"],
    ["-0.80--0.70", "", "#baa49e"],
    ["-0.90--0.80", "", "#b49e95"],  
  ]
}



export const NDREColor = {"colors": [[-1.0, "#ad0028"], [0.05, "#c5142a"], [0.1, "#e02d2c"], [0.15, "#ef4c3a"], [0.2, "#fe6c4a"], [0.25, "#ff8d5a"], [0.3, "#ffab69"], [0.35, "#ffc67d"], [0.4, "#ffe093"], [0.45, "#ffefab"], [0.5, "#fdfec2"], [0.55, "#eaf7ac"], [0.6, "#d5ef94"], [0.65, "#b9e383"], [0.7, "#9bd873"], [0.75, "#77ca6f"], [0.8, "#53bd6b"], [0.85, "#14aa60"], [0.9, "#009755"], [0.95, "#007e47"], [1.0, "#007e47"]], "type": "discrete", "thresholds_type": "absolute", "colors_quantity": 21}
export const NDRELegend = {
  "colors": [
    ["0.95-1.00", "Dense vegetation", "#007e47"],
    ["0.90-0.95", "Dense vegetation", "#009755"],
    ["0.85-0.90", "Dense vegetation", "#14aa60"],
    ["0.80-0.85", "Dense vegetation", "#53bd6b"],
    ["0.75-0.80", "Dense vegetation", "#77ca6f"],
    ["0.70-0.75", "Dense vegetation", "#9bd873"],
    ["0.65-0.70", "Dense vegetation", "#b9e383"],
    ["0.60-0.65", "Dense vegetation", "#d5ef94"],
    ["0.55-0.60", "Moderate vegetation", "#eaf7ac"],
    ["0.50-0.55", "Moderate vegetation", "#fdfec2"],
    ["0.45-0.50", "Moderate vegetation", "#ffefab"],
    ["0.40-0.45", "Moderate vegetation", "#ffe093"],
    ["0.35-0.40", "Sparse vegetation", "#ffc67d"],
    ["0.30-0.35", "Sparse vegetation", "#ffab69"],
    ["0.25-0.30", "Sparse vegetation", "#ff8d5a"],
    ["0.20-0.25", "Sparse vegetation", "#fe6c4a"],
    ["0.15-0.20", "Open soil", "#ef4c3a"],
    ["0.10-0.15", "Open soil", "#e02d2c"],
    ["0.05-0.10", "Open soil", "#c5142a"],
    ["-1.00-0.05", "Open soil", "#ad0028"], 
  ]
}
export const MSAVI = '(2*NIR+1-sqrt((2*NIR+1)^2-8*(NIR-RED)))';
export const MSAVILegend = {
  "colors": [
    ['0.70 − 1.00',  'Better to use NDVI',"#004728" ],
    ['0.60 − 0.70',  'Dense vegetation', "#004728" ],
    ['0.50 − 0.60',  'Dense vegetation', "#004728" ],
    ['0.40 − 0.50',  'Dense vegetation',"#005530"],
    ['0.35 − 0.40',  'Dense vegetation',"#009755"],
    ['0.30 − 0.35',  'Moderate vegetation',"#14aa60"],
    ['0.27 − 0.30',  'Moderate vegetation', "#53bd6b" ],
    ['0.25 − 0.27',  'Moderate vegetation', "#77ca6f"],
    ['0.22 − 0.25',  'Moderate vegetation',"#9bd873"],
    ['0.20 − 0.22',  'Moderate vegetation', "#b9e383" ],
    ['0.17 − 0.20',  'Low vegetation',"#d5ef94" ],
    ['0.15 − 0.17',  'Low vegetation',"#eaf7ac" ],
    ['0.12 − 0.15',  'Low vegetation', "#fdfec2"],
    ['0.10 − 0.12',  'Sparse vegetation',"#ffefab" ],
    ['0.08 − 0.10',  'Sparse vegetation', "#ffe093"],
    ['0.06 − 0.08',  'Sparse vegetation', "#ffc67d"],
    ['0.04 − 0.06',  'Sprouts', "#ffab69" ],
    ['0.02 − 0.04',  'Sprouts', "#ff8d5a"],
    ['0.00 − 0.02',  'Emerging', "#fe6c4a" ],
    ['-1.00 − 0.00',  'Bare soil', "#ad0028" ],
    ['',  'Bare soil', "#fff" ],
]
}

 
export const MSAVIColor = {
  "colors": [[-1.0, "#ad0028"], [0.0, "#fe6c4a"], [0.02, "#ff8d5a"], [0.04, "#ffab69"],
  [0.06, "#ffc67d"], [0.08, "#ffe093"], [0.1, "#ffefab"], [0.12, "#fdfec2"], [0.15, "#eaf7ac"], [0.17, "#d5ef94"], [0.2, "#b9e383"],
  [0.22, "#9bd873"], [0.25, "#77ca6f"], [0.27, "#53bd6b"], [0.3, "#14aa60"], [0.35, "#009755"], [0.4, "#007e47"], [0.5, "#00673a"],
  [0.6, "#005530"], [0.7, "#004728"], [1.0, "#004728"]], "type": "discrete", "thresholds_type": "absolute", "colors_quantity": 21
}
export const RECIColor = {
  "colors": [[0.0, "#a50026"], [0.5, "#bc1626"], [1.0, "#d62f26"], [1.5, "#e54d34"], [2.0, "#f46d43"],
  [2.5, "#f88e52"], [3.0, "#fcac60"], [3.5, "#fdc675"], [4.0, "#fee08b"], [4.5, "#feefa5"], [5.0, "#fefebd"], [5.5, "#ecf7a5"],
  [6.0, "#d9ef8b"], [6.5, "#bfe37a"], [7.0, "#a4d869"], [7.5, "#84ca66"], [8.0, "#66bd63"], [8.5, "#3faa59"], [9.0, "#19974f"],
  [9.5, "#0c7e42"], [10.0, "#0c7e42"]], "type": "discrete", "thresholds_type": "absolute", "colors_quantity": 21
}

export const RECILegend = {
  "colors": [
    [ '9.50 − 10.00', 'Healthy vegetation', "#0c7e42"],
    [ '9.00 − 9.50', 'Healthy vegetation', "#19974f" ],
    [ '8.50 − 9.00', 'Healthy vegetation',"#3faa59" ],
    [ '8.00 − 8.50', 'High amount of chlorophyll',"#66bd63"],
    [ '7.50 − 8.00', 'High amount of chlorophyll',  "#84ca66" ],
    [ '7.00 − 7.50', 'High amount of chlorophyll', "#a4d869"],
    [ '6.50 − 7.00', 'High amount of chlorophyll', "#bfe37a" ],
    [ '6.00 − 6.50', 'Moderate amount of chlorophyll', "#d9ef8b"],
    [ '5.50 − 6.00', 'Moderate amount of chlorophyll', "#ecf7a5"],
    [ '5.00 − 5.50', 'Moderate amount of chlorophyll',"#fefebd" ],
    [ '4.50 − 5.00', 'Moderate amount of chlorophyll', "#feefa5"],
    [ '4.00 − 4.50', 'Low amount of chlorophyll', "#fee08b" ],
    [ '3.50 − 4.00', 'Low amount of chlorophyll', "#fdc675" ],
    [ '3.00 − 3.50', 'Low amount of chlorophyll',"#fcac60" ],
    [ '2.50 − 3.00', 'Low amount of chlorophyll', "#f88e52" ],
    [ '2.00 − 2.50', 'Low amount of chlorophyll',"#f46d43" ],
    [ '1.50 − 2.00', 'Open soil', "#e54d34" ],
    [ '1.00 − 1.50', 'Open soil',  "#d62f26"],
    [ '0.50 − 1.00', 'Open soil',  "#bc1626" ],
    [ '0.00 − 0.50', 'Open soil',  "#a50026" ]

]
}
export const NDMIColor ={"colors": [[-1, "#af998c"], [-0.9, "#b49e95"], [-0.8, "#baa49e"], [-0.7, "#bfaaa8"], [-0.6, "#c5b0b2"], [-0.5, "#cbb6bc"], [-0.4, "#d0bbc5"], [-0.3, "#d6c1cf"], [-0.2, "#cbb9d2"], [-0.1, "#baadd3"], [0, "#a8a0d5"], [0.1, "#9894d6"], [0.2, "#8788d7"], [0.3, "#767bd8"], [0.4, "#646ed9"], [0.5, "#5362da"], [0.6, "#4356db"], [0.7, "#3249dc"], [0.8, "#213ddd"], [0.9, "#0f30de"], [1, "#0f30de"]], "type": "discrete", "thresholds_type": "absolute", "colors_quantity": 21}
// MIN_MAX=0.0,10.0 export const capSiteKey = "6LeAblAoAAAAAF1ih3b-3v-oHDKG4AmTV_bSui7K"; 
// export const INTRO_VIDEO = ASSET_URL + "videos/intro.mp4";
// export const WEATHER_MAPS = ASSET_URL + "images/weather_maps.png";
// export const SOIL = ASSET_URL + "images/soil.jpg";
// export const VEGETATION_IMAGE = ASSET_URL + "images/vegetation_and_monitoring.jpeg";
// export const SMART_SOLUTIONS = ASSET_URL + "images/SmartSolutions.png";
// export const KISSAN_01 = ASSET_URL + "kissan/kissan01.jpeg"
// export const KISSAN_02 = ASSET_URL + "kissan/kissan02.jpeg"
// export const KISSAN_03 = ASSET_URL + "kissan/kissan03.jpeg"
// export const BACKGROUND_WHOLE = ASSET_URL + "images/background_whole.jpg"
// export const FARM_RECOMMENDATION = ASSET_URL + "images/farm_recommendation.jpg"
// export const WHITE_LOGO = ASSET_URL + "images/white_logo.svg"
// export const COLORED_LOGO = ASSET_URL + "images/logo.png"
// export const COTTON = ASSET_URL + "images/Cotton.jpg";
// export const LAND_CONS = ASSET_URL + "images/LandCons.jpg"
// export const DAM = ASSET_URL + "images/dam.png"
// export const PEST_SCOUTING = ASSET_URL + "images/PestScouting.png"
// export const PRESS_RELEASE_00 = ASSET_URL + "press/press-release00.mp4";
// export const PRESS_RELEASE_001 = ASSET_URL + "press/press-release001.mp4";
// export const PRESS_RELEASE_01 = ASSET_URL + "press/press-release01.mp4";
// export const PRESS_RELEASE_03 = ASSET_URL + "press/press-release03.mp4";
// export const PRESS_RELEASE_04 = ASSET_URL + "press/press-release04.mp4";
// export const PRESS_RELEASE_05 = ASSET_URL + "press/press-release05.mp4";
// export const PRESS_RELEASE_06 = ASSET_URL + "press/press-release06.mp4";
// export const PRESS_RELEASE_07 = ASSET_URL + "press/press-release07.mp4";
// export const PRESS_RELEASE_08 = ASSET_URL + "press/press-release08.mp4";
// export const PRESS_RELEASE_09 = ASSET_URL + "press/press-release09.mp4";
// export const PRESS_RELEASE_10 = ASSET_URL + "press/press-release10.mp4";
// export const PRESS_RELEASE_11 = ASSET_URL + "press/press-release11.mp4";
// export const THUMBNAIL_00 = ASSET_URL + "press/Thumbnail00.png";
// export const THUMBNAIL_001 = ASSET_URL + "press/Thumbnail001.png";
// export const THUMBNAIL_01 = ASSET_URL + "press/Thumbnail01.png";
// export const THUMBNAIL_03 = ASSET_URL + "press/Thumbnail03.png";
// export const THUMBNAIL_04 = ASSET_URL + "press/Thumbnail04.png";
// export const THUMBNAIL_05 = ASSET_URL + "press/Thumbnail05.png";
// export const THUMBNAIL_06 = ASSET_URL + "press/Thumbnail06.png";
// export const THUMBNAIL_07 = ASSET_URL + "press/Thumbnail07.png";
// export const THUMBNAIL_08 = ASSET_URL + "press/Thumbnail08.png";
// export const THUMBNAIL_09 = ASSET_URL + "press/Thumbnail09.png";
// export const THUMBNAIL_10 = ASSET_URL + "press/Thumbnail10.png";
// export const THUMBNAIL_11 = ASSET_URL + "press/Thumbnail11.png";
// export const RECENT_EVENT_01 = ASSET_URL + "press/recent_event1.jpeg";
// export const RECENT_EVENT_02 = ASSET_URL + "press/recent_event2.jpeg";
// export const RECENT_EVENT_03 = ASSET_URL + "press/recent_event3.jpeg";
// export const RECENT_EVENT_04 = ASSET_URL + "press/recent_event4.jpeg";
// export const RECENT_EVENT_05 = ASSET_URL + "press/recent_event5.jpeg";
// export const RECENT_EVENT_06 = ASSET_URL + "press/recent_event6.jpeg";
// export const RECENT_EVENT_07 = ASSET_URL + "press/recent_event7.jpeg";
// export const RECENT_EVENT_08 = ASSET_URL + "press/recent_event8.jpeg";
// export const RECENT_EVENT_09 = ASSET_URL + "press/recent_event9.jpeg";
// export const PRESS_RELEASE_IMAGE_01 = ASSET_URL + "press/press_release1.jpeg";
// export const PRESS_RELEASE_IMAGE_02 = ASSET_URL + "press/press_release2.jpeg";
// export const PRESS_RELEASE_IMAGE_03 = ASSET_URL + "press/press_release3.jpeg";
// export const PRESS_RELEASE_IMAGE_04 = ASSET_URL + "press/press_release4.jpeg";
// export const PRESS_RELEASE_IMAGE_05 = ASSET_URL + "press/press_release5.jpeg";
// export const PRESS_RELEASE_IMAGE_06 = ASSET_URL + "press/press_release6.jpeg";
// export const PRESS_RELEASE_IMAGE_07 = ASSET_URL + "press/press_release7.jpeg";
// export const PRESS_RELEASE_IMAGE_08 = ASSET_URL + "press/press_release8.jpeg";
// export const VIDEO_GALLERY_THUMBNAIL11 = ASSET_URL +  "videogallery/images/news11.png";
// export const VIDEO_GALLERY_THUMBNAIL12 = ASSET_URL + "videogallery/images/news12.png";
// export const GALLERY_VIDEO11 = ASSET_URL + "videogallery/video/video11.mp4";
// export const GALLERY_VIDEO12 = ASSET_URL + "videogallery/video/video12.mp4";


// THEME COLORS

export const Primary = "rgb(255, 255, 255)"
export const Secondary = "rgb(240, 244, 249)"
export const THEME_TEXT_COLOR = "#333;"
export const SUB_TEXT_COLOR="#4f5560";
export const THEME_HEADING_COLOR= ""

