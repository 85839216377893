import { Grid, Typography,Alert, Box } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ContactForm from "./ContactForm";
import { useState,useEffect } from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Title from "../utils/Title";
import { Primary } from "../utils/constants";

export default function ContactUs() {
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const [severity,setSeverity] = useState('')
  const handleFormSubmit = (condition) => {
    if(condition){
      setMessage('Message sent successfully')
      setSeverity('success')
    }else{
      setMessage('Message sent failed')
      setSeverity('error')
    }
    setFormSubmitted(true);
  };
  useEffect(() => {
    if (isFormSubmitted) {
      const timeout = setTimeout(() => {
        setFormSubmitted(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [isFormSubmitted]);
  return (
    <>
    <Title title={'Contact Us'}/>
      <Grid
        container
        justifyContent="center"
        alignItems="center"  
        height={"250px"}
        style={{  background:Primary, position: "absolute",top:0 }}
      >
        <Grid
          item
          xs={12}
          md={5}
          style={{
           paddingTop:40
            
          }}
        >
          {/* <Typography
            align="start"
            variant="h5"
            fontFamily="Poppins"
            style={{
              color: "rgb(14, 151, 255)",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "24px",
            }}
          >
            <strong>Contact us</strong>
          </Typography> */}
          <Typography
            align="start"
            variant="h5"
            fontFamily="Poppins"
            style={{
              color: "rgb(255, 255, 255)",
              fontSize: "28px",
              fontWeight: "700",
              lineHeight: "48px",paddingBottom:10,textAlign:'center'
            }}
          >
            <strong>Have Questions? Get in touch</strong>
          </Typography>
          {/* <Typography
            align="start"
            variant="h5"
            fontFamily="Poppins"
            style={{
              color: "rgb(255, 255, 255)",
              fontSize: "32px",
              fontWeight: "700",
              lineHeight: "48px", 
            }}
          >
            <strong>Get in touch</strong>
          </Typography> */}

          <Box display="flex" alignItems="center" gap={4} flexWrap="wrap">
            <Typography fontFamily="Poppins" style={{ color: "#fff", display: "flex", alignItems: "center" }}>
              <LocationOnOutlinedIcon style={{ color: "rgb(135, 120, 73)", marginRight: 8 }} />
              <strong>Meadow Heights 3048, Australia</strong>
            </Typography>

            <Typography fontFamily="Poppins" style={{ color: "#fff", display: "flex", alignItems: "center" }}>
              <EmailIcon style={{ color: "rgb(135, 120, 73)", marginRight: 8 }} />
              <strong><a href="mailto:contact@escan-systems.com">contact@escan-systems.com</a> </strong>
            </Typography>


            <Typography fontFamily="Poppins" color="text.secondary">
              <WhatsAppIcon style={{ color: "rgb(135, 120, 73)", marginRight: 8 }} />
              <strong>+61 452 284 468</strong>
            </Typography>
          </Box>



          {/* <Typography
            className="mb-4"
            fontFamily="Poppins"
            color="#fff"
          >
           Meadow Heights 3048, Australia
          </Typography> */}
       
          {/* <Typography
            className="mb-4"
            fontFamily="Poppins"
            color="#fff"
          >
            contact@escan-systems.com
          </Typography> */}
          {/* <Typography className="mb-2" fontFamily="Poppins">
            <PhoneIcon className="me-3" style={{ color: "rgb(135, 120, 73)" }} />
            <strong>Phone</strong>
          </Typography> */}
          {/* <Typography
            className="mb-4"
            fontFamily="Poppins"
            color="text.secondary"
          >
            051-9286355
          </Typography> */}
          {/* <Typography className="mb-2" fontFamily="Poppins">
            <WhatsAppIcon className="me-3" style={{ color: "rgb(135, 120, 73)" }} />
            <strong>Whatsapp</strong>
          </Typography>
          <Typography
            className="mb-4"
            fontFamily="Poppins"
            color="text.secondary"
          >
            0336-4996661
          </Typography> */}

         </Grid>
        {/* <Grid
          item
          xs={12}
          md={6}
          style={{
            padding: "20px",
          }}
        >
          <ContactForm onFormSubmit={handleFormSubmit}
           boxShadow={true} 
           location={"na"} 
           area={"na"}/>

 

      
        </Grid> */}
      </Grid>
      {isFormSubmitted && <Alert severity={severity}
          role="presentation"
          sx={{
            width:{
              lg:'18%'
            },
            position: "fixed",
            bottom: "5%",
            left: "2%",
            zIndex: 1,
            animation: 'fade-out 5s forwards'
          }}
          >
            {message}</Alert> }
    </>
  );
}