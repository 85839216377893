import axios from "axios";
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Bounce, ToastContainer, toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import {
  MapContainer,
  TileLayer,
  ImageOverlay,
  Marker,
  Popup,
} from "react-leaflet";
import cropsArray from "./cropsArray";
import { CancelOutlined } from "@mui/icons-material";
import { Primary, Secondary, THEME_TEXT_COLOR } from "../utils/constants";

export default function UpdateField({
  centerCoordinates,
  SentimageURL,
  bounds,
  farmID,
  farmArea,
  setFieldData,
  showUpdateFieldModal,
  setShowUpdateFieldModal,
}) {
  const [farmName, setFarmName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [sowingDate, setSowingDate] = useState(null);
  const [notes, setNotes] = useState("");

  const handleFarmNameChange = (event) => {
    setFarmName(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSowingDateChange = (date) => {
    setSowingDate(date);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleSave = async () => {
    if (!farmName || !selectedOption || !sowingDate) {
      toast("Please fill in all fields", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return;
    }
    setShowUpdateFieldModal(false);
    const requestData = {
      farmName: farmName,
      selectedOption: selectedOption,
      sowingDate: sowingDate,
      farmID: farmID,
      farmArea:farmArea
    };
    try {
      const response = await axios.post("https://escan-systems.com/api/updateField", requestData);
      setFieldData({
        crop_type: response.data.properties.years_data[0].crop_type,
        area: response.data.properties.area,
      });

      toast("Your farm has been registered", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });

    } catch (error) {
      console.error(
        "Error in request:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <Dialog
      sx={{
        borderRadius: '15px',

        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            background: Primary,
            width: "100%",
            maxWidth: "800px",
          },
        },
      }}
      open={showUpdateFieldModal}
      onClose={() => setShowUpdateFieldModal(false)}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: Primary,

          boxShadow: 24,
          borderRadius: '15px',
          color: THEME_TEXT_COLOR,
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center", // Centers the text horizontally
            alignItems: "center", // Vertically aligns the items
            position: "relative",
            boxShadow: "0px 4px 4px -2px rgba(0,0,0,0.2)",
            padding: "15px 15px 10px",
          }}
        >
          <Typography variant="h6">Update Field</Typography>
          <CloseIcon
            color={"white"}
            onClick={() => setShowUpdateFieldModal(false)}
            style={{
              cursor: "pointer",
              position: "absolute", // Positions the icon absolutely inside the Box
              right: 15, // Aligns the icon to the right side
            }}
          />
        </Box>
        <DialogContent>
          <Box style={{ width: "100%", display: "flex", borderRadius: '5px' }}>
            <MapContainer
              center={centerCoordinates}
              zoom={19}
              style={{ height: "400px", width: "400px", borderRadius: '15px' }}
              scrollWheelZoom={true}
              id="map-container"
            >
              <TileLayer
                url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                maxZoom={15}
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
              />
              <ImageOverlay
                url={SentimageURL}
                bounds={bounds}
                position={centerCoordinates}
                options={{
                  crossOrigin: "anonymous",
                  interactive: true,
                  boundsOptions: {
                    paddingTopLeft: [0, 0],
                    paddingBottomRight: [0, 0],
                  },
                }}
              />
            </MapContainer>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
                marginLeft: "10px",
              }}
            >
              <TextField
                label="Field Name"
                variant="outlined"
                fullWidth
                value={farmName}
                onChange={handleFarmNameChange}
                sx={{
                  input: {
                    color: THEME_TEXT_COLOR,
                    background: Secondary,
                    borderRadius: '5px',

                  },
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgb(135, 120, 73)",
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgb(115, 100, 60)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgb(115, 100, 60)',
                    },
                  },
                }}
                InputLabelProps={{
                  style: { color: THEME_TEXT_COLOR},
                }}
                InputProps={{
                  style: { color: THEME_TEXT_COLOR },
                }}
              />

              {/* Dropdown for Option */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel
                  htmlFor="grouped-native-select"
                  style={{ color: THEME_TEXT_COLOR}}
                >
                  Crops
                </InputLabel>
                <Select
                  native
                  value={selectedOption}
                  onChange={handleOptionChange}
                  id="grouped-native-select"
                  label="Crops"
                  sx={{
                    backgroundColor: Secondary,
                    color: THEME_TEXT_COLOR,
                    "& .MuiInputBase-root": {
                      color: THEME_TEXT_COLOR,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgb(135, 120, 73)",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgb(135, 120, 73)",
                    },


                    "& .MuiSvgIcon-root": {
                      color: THEME_TEXT_COLOR,
                    },
                  }}
                >
                  <option
                    aria-label="None"
                    value=""
                    style={{ backgroundColor: Secondary}}
                  />
                  {cropsArray.map((crop) => (
                    <option
                      key={crop}
                      value={crop}
                      style={{
                        color:THEME_TEXT_COLOR,
                        backgroundColor: Secondary,
                      }}
                    >
                      {crop}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Date Picker for Sowing Date */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    fullWidth
                    label="Select Sowing Date"
                    value={sowingDate}
                    onChange={handleSowingDateChange}
                    sx={{
                      width: "100%",
                      backgroundColor: Secondary,
                      "& .MuiInputLabel-root": {
                        color: THEME_TEXT_COLOR,
                      },
                      "& .MuiInputBase-root": {
                        color: THEME_TEXT_COLOR,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgb(135, 120, 73)",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgb(135, 120, 73)",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "rgb(135, 120, 73)",
                      },
                      "& .MuiSvgIcon-root": {
                        color: THEME_TEXT_COLOR,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>

              {/* Notes Text Field */}
              <TextField
                label="Notes"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={notes}
                onChange={handleNotesChange}
                sx={{
                  mt: 2,
                  mb: 2,
                  color: THEME_TEXT_COLOR,
                  background: Secondary,
                  '& .MuiOutlinedInput-root': {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgb(135, 120, 73)",
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgb(135, 120, 73)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgb(135, 120, 73)',
                    },
                  },
                }}
                InputLabelProps={{
                  style: { color: THEME_TEXT_COLOR},
                }}
                InputProps={{
                  style: { color:THEME_TEXT_COLOR },
                }}
              />

              {/* Save Button */}
              <Button
                variant="contained"

                style={{
                  background: "#877849",
                  color: { THEME_TEXT_COLOR },
                  marginTop: "3px",
                  width: "100%",
                  padding: "12px",
                  borderRadius: "15px",
                  fontWeight: "bold",
                  fontSize: "15px",
                  textTransform: "capitalize",
                }}
                onClick={handleSave}
              >
                Update
              </Button>

            </Box>
          </Box>

          {/* 
                <>
                
                  <ImageOverlay
                    url={SentimageURL}
                    bounds={bounds}
                    onMouseOver={(e) => {
                      console.log(e.latlng)
                    }}
                    options={{
                      crossOrigin: 'anonymous',
                      interactive: true,
                      boundsOptions: { paddingTopLeft: [0, 0], paddingBottomRight: [0, 0] },
                    }}
                  />
                </> */}
        </DialogContent>
      </Box>
    </Dialog>
  );
}
