import Toolbar from "@mui/material/Toolbar";
import EARTH_IMAGE from "../../public/images/earth.png"
import { Box, Typography, Grid, Tooltip } from "@mui/material"; 
import { useWindowSize } from "@uidotdev/usehooks";
import { ASSET_IMAGE, Secondary, SUB_TEXT_COLOR, THEME_TEXT_COLOR } from "../utils/constants";
import {useRouter} from "../routes/hooks";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion"
import { GradualSpacing, RotateWords, TypingEffect } from "../utils/Rotate";
import sattelites from "../../public/images/sattelites.png";


export default function HomeComponent1() {
  const router = useRouter();
  const windowSize = useWindowSize();
  const [tooltip, setTooltip] = useState({ visible: false, text: "", x: 0, y: 0 }); 
  const areas = [
    { name: "Monitoring", coords: "1268,3575,336,2619", description: "Real-time farm monitoring." },
    { name: "Weather Analytics", coords: "1114,3830,1876,4682", description: "Weather forecasts for better planning." },
    { name: "AI Analytics", coords: "2257,4555,3162,5492", description: "AI-driven farm analytics and predictions." },
    { name: "Scout Tasks", coords: "3495,3809,4416,4730", description: "Task assignments for farm scouts." },
    { name: "Crop Water Requirements", coords: "4162,2666,5077,3587", description: "Optimal water management for crops." },
  ];
const Monitoring = `📌 Description:
  Monitoring provides real-time insights into farm operations, crop health, and environmental conditions using satellite imagery, IoT sensors, and drones.
  
  🌱 Benefits for Farmers:
  ✅ Real-time Crop Health Monitoring: Detect diseases, pests, and nutrient deficiencies early.
  ✅ Automated Surveillance: Reduce manual field visits by remotely tracking crop conditions.
  ✅ Yield Optimization: Make data-driven decisions to increase productivity and reduce losses.
  ✅ Cost Efficiency: Minimize unnecessary labor and resource wastage through targeted interventions.`;

  const Weather = `📌 Description:
  Weather analytics provides hyper-local weather forecasts, climate trends, and early warnings for extreme conditions like droughts, storms, and frost.
  
  🌱 Benefits for Farmers:
  ✅ Precision Forecasting: Plan planting, irrigation, and harvesting based on accurate weather predictions.
  ✅ Disaster Prevention: Reduce losses by preparing for storms, heavy rainfall, or droughts in advance.
  ✅ Efficient Resource Allocation: Avoid overwatering or underwatering crops by predicting rainfall.
  ✅ Pest & Disease Control: Monitor humidity and temperature conditions that may promote pest infestations.`;

  const AI = `📌 Description:
  AI-powered analytics process vast amounts of farm data to provide actionable insights on crop performance, soil health, and yield prediction.
  
  🌱 Benefits for Farmers:
  ✅ Smart Decision-Making: Get AI-driven recommendations for fertilizers, pesticides, and irrigation.
  ✅ Yield Prediction: Estimate harvest quantity and quality to optimize selling strategies.
  ✅ Pest & Disease Detection: Identify early signs of infestations through pattern recognition.
  ✅ Market Insights: Predict crop demand and optimize pricing strategies for better profits.`;

const scount =`📌 Description:
  Scout tasks help farmers and field workers efficiently manage field inspections, record observations, and track issues through digital mapping.
  
  🌱 Benefits for Farmers:
  ✅ Efficient Field Management: Assign scouting tasks and track progress digitally.
  ✅ Faster Problem Identification: Quickly detect issues like weeds, pests, or soil degradation.
  ✅ Data-Driven Reports: Store and analyze scouting data to improve future farm decisions.
  ✅ Time & Labor Savings: Reduce manual work and improve farm productivity.`;

  const CWR = `📌 Description:
  CWR analytics provide precise recommendations on how much water crops need based on weather, soil moisture, and crop type.
  
  🌱 Benefits for Farmers:
  ✅ Water Efficiency: Prevent over-irrigation and water wastage, saving resources.
  ✅ Optimal Crop Growth: Ensure crops receive the right amount of water for healthy yields.
  ✅ Cost Savings: Reduce electricity and fuel costs associated with irrigation systems.
  ✅ Sustainability: Promote eco-friendly farming by conserving groundwater.`;
 
  const sectionTexts = {
    Monitoring: `
  Monitoring provides real-time insights into farm operations, crop health, and environmental conditions using satellite imagery, IoT sensors, and drones.\n  
  
  🌱 Benefits for Farmers:\n
  ✅ Real-time Crop Health Monitoring: Detect diseases, pests, and nutrient deficiencies early.\n
  ✅ Automated Surveillance: Reduce manual field visits by remotely tracking crop conditions.\n
  ✅ Yield Optimization: Make data-driven decisions to increase productivity and reduce losses.\n
  ✅ Cost Efficiency: Minimize unnecessary labor and resource wastage through targeted interventions.`,
  
    "Weather Analytics": `
  Weather analytics provides hyper-local weather forecasts, climate trends, and early warnings for extreme conditions like droughts, storms, and frost.\n  
  
  🌱 Benefits for Farmers:\n  
  ✅ Precision Forecasting: Plan planting, irrigation, and harvesting based on accurate weather predictions.\n  
  ✅ Disaster Prevention: Reduce losses by preparing for storms, heavy rainfall, or droughts in advance.\n  
  ✅ Efficient Resource Allocation: Avoid overwatering or underwatering crops by predicting rainfall.\n  
  ✅ Pest & Disease Control: Monitor humidity and temperature conditions that may promote pest infestations.`,
  
    "AI Analytics": `
  AI-powered analytics process vast amounts of farm data to provide actionable insights on crop performance, soil health, and yield prediction.\n  
  
  🌱 Benefits for Farmers:\n  
  ✅ Smart Decision-Making: Get AI-driven recommendations for fertilizers, pesticides, and irrigation.\n  
  ✅ Yield Prediction: Estimate harvest quantity and quality to optimize selling strategies.\n  
  ✅ Pest & Disease Detection: Identify early signs of infestations through pattern recognition.\n  
  ✅ Market Insights: Predict crop demand and optimize pricing strategies for better profits.`,
  
    "Scout Tasks": `
  Scout tasks help farmers and field workers efficiently manage field inspections, record observations, and track issues through digital mapping.\n  
  
  🌱 Benefits for Farmers:\n  
  ✅ Efficient Field Management: Assign scouting tasks and track progress digitally.\n  
  ✅ Faster Problem Identification: Quickly detect issues like weeds, pests, or soil degradation.\n  
  ✅ Data-Driven Reports: Store and analyze scouting data to improve future farm decisions.\n  
  ✅ Time & Labor Savings: Reduce manual work and improve farm productivity.`,
  
    "Crop Water Requirements (CWR)": `
  CWR analytics provide precise recommendations on how much water crops need based on weather, soil moisture, and crop type.\n  
  
  🌱 Benefits for Farmers:\n  
  ✅ Water Efficiency: Prevent over-irrigation and water wastage, saving resources.\n  
  ✅ Optimal Crop Growth: Ensure crops receive the right amount of water for healthy yields.\n  
  ✅ Cost Savings: Reduce electricity and fuel costs associated with irrigation systems.\n  
  ✅ Sustainability: Promote eco-friendly farming by conserving groundwater.`,
  };
  
  


  const handleClick = (event, section) => {
    setTooltip({
      visible: true,
      section,
      text: sectionTexts[section] || "No description available.",
      x: event.clientX,
      y: event.clientY - 30,
    });
  };

  // const handleClick = (event,section) => {
  //   setTooltip({
  //     visible: true,
  //     section,
  //     x: event.clientX,
  //     y: event.clientY - 30,
  //   });
  //   //alert(`Clicked on ${section}`);
  // };

  return (
    <div id="home">
      <Grid container component="main">
        {/* 
       */}
      {/* <Toolbar /> */}<Toolbar />  
        <Grid item xs={12} style={{ backgroundColor: Secondary,    marginTop: "40px", }} >
          <Box
            style={{
              margin: "0 auto",
              marginTop: "40px",
              marginBottom: "40px",
              width:
                windowSize.width < 992
                  ? "100%"
                  : windowSize.width < 1025
                    ? "95%"
                    : windowSize.width < 1960
                      ? "70%"
                      : "60%",
              display: "flex",
              flexDirection: windowSize.width < 992 ? "column-reverse" : "row",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Box style={{ width: windowSize.width < 992 ? "95%" : "50%" }}>
              <Typography
                variant="h4"
                component="div"
                color={THEME_TEXT_COLOR}
                mb={3}
                style={{ fontWeight: "bold" }}
              >
                <strong style={{ color: THEME_TEXT_COLOR,fontFamily:'Gilroy-Bold' }}>
                  Earth Scan System:{" "}
                  <span style={{ color: "rgb(242 126 48)" ,fontFamily:'Gilroy-Medium'}}>
                  {/* {"One-stop Solution for Sustainable Agriculture and Natural Resource Management "} */}
                  <TypingEffect text="One-stop Solution for Sustainable Agriculture and Natural Resource Management " />
                  </span>
                </strong>
              </Typography>
              <Typography
                variant="h6"
                component="div"
                color={THEME_TEXT_COLOR}
                mb={3}
                style={{ fontWeight: "bold",fontFamily:'Gilroy-Bold' }}
              >
                <strong className="animated animatedFadeInUp fadeInUp">
                <GradualSpacing  text="  ESS aims to create a beneficial impact by leveraging geospatial data alongside tailored algorithms." />

                
                </strong>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                 className="animated animatedFadeInUp fadeInUp"
                style={{ color: SUB_TEXT_COLOR ,fontFamily:'Gilroy-Medium'}}
              >

<GradualSpacing text="At ESS, we use cutting-edge remote sensing technologies to deliver comprehensive Earth solutions that empower industries, governments, and communities to make informed decisions based on accurate, real-time environmental data. Our innovative approach combines advanced satellite imagery, aerial data collection, and sophisticated bigdata analytics to provide unparalleled insights into Earth's natural and human-made environments." />

                
              </Typography>
            </Box>
            <Box style={{ width: windowSize.width < 992 ? "100%" : "50%" , position: "relative"}}>

              {/* whileHover={{ scale: 1.05 }} whileTap={{ scale: 1.1 }}  */}
              <motion.div >
              
                  <img
                    src={`${sattelites}`}
                    // src={`${ASSET_IMAGE}/globe.png`}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      transition: "transform 0.5s ease",
                      transformOrigin: "center", cursor: 'pointer'
                    }}
                    usemap="#image-map"
                    alt="earth scan system"
                  />
{/* 
<Tooltip title="Monitoring" style={{  position: "absolute",
                    top: "50%", // Adjust based on image
                    left: "5%",  }}>

                </Tooltip> */}


  


                <div
                  style={{
                    position: "absolute",
                    top: "50%", // Adjust based on image
                    left: "5%", // Adjust based on image
                    width: "15%",
                    height: "15%",
                    cursor: "pointer",
                  //  backgroundColor: "rgba(255, 0, 0, 0.2)", // TEMPORARY for visibility
                  }}
                  onMouseEnter={(e) => handleClick(e, "Monitoring")}
                  onMouseLeave={()=>{setTooltip({ visible: false, text: "", x: 0, y: 0 });}}
                ></div>

      <div
        style={{
          position: "absolute",
          top: "70%",
          left: "15%",
          width: "15%",
          height: "15%",
          cursor: "pointer",
         // backgroundColor: "rgba(0, 255, 0, 0.2)", // TEMPORARY for visibility
        }}
        onMouseEnter={(e) => handleClick(e,"Weather Analytics")}
        onMouseLeave={()=>{setTooltip({ visible: false, text: "", x: 0, y: 0 });}}
      ></div>

      <div
        style={{
          position: "absolute",
          top: "85%",
          left: "40%",
          width: "15%",
          height: "20%",
          cursor: "pointer",
        //  backgroundColor: "rgba(0, 0, 255, 0.2)", // TEMPORARY for visibility
        }}
        onMouseEnter={(e) => handleClick(e,"AI Analytics")}
        onMouseLeave={()=>{setTooltip({ visible: false, text: "", x: 0, y: 0 });}}
      ></div>

      <div
        style={{
          position: "absolute",
          top: "70%",
          left: "60%",
          width: "15%",
          height: "20%",
          cursor: "pointer",
        //  backgroundColor: "rgba(255, 255, 0, 0.2)", // TEMPORARY for visibility
        }}
        onMouseEnter={(e) => handleClick(e,"Scout Tasks")}
        onMouseLeave={()=>{setTooltip({ visible: false, text: "", x: 0, y: 0 });}}
      ></div>

      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "70%",
          width: "15%",
          height: "20%",
          cursor: "pointer",
        // backgroundColor: "rgba(255, 0, 255, 0.2)", // TEMPORARY for visibility
        }}
        onMouseEnter={(e) => handleClick(e,"Crop Water Requirements (CWR)")}
        onMouseLeave={()=>{setTooltip({ visible: false, text: "", x: 0, y: 0 });}}
       // onClick={(e) => handleClick(e,"Crop Water Requirements")}
      ></div>
{/* 
{tooltip.visible && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          style={{
            position: "fixed",
            top: tooltip.y,
            left: tooltip.x,
            backgroundColor: "#fff",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: "14px",
            pointerEvents: "none",
            transform: "translate(-50%, -100%)",
          }}
        >
                    {tooltip.text.split("\n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
  ))}
    
        </motion.div>
      )} */}


              </motion.div>
           
              {/* <img
                src={`${ASSET_IMAGE}/globe.svg`}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
                alt="earth"
              /> */}

            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
