import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import Service1 from "../../public/images/Service1.png";
import Service2 from "../../public/images/Service2.png";
import Service3 from "../../public/images/Service3.png";
import ck from "../../public/images/ck.png";
import fms from "../../public/images/fms.png";

import { Secondary, THEME_TEXT_COLOR } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
 
/* 

 "Agriculture and Forestry": {
      items: [
        "Mapping and Monitoring Food Security",
        "Precision Agriculture",
        "Crop Monitoring and Management",
        "Farm Management Solutions",
        "Weather and Climate Analysis",
        "Field Mapping and Surveying",
        "Forest Management",
        "Sustainable Land Use Planning",
        "Irrigation and Water Management",
        "Environmental Impact Assessment",
        "Green Supply Chain Management"
      ],
      image: icon_IMG
    },  
    "Carbon Management and Offsetting": {
      items: [
        "Carbon Footprint Analysis",
        "Carbon Monitoring and Reporting",
        "Carbon Offset Solutions",
        "Energy Efficiency Solutions",
        "Renewable Energy Integration",
        "Sustainable Supply Chain Management",
        "Carbon Accounting Software",
        "Environmental Impact Assessment",
        "Climate Risk Analysis",
        "Carbon Neutral Certification",
        "Sustainable Agriculture Practices",
        "Corporate Sustainability Strategy",
        "Public Awareness and Education",
        "Regulatory Compliance and Advocacy"
      ],
      image: CO2
    },
    
    
    */
const data = { 
    "Precision Crop Monitoring Solutions": {
      items: [
        "Our AI-powered platform provides real-time insights to monitor crop health, increase productivity, reduce input costs, and support sustainable farming—all in one integrated system.",
      ],
      image: Service1,
      button:"Start Monitoring",params:1,
      link:"/registration"
    }, 
    "Carbon Credit Monitoring & Reporting Services": {
      items: [
        "We help landowners, agribusinesses, and organizations track, verify, and report carbon sequestration with full compliance to VCS and Gold Standard, enabling credible carbon credit generation.",
        ],
      image: Service2, button:"Go Carbon Smart",params:2,
      link:"/carbon-monitoring"
    }, 
    "Digital Agriculture Solutions": {
      items: [
        "We offer AI-driven tools that improve farm efficiency, sustainability, and profitability across the entire agricultural value chain—from planning and monitoring to decision-making and reporting." 
      ],
      image: Service3, button:"Go Digital",params:3,
      link:"/registration"
    }, 
    "Smart Farm Management – CropERP": {
      items: [
        "CropERP is a comprehensive farm management system that integrates all aspects of farm operations, from land preparation to market sales." 
      ],
      image:fms, button:"Optimize Your Farm",params:4,
      link:"/registration"
    },
    "IoT-Enabled Agriculture – CropKonnect": {
      items: [
        "CropKonnect is an IoT-based agriculture solution that collects real-time soil and environmental data to enhance precision farming.",
      ],
      image:ck, button:"Monitor & Automate",params:5,
      link:"/registration"
    },
  };
  function ServicesCard() {
     const navigate = useNavigate();
    return (
 
      <Grid sx={{
        justifyContent: 'center'
      }} container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        {Object.keys(data).map((title, index) => (
          <Grid item xs={4} sm={4} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                backgroundColor: Secondary,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "8px",
                boxShadow: "none",
              }}
            >
              <img
                src={data[title].image}
                alt={title}
                style={{
                  width: "100%", // Make it responsive
                  height: "250px", // Adjust based on width
                  maxHeight: "250px", // Limit size
                  objectFit: "cover",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: THEME_TEXT_COLOR,
                  fontFamily: "Gilroy-Medium",
                  mt: 2,
                  mb: 2,
                  fontSize: { xs: "14px", sm: "16px", md: "18px" }, // Responsive font size
                }}
              >
                <strong>{title}</strong>
              </Typography>

              {data[title].items.map((item, itemIndex) => (
                <Typography
                  key={itemIndex}
                  variant="body2"
                  sx={{ fontSize: { xs: "12px", sm: "14px" }, lineHeight: "20px" }}
                >
                  {item}
                </Typography>
              ))}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" }, // Stack buttons on mobile
                  alignItems: "center",
                  gap: 1,
                  mt: 2,
                }}
              >

                {/* <Tooltip placement="top" title="Coming Soon">

            <Button
                id="learn_more"
                size="small"
                fullWidth
                onClick={() => {
                  if (data[title].params !== 4 && data[title].params !== 5) {
                    navigate(data[title].link);
                  }
                }}
              >
                {data[title].button}
              </Button>


            </Tooltip> */}
                {/* {data[title] && (data[title].params === 1 || data[title].params === 3) ? (
                <>

                  <br /> <br /> <br /> <br />
                </>
              ) : null} */}

                {data[title].params === 4 || data[title].params === 5 ? (
                  <Tooltip placement="top" title="Coming Soon">

                    <Button id="learn_more" size="small" fullWidth>
                      {data[title].button}
                    </Button>

                  </Tooltip>
                ) : (
                  <Button
                    id="learn_more"
                    size="small"
                    fullWidth
                    onClick={() => navigate(data[title].link)}
                  >
                    {data[title].button}
                  </Button>
                )}



                <Button
                  id="contact_us"
                  size="small"
                  fullWidth
                  onClick={() => {
                    navigate(`/service-detail/${data[title].params}`);
                  }}
                >
                  View Details
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  }
  
  export default ServicesCard;