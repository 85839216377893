import Toolbar from "@mui/material/Toolbar";
import { Box, Typography, Grid, CardContent, Card } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import * as React from 'react';
import { 
    Button,
    Switch,
    Stack,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery
  } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import FinalCard from "./FinalCard";
import SmallerCard from "./SmallerCard";
import CustomCarousel from "./CustomCarousel";
import PixelCard from "./PixelCard";
import service_1 from "../../public/images/service_1.png";
import service_2 from "../../public/images/service_2.png";
import service_3 from "../../public/images/service_3.png";
import Goals_IMG from "../../public/images/Sustainable-Development-Goals.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import RecommendIcon from '@mui/icons-material/Recommend'; 
import { useState } from "react";
import { ASSET_IMAGE, Primary, Secondary, THEME_TEXT_COLOR } from "../utils/constants";
import { useNavigate } from "react-router-dom";


export default function PlanComponent() {
    const navigate = useNavigate();
    const Goals1 = "SGD/1.jpg";
    const Goals2 = "SGD/2.jpg";
    const Goals3 = "SGD/3.jpg";
    const Goals4 = "SGD/6.jpg";
    const Goals5 = "SGD/7.jpg";
    const Goals6 = "SGD/11.jpg";
    const Goals7 = "SGD/13.jpg";
    const Goals8 = "SGD/14.jpg";
    const Goals9 = "SGD/16.jpg";
    const goals = [
        { id: 1, color: '#e5243b', text: 'No Poverty', desc: 'End poverty in all its forms everywhere.', img: ASSET_IMAGE + "/" + Goals1 },
        { id: 2, color: '#dda63a', text: 'Zero Hunger', desc: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture.", img: ASSET_IMAGE + "/" + Goals2 },
        { id: 3, color: '#4c9f38', text: 'Good Health and Well-being', desc: "Ensure healthy lives and promote well-being for all at all ages.", img: ASSET_IMAGE + "/" + Goals3 },
        { id: 6, color: '#26bde2', text: 'Clean Water and Sanitation', desc: "Ensure availability and sustainable management of water and sanitation for all.", img: ASSET_IMAGE + "/" + Goals4 },
        { id: 7, color: '#fcc30b', text: 'Affordable and Clean Energy', desc: "Ensure access to affordable, reliable, sustainable and modern energy for all.", img: ASSET_IMAGE + "/" + Goals5 },
        { id: 11, color: '#f89d2a', text: 'Sustainable Cities and Communities', desc: "Make cities and human settlements inclusive, safe, resilient and sustainable.", img: ASSET_IMAGE + "/" + Goals6 },
        { id: 13, color: '#3f7e44', text: 'Climate Action', desc: "Take urgent action to combat climate change and its impacts.", img: ASSET_IMAGE + "/" + Goals7 },
        { id: 14, color: '#0a97d9', text: 'Life Below Water', desc: "Conserve and sustainably use the oceans, seas and marine resources for sustainable development.", img: ASSET_IMAGE + "/" + Goals8 },
        { id: 16, color: '#00689d', text: 'Peace, Justice and Strong Institutions', desc: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.", img: ASSET_IMAGE + "/" + Goals9 },
    ];
    const [hoveredGoal, setHoveredGoal] = useState(null);

    const StyledCard = styled(Card)(({ theme, isPro }) => ({
        borderRadius: 20,
        height: "100%",
        backgroundColor: isPro ? Primary : Primary,
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
            transform: "translateY(-10px)",
            backgroundColor: Secondary,
            boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)"
        }
    }));


    const [isYearly, setIsYearly] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const windowSize = useWindowSize();
    const plans = [

        {
            title: "ESS Basic",
            display:"ESS Bulk",
            price: isYearly ? 100 : 100,
            features: [
               "Maximum Area 1000 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "Crop monitoring learning module"
            ],
            icon: <StarIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />
        },
        {
            title: "ESS Advance",
            display:"ESS Premium",
            price: isYearly ? 0.20 + "/ Ha" : 0.20 + "/Ha",
            features: [
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "AI Analytics for VRA",
                "OpenAI Driven Decision Support",
            ],
            icon: <svg  style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }}  xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
            <g clip-path="url(#clip0_419_224)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1101 0.461304C6.3275 0.461304 0 6.78881 0 14.5714C0 22.354 6.3275 28.6815 14.1101 28.6815C21.8927 28.6815 28.2202 22.354 28.2202 14.5714C28.2202 6.78881 21.8927 0.461304 14.1101 0.461304Z" fill="#FEC006"/>
            <path d="M6.77377 16.5171V11.4298C6.36039 11.2259 6.0848 10.8015 6.0848 10.3109C6.0848 9.62745 6.63047 9.07076 7.31944 9.07076C8.00841 9.07076 8.56509 9.62745 8.56509 10.3164C8.56509 10.4983 8.52651 10.6802 8.44935 10.8455L11.3596 13.9928L13.1564 10.3991C12.969 10.1786 12.8643 9.89752 12.8643 9.59989C12.8643 8.91643 13.421 8.35974 14.1099 8.35974C14.7989 8.35974 15.3556 8.91643 15.3556 9.59989C15.3556 9.89752 15.2453 10.1786 15.0635 10.3991L16.8603 13.9928L19.776 10.8455C19.6989 10.6802 19.6603 10.4983 19.6603 10.3164C19.6603 9.62745 20.217 9.07076 20.9059 9.07076C21.5949 9.07076 22.1516 9.62745 22.1516 10.3164C22.1516 10.8015 21.8705 11.2259 21.4626 11.4298V16.5227H6.77377V16.5171ZM21.8595 17.5478H6.36039C6.07378 17.5478 5.84229 17.7793 5.84229 18.066V20.2762C5.84229 20.5628 6.07378 20.7943 6.36039 20.7943H21.865C22.1516 20.7943 22.3831 20.5628 22.3831 20.2762V18.066C22.3776 17.7793 22.1461 17.5478 21.8595 17.5478Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_419_224">
            <rect width="28.2202" height="28.2202" fill="white" transform="translate(0 0.461304)"/>
            </clipPath>
            </defs>
            </svg>,
            isPro: true
        }
    ];

    return (
        <div id="sdg" style={{   marginTop: "20px",}}>
            <Grid container component="main">
 

            <Box
                        style={{
                            position: "relative",
                            borderRadius: "50px",
                         
                            marginTop: "40px",
                            
                            width:
                                windowSize.width < 992
                                    ? "95%"
                                    : windowSize.width < 1025
                                        ? "85%"
                                        : windowSize.width < 1960
                                            ? "65%"
                                            : "50%",
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="div"
                            color={"white"}
                            style={{ fontWeight: "bold", color: "#f27e30", marginBottom: 10 ,fontFamily:'Gilroy-Bold'}}
                        >
                            <strong>Crop Monitoring Plans</strong>
                        </Typography>
                        <Typography
                            variant="body1"
                            component="div"
                            textAlign={"justify"}
                            mb={5}
                            style={{ color: THEME_TEXT_COLOR,fontFamily:'Gilroy-Medium' }}
                        >
                            Get the right plan for your needs at unbeatable promotional prices
                        </Typography>

                        </Box>
                <Grid item xs={12} style={{ backgroundColor: Primary }}>
                    <Box
                        style={{
                            position: "relative",
                            borderRadius: "50px",
                            margin: "0 auto",
                            marginTop: "10px",
                            marginBottom: "40px",
                            width:
                                windowSize.width < 992
                                    ? "95%"
                                    : windowSize.width < 1025
                                        ? "85%"
                                        : windowSize.width < 1960
                                            ? "65%"
                                            : "50%",
                        }}
                    >
                        {/* <Typography
                            variant="h5"
                            component="div"
                            color={"white"}
                            style={{ fontWeight: "bold", color: "#f27e30", marginBottom: 10 ,fontFamily:'Gilroy-Bold'}}
                        >
                            <strong>Crop Monitoring Plans</strong>
                        </Typography>
                        <Typography
                            variant="body1"
                            component="div"
                            textAlign={"justify"}
                            mb={5}
                            style={{ color: THEME_TEXT_COLOR,fontFamily:'Gilroy-Medium' }}
                        >
                            Get the right plan for your needs at unbeatable promotional prices
                        </Typography> */}
                        

                        <Box>
                            <Stack
                                direction={{ xs: "column", md: "row" }}
                                spacing={4}
                                justifyContent="center"
                                alignItems="stretch"
                            >
                                {plans.map((plan) => (
                                    <Box
                                        key={plan.title}
                                        sx={{
                                            flex: 1,
                                            maxWidth: { xs: "100%", md: "350px" }
                                        }}
                                    >
                                        <StyledCard isPro={true}>
                                            <CardContent
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    height: "100%",
                                                    p: 4
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 2,
                                                        mb: 3
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            color: plan.isPro ? THEME_TEXT_COLOR : THEME_TEXT_COLOR,
                                                            fontSize: "2rem",fontFamily:'Gilroy-Medium'
                                                        }}
                                                    >
                                                        {plan.icon}
                                                    </Box>
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontWeight: "bold",
                                                            color: THEME_TEXT_COLOR,fontFamily:'Gilroy-Medium'
                                                        }}
                                                    >
                                                        {plan.display}
                                                    </Typography>
                                                </Box>

                                                <Typography
                                                    variant="h5"
                                                    sx={{ fontWeight: "bold", color: THEME_TEXT_COLOR, mb: 1,marginRight:1,fontFamily:'Gilroy-Medium' }}
                                                >
                                                    $ {plan.price }
                                                    <Typography component="span" sx={{ fontSize: "1rem", color: THEME_TEXT_COLOR,fontFamily:'Gilroy-Medium' }} > /{isYearly ? "year" : "month"} </Typography>
                                                </Typography>

                                                <List sx={{ flexGrow: 0.2, mb: 3 }}>
                                                    {plan.features.map((feature) => (
                                                        <ListItem key={feature} sx={{ px: 0 }}>
                                                            <ListItemIcon>
                                                                <CheckIcon style={{ color: THEME_TEXT_COLOR }} />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={feature}
                                                                sx={{ color: THEME_TEXT_COLOR }}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>

                                                <Typography
                                                    component="span"
                                                    sx={{
                                                        fontSize: "1rem",
                                                        color: THEME_TEXT_COLOR,
                                                        display: "flex",
                                                        alignItems: "center", 
                                                        fontWeight: "bold",
                                                    }}
                                                    onClick={()=>{navigate("/registration")}}
                                                >
                                                    Start free trial
                                                    <Box
                                                        sx={{
                                                            background: Secondary,
                                                            borderRadius: '50%',
                                                            width: 30,
                                                            height: 30,
                                                            display: 'flex',
                                                            justifyContent: 'space-evenly',
                                                            alignItems: 'center',
                                                            marginLeft: 2,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <ArrowBackIcon style={{ color: "#000", transform: "rotate(180deg)",width:20,height:20 }} />
                                                    </Box>
                                                </Typography>

                                            </CardContent>
                                        </StyledCard>
                                    </Box>
                                ))}
                            </Stack>

                            <Typography
                            variant="body1"
                            component="div"
                            textAlign={"justify"}
                            
                            mt={4}
                            style={{ color: THEME_TEXT_COLOR}}
                        >

                            <CheckIcon style={{ color: THEME_TEXT_COLOR,marginRight:20,marginBottom:5,fontFamily:'Gilroy-Medium' }} />

                            For B2B / Customized Solutions with Special Care Plan of ESS contact us at email contact@escan-systems.com

                        </Typography>

                        <Typography
                            variant="body1"
                            component="div"
                            textAlign={"justify"}
                            
                            mt={2}
                            style={{ color: THEME_TEXT_COLOR}}
                        >

                            <CheckIcon style={{ color: THEME_TEXT_COLOR,marginRight:20,marginBottom:5,fontFamily:'Gilroy-Medium' }} />

                            For Social Responsibility Package, Complementary in collaboration with Not for 
                            Profit Organizations contact us at email contact@escan-systems.com

                        </Typography>

                        </Box>


                    </Box>
                </Grid>


            </Grid>
        </div>
    );
}
  