// CustomCarousel.js
import React, { useState,useEffect } from "react";
import {
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  IconButton,
  Fade,
  Slide,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useWindowSize } from "@uidotdev/usehooks";
import { Primary, Secondary, THEME_TEXT_COLOR } from "../utils/constants";


const CustomCarousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const size = useWindowSize();
  const handleNext = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [currentSlide, slides.length]);
 /* 
 
 , backgroundImage:
      `url(${slides[currentSlide].image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    
    */
  return (

    <Box
      style={{

        backgroundImage:
          `url(${slides[currentSlide].image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <Box sx={{ position: "relative", height: "100%", background: "linear-gradient(to bottom, transparent, black)" }}>
        <Paper
          sx={{
            textAlign: "center",
            padding: 2,
            backgroundColor: "transparent",
            position: "absolute",
            bottom: 50,
            width: "100%",

          }}
        >
          <Typography className="loginSlides" variant="h5" sx={{ fontWeight: 'bold', fontSize: '1.6rem', color: '#fff' }} style={{color:'#fff !important'}}>
            {slides[currentSlide].title}
          </Typography>
          <Typography variant="body1" className="loginSlides" sx={{ fontSize: '1.2rem', color:'#fff' }}  style={{color:'#fff !important'}}>
            {slides[currentSlide].content}
          </Typography>
        </Paper>
        <Stack
          direction="row"
          spacing={4}
          sx={{
            position: "absolute",
            bottom: 30,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {slides.map((_, index) => (
            <Box
              key={index}
              onClick={() => handleDotClick(index)}
              sx={{
                cursor: 'pointer',
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: index === currentSlide ? "white" : "grey",
              }}
            />
          ))}
        </Stack>

        <IconButton
          onClick={handlePrev}
          sx={{
            position: "absolute",
            left: 15,
            top: "50%",
            transform: "translateY(-50%)",
            borderRadius: "50%",
            backgroundColor: Secondary,
            "&:hover": {
              backgroundColor: Primary,
              color: THEME_TEXT_COLOR,
            },
          }}
        >
          <ArrowBackIcon style={{ color:THEME_TEXT_COLOR }} />
        </IconButton>
        <IconButton
          onClick={handleNext}
          sx={{
            position: "absolute",
            right: 15,
            top: "50%",
            transform: "translateY(-50%)",
            borderRadius: "50%",
            backgroundColor: Secondary,
            "&:hover": {
              backgroundColor: Primary,
              color: THEME_TEXT_COLOR,
            },
          }}
        >
          <ArrowForwardIcon style={{ color:THEME_TEXT_COLOR}} />
        </IconButton>
      </Box>
    </Box>




  );
};

export default CustomCarousel;
