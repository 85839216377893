import CustomFooter from "../home/CustomFooter";
import CustomHeader from "../home/NewHeader";
import PrivacyComponent from "./PrivacyPolicyComponent";

export default function PrivacyPolicy() {
  return (
    <>
      <CustomHeader />
        <PrivacyComponent />
      <CustomFooter/>
    </>
  );
}
