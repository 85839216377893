import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useWindowSize } from "@uidotdev/usehooks";
import ESS_LOGO from "../../public/images/ESS-06.png";
import { Link } from "react-scroll";
import RequestAccountModal from "../utils/RequestAccountModal";
import { useRouter } from "../routes/hooks";
import { Primary, THEME_TEXT_COLOR } from "../utils/constants";
import { Phone, PhoneIphone } from "@mui/icons-material";
import ESS_LOGO_UPDATED from "../../public/images/ESS_UPDATED.png";

const drawerWidth = 240;
// const navItems = [
//   "Home",
//   "About",
//   "Investors' showcase",
//   "SDG",
//   "Contact",
//   "Crop Monitoring",
// ];

const navItems = [
  { name: "Home", link: "/" },
  { name: "About", link: "/about" },
  { name: "Investors' showcase", link: "/investors-showcase" },
  { name: "SDG", link: "sdg" },
  { name: "Contact", link: "/contact-us" },
  { name: "Crop Monitoring", link: "/crop-monitoring" },
];



function CustomHeader(props) {
  const router = useRouter();
  const windowSize = useWindowSize();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleRequestAccountClick = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={ESS_LOGO_UPDATED} width={"150px"} />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={1} disablePadding>
            <ListItemButton>
              {item.name === "Request Account" ? (
                <Link
                  key={item.name}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  style={{ textAlign: "center" }}
                >
                  <Button style={{ color: "black" }}>{item.name}</Button>
                </Link>
              ) : item.name === "Crop Monitoring" ? (
                <Link
                  key={item}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Button
                    style={{
                      background: "#877849",
                      borderRadius: "10px",
                      boxShadow: "none",
                      color: "#fff !important",
                      display: "block",
                      fontSize: "13px",
                      fontWeight: 600,
                      margin: 0,
                      padding: "9px 8px",
                      textAlign: "center",
                      width: "100%",
                    }}
                    onClick={() => router.push('/registration')}
                  >
                    <b style={{
                      padding: "9px 8px"
                    }}>{item.name}</b>
                  </Button>
                </Link>
              ) : (
                item.name === "Investors' showcase" ? <>

                  <Link
                    key={item.name}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textAlign: "center", width: "100%" }}
                  >
                    <Button style={{ color: "black" }} onClick={() => router.push("/investors-showcase")   } ><b>{item.name}</b></Button>
                  </Link>

                </> : <>

                  <Link
                    key={item.name}
                 
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={handleDrawerToggle}
                    style={{ textAlign: "center" }}
                  >

                    <Button style={{ color: "black" }}  onClick={()=> router.push(item.link.toLowerCase())}><b>{item.name}</b></Button>


                  </Link>

                </>

              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>


      {/* 
      <Box sx={{ display: "flex" ,backgroundColor: Primary, height: '62px'}}> */}


      {/* width:50%,height: '62px'  */}


      <div className="fixed top-0 left-0 w-full z-50  ">
        <div className="flex flex-col ">
          <div className="invisible transition-all d-700 ease-in-out absolute h-[100vh] w-full bg-[#c8c8c899] backdrop-blur-sm" />
          <div className="w-full flex justify-center items-center">

            {/*   <div className="flex max-w-[808px] w-full px-5 md:px-10 md:max-w-[1104px] lg:max-w-[1736px] lg:px-[100px] 2xl:max-w-[1528px] 2xl:px-[150px]">  */}
            <div className="flex max-w-[808px] w-full px-5 md:px-10 md:max-w-[904px] lg:max-w-[1736px] lg:px-[100px] 2xl:max-w-[1528px] 2xl:px-[150px]">
              <div className="relative w-full">
                <div className="w-full relative">
                  <div className="flex w-full mt-[23px]">
                    <header className="z-50 flex w-full rounded-[8px] items-center justify-between h-[62px] px-[10px] bg-white shadow-custom  md:px-[44px]" style={{
                      boxShadow:
                        "0px 0px 24px 11px rgba(90, 125, 226, 0.1), var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
                      "--tw-shadow": "0px 0px 24px 11px rgba(90, 125, 226, .1)",
                      "--tw-shadow-colored": "0px 0px 24px 11px var(--tw-shadow-color)",
                    }}>
                      <div className="flex gap-[36px] h-full items-center">
                        <img
                          alt="ESS logo"
                          loading="lazy"
                          width={376}
                          height={150}
                          decoding="async"
                          data-nimg={1}
                          onClick={()=>router.push('/')}
                          className="max-w-[140px]  object-cover cursor-pointer"
                          style={{ color: "transparent" }}
                          srcSet={ESS_LOGO_UPDATED}
                          src={ESS_LOGO_UPDATED}
                        />
                        <nav className="hidden min-[905px]:flex flex-1 items-center justify-center space-x-6 h-full">
                          <a
                            className=" text-tableblackgreyHover text-h7 cursor-pointer font-medium  hover:text-charcol transition-color d-700 ease-in-out"
                            href="/"
                          >
                            Home
                          </a>
                          <a
                            className="text-tableblackgreyHover text-h7 font-medium  h-full flex items-center"
                            href="/about"
                          >
                            About
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={11}
                              height={6}
                              viewBox="0 0 11 6"
                              fill="none"
                              className="ml-[6px] group-hover:rotate-0 transition-all d-700 ease-in-out"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.46595 5.50855C6.32624 5.64818 6.1368 5.72668 5.93923 5.72668C5.74166 5.72668 5.55222 5.64818 5.41251 5.50855L1.19814 1.29415C1.12698 1.22543 1.07023 1.14322 1.03119 1.05233C0.992138 0.961442 0.971593 0.863681 0.970731 0.764766C0.96987 0.665843 0.988725 0.567749 1.02618 0.476189C1.06364 0.384637 1.11895 0.301456 1.1889 0.231506C1.25885 0.161556 1.34203 0.106243 1.43359 0.0687853C1.52515 0.0313193 1.62324 0.0124724 1.72216 0.0133339C1.82108 0.0141954 1.91884 0.0347407 2.00973 0.0737914C2.10062 0.112834 2.18282 0.169586 2.25155 0.240738L5.93923 3.9284L9.62691 0.240738C9.76742 0.105032 9.95557 0.0299458 10.1509 0.0316444C10.3462 0.0333429 10.5331 0.111688 10.6712 0.249817C10.8093 0.387945 10.8877 0.574796 10.8894 0.770122C10.8911 0.965457 10.816 1.15364 10.6803 1.29415L6.46595 5.50855Z"
                                fill="currentColor"
                                className="text-tableblackgreyHover"
                              />
                            </svg> */}
                          </a>
                          <a
                            className="text-tableblackgreyHover text-h7 font-medium  hover:text-charcol transition-color d-700 ease-in-out"
                            href="https://escan-systems.com/blog/"
                          >
                            Blog
                          </a>
                        </nav>
                      </div>
                      <div className="flex items-center gap-[30px]">
                        <div className="hidden min-[905px]:flex md:items-center md:gap-[26px]">
                          <div className="relative group">
                            <a href="/contact-us">
                              <IconButton>
                              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 5.5C3 14.0604 9.93959 21 18.5 21C18.8862 21 19.2691 20.9859 19.6483 20.9581C20.0834 20.9262 20.3009 20.9103 20.499 20.7963C20.663 20.7019 20.8185 20.5345 20.9007 20.364C21 20.1582 21 19.9181 21 19.438V16.6207C21 16.2169 21 16.015 20.9335 15.842C20.8749 15.6891 20.7795 15.553 20.6559 15.4456C20.516 15.324 20.3262 15.255 19.9468 15.117L16.74 13.9509C16.2985 13.7904 16.0777 13.7101 15.8683 13.7237C15.6836 13.7357 15.5059 13.7988 15.3549 13.9058C15.1837 14.0271 15.0629 14.2285 14.8212 14.6314L14 16C11.3501 14.7999 9.2019 12.6489 8 10L9.36863 9.17882C9.77145 8.93713 9.97286 8.81628 10.0942 8.64506C10.2012 8.49408 10.2643 8.31637 10.2763 8.1317C10.2899 7.92227 10.2096 7.70153 10.0491 7.26005L8.88299 4.05321C8.745 3.67376 8.67601 3.48403 8.55442 3.3441C8.44701 3.22049 8.31089 3.12515 8.15802 3.06645C7.98496 3 7.78308 3 7.37932 3H4.56201C4.08188 3 3.84181 3 3.63598 3.09925C3.4655 3.18146 3.29814 3.33701 3.2037 3.50103C3.08968 3.69907 3.07375 3.91662 3.04189 4.35173C3.01413 4.73086 3 5.11378 3 5.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              </IconButton>
                            </a>
                            <span className="absolute bottom-full mb-[8px] hidden group-hover:flex items-center justify-center px-2 py-1 text-xs text-white bg-gray-700 rounded-md shadow-lg">
                              Contact
                            </span>
                          </div>

                        </div>
                        <div className="flex items-center gap-[3px] md:gap-[10px]">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                     className="hidden min-[905px]:block"
                         
                            href="/registration"
                          >
                            <button
                              className="inline-flex items-center justify-center whitespace-nowrap !text-h7 font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-orange bg-orange text-white hover:bg-orange-hover px-[14px] py-[4px] rounded-[7px] md:text-h7"
                              style={{
                                background: "rgb(242, 126, 48)",
                                borderRadius: "7px",
                              }}
                              
                            >
                              Crop Monitoring
                            </button>
                          </a>

                          <a className="hidden min-[905px]:block" href="/investors-showcase">
                            <button
                              className="inline-flex items-center justify-center whitespace-nowrap !text-h7 font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-charcol bg-charcol text-white hover:bg-[#222222] hover:text-white px-[14px] py-[4px] rounded-[7px]"
                              style={{
                                backgroundColor: "#333",
                                borderRadius: "7px",
                              }}
                            >
                              Investors Corner
                            </button>
                          </a>

                          <div className="flex justify-end min-[905px]:hidden"   onClick={handleDrawerToggle}>
                            <svg
                              className="block min-[905px]:hidden h-[24px] w-[21px] cursor-pointer"
                              type="button"
                              aria-haspopup="dialog"
                              aria-expanded="false"
                              aria-controls="radix-:Rt6afnsq:"
                              data-state="closed"
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              role="img"
                              aria-label="Menu icon"
                            >
                              <line x1={4} x2={20} y1={12} y2={12} />
                              <line x1={4} x2={20} y1={6} y2={6} />
                              <line x1={4} x2={20} y1={18} y2={18} />
                            </svg>
                          </div>

                          <nav>
                            <Drawer
                              container={container}
                              variant="temporary"
                              open={mobileOpen}
                              onClose={handleDrawerToggle}
                              ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                              }}
                              sx={{
                                display: { xs: "block", sm: "none" },
                                "& .MuiDrawer-paper": {
                                  boxSizing: "border-box",
                                  width: drawerWidth,
                                },
                              }}
                            >
                              {drawer}
                            </Drawer>
                          </nav>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

CustomHeader.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default CustomHeader;
