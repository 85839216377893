import Toolbar from "@mui/material/Toolbar";
import { Box, Typography, Grid, Card, CardContent, CardActions } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import { useWindowSize } from "@uidotdev/usehooks";
import FinalCard from "./FinalCard";
import SmallerCard from "./SmallerCard";
import CustomCarousel from "./CustomCarousel";
import PixelCard from "./PixelCard";

import Goals_IMG from "../../public/images/Sustainable-Development-Goals.svg";
import Service1 from "../../public/images/Service1.png";
import { Primary, Secondary, THEME_HEADING_COLOR, THEME_TEXT_COLOR } from "../utils/constants";
import { Button } from "react-scroll";
import ServicesCard from "./Services";
import Sattelites from "../../public/images/sattelites.png";
 
export default function HomeComponent2() {
  
  const service_1 = "service_1.png";
  const service_2 = "service_2.png";
  const service_3 = "service_3.png";
  const cm1 = "cm1.png";
  const ess8 = "ess8.png";
  const windowSize = useWindowSize();
  return (
    <div id="about">
          <Grid container component="main">
      {/* <Grid item xs={12} style={{ backgroundColor: Primary }}>
        <Box
          pt={5}
          pb={5}
          pr={2}
          pl={2}
          style={{
            backgroundColor: "#212a33",
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
            display: "flex",
            flexDirection: windowSize.width < 992 ? "column-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box style={{ width: windowSize.width < 992 ? "85%" : "40%" }}>
            <Typography
              variant="h6"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold" }}
            >
              <strong>Watch Video About EOSDA</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color={"white"}
              style={{ fontWeight: "bold" }}
            >
              <strong>
                EOS Data Analytics is one of the leading global geospatial
                analytics providers on the market, partnering with governmental,
                commercial, and scientific organizations. EOSDA creates
                satellite-driven solutions for agriculture, forestry, and
                mining, while also actively exploring 22 more industries. The
                Company sees its mission as helping businesses around the world
                become more sustainable via innovative precision observation and
                data analytics tools.
              </strong>
            </Typography>
          </Box>
          <Box
            style={{
              width: windowSize.width < 992 ? "90%" : "40%",
              position: "relative",
            }}
          >
            <img
              src="https://i.ytimg.com/vi/fzj4yQtOOCU/maxresdefault.jpg"
              width="100%"
              alt="earth"
            />
            <PlayCircleFilledWhiteIcon
              style={{
                fontSize: "50px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#d1d2d4",
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      </Grid> */}
      <Grid item xs={12} style={{ backgroundColor: Primary }}>
        <Box
          pt={5}
          pb={5}
          pr={2}
          pl={2}
          style={{
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "10px",
            width:
              windowSize.width < 992
                ? "100%"
                : windowSize.width < 1025
                ? "90%"
                : windowSize.width < 1960
                ? "70%"
                : "55%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          {/* <Typography
            variant="h5"
            component="div"
            color={"white"}
            mb={1}
            style={{ fontWeight: "bold", color: "#f27e30", fontFamily:'Gilroy-Bold' }}
          >
            <strong>What we do</strong>
          </Typography>
          <Typography
            variant="body1"
            component="div"
            textAlign={"justify"}
            mb={5}
            style={{color:THEME_TEXT_COLOR ,fontFamily:'Gilroy-Medium'}}
          >

At Earth Scan Systems (ESS), we harness remote sensing, IoT, and AI-driven analytics to drive sustainable development and resource efficiency. 
Our advanced technology provides real-time environmental intelligence, helping businesses, governments, and organizations make data-driven decisions for a more sustainable future.
By integrating high-resolution satellite data, sensor networks, and predictive analytics, we deliver actionable insights on climate change, land use, and resource management.
 ESS empowers industries to monitor, optimize, and reduce their environmental impact, ensuring a smarter, greener planet.
With ESS, you’re not just leveraging cutting-edge technology—you’re investing in a sustainable future.


          </Typography> */}

          {/* <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold",  color: "#f27e30", fontFamily:'Gilroy-Bold'  }}
            >
              <strong>Vision</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color:THEME_TEXT_COLOR ,fontFamily:'Gilroy-Medium'}}
            >
              
              A world where technology enables sustainability, fostering resilient communities, businesses, and governments. Through innovation, collaboration, and ethical practices, we strive to create a lasting positive impact on our planet for future generations.
            </Typography> */}
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold",  color: "#f27e30", fontFamily:'Gilroy-Bold'}}
            >
              <strong>Our Mission</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={3}
              style={{ color:THEME_TEXT_COLOR,fontFamily:'Gilroy-Medium' }}
            >
             To deliver real-time environmental insights through Earth Observation and AI-powered analytics. We empower our clients with data-driven tools for smarter decision-making, enabling sustainability and global positive change through innovative solutions.
            </Typography>


          <Typography
            variant="h5"
            component="div"
            color={"white"}
            style={{ fontWeight: "bold", color: "#f27e30",fontFamily:'Gilroy-Bold' }}
          >
            <strong>Our Services</strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: Primary,     }}>


          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "20px",
              margin: "0 auto",
         
              width:
                windowSize.width < 992
                  ? "100%"
                  : windowSize.width < 1025
                    ? "90%"
                    : windowSize.width < 1960
                      ? "68%"
                      : "55%",
            }}
    
          >

            <ServicesCard/>
            {/* {[...Array(4)].map((_, index) => (
              <Card
                key={index}
                style={{
                  padding: "20px",
                  borderRadius: "10px",
                  backgroundColor: Secondary,
                  width: "calc(33.33% - 20px)",  
                  minWidth: "250px",  
                }}
              >
                <CardContent>
                  <img src={Service1} style={{ marginBottom: "10px", width: "100%" }} />
                  <Typography
                    variant="h6"
                    color={THEME_HEADING_COLOR}
                    mb={2}
                    style={{ fontWeight: "bold", fontFamily: "Gilroy-Bold" }}
                  >
                    <strong>{"Precision Crop Monitoring Solutions"}</strong>
                  </Typography>
                  <Typography
                    variant="body1"
                    color={THEME_TEXT_COLOR}
                    mb={2}
                    style={{ fontFamily: "Gilroy-Medium" }}
                  >
                    {`Our Precision Crop Monitoring Solutions provide real-time, AI-powered insights to optimize farm productivity, manage risks, and improve sustainability.`}
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button id="learn_more" size="small">View Details</Button>
                  <Button id="contact_us" size="small">Contact Us</Button>
                </CardActions>
              </Card>
            ))} */}
          </Box>


     
        {/* <Box
          style={{
            position: "relative",
            borderRadius: "10px",
            margin: "0 auto",
            marginBottom: "40px",
            width:
            windowSize.width < 992
              ? "100%"
              : windowSize.width < 1025
              ? "90%"
              : windowSize.width < 1960
              ? "70%"
              : "55%",
          }}
        >
          {windowSize.width < 1300 ? (
            <SmallerCard
              imageURL={service_1}
              titleText="Agriculture Monitoring and Precision Agriculture"
              bodyText="Our company's remote sensing-based precision agriculture services are designed to transform the agricultural sector by providing advanced, data-driven insights that optimize farming practices. By leveraging the power of remote sensing technology, we enable farmers and agribusinesses to monitor crop health, soil conditions, and environmental factors with unprecedented accuracy and detail. This information empowers them to make informed decisions, enhancing crop yields, reducing waste, and improving the overall efficiency of their operations. Our services are not just about adopting new technology; they're about embracing a future where agriculture is sustainable, productive, and in harmony with the environment, ensuring prosperity for today's farmers and generations to come."
            />
          ) : (
            <FinalCard
              imageURL={service_1}
              titleText="Agriculture Monitoring and Precision Agriculture"
              bodyText="Our company's remote sensing-based precision agriculture services are designed to transform the agricultural sector by providing advanced, data-driven insights that optimize farming practices. By leveraging the power of remote sensing technology, we enable farmers and agribusinesses to monitor crop health, soil conditions, and environmental factors with unprecedented accuracy and detail. This information empowers them to make informed decisions, enhancing crop yields, reducing waste, and improving the overall efficiency of their operations. Our services are not just about adopting new technology; they're about embracing a future where agriculture is sustainable, productive, and in harmony with the environment, ensuring prosperity for today's farmers and generations to come."
              leftPosition={true}
            />
          )}
        </Box> */}
      </Grid>

     
      {/* NEW PASTED  */}


      {/* <Grid item xs={12} style={{ backgroundColor: Primary }}>
        <Box
          style={{
            position: "relative",
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
          }}
        >
          {windowSize.width < 1300 ? (
            <SmallerCard
              imageURL={ess8}
              titleText="ESS applies cutting-edge remote sensing and machine learning techniques"
              bodyText="ESS applies cutting-edge remote sensing and machine learning techniques to analyze vegetation indices such as NDVI, MSAVI, NDRE, and ReCI, providing precise estimations of biomass accumulation and carbon storage. By combining multi-source satellite data, ESS ensures high temporal and spatial accuracy in detecting deforestation, land degradation, and sustainable land management practices. This data-driven approach supports farmers, agribusinesses, and environmental organizations in meeting carbon credit certification requirements while promoting climate-smart agricultural practices. By offering transparent, reliable, and verifiable carbon monitoring solutions, ESS empowers stakeholders to maximize their sustainability impact and contribute to global climate change mitigation efforts."
            />
          ) : (
            <FinalCard
              imageURL={ess8}
              titleText="ESS applies cutting-edge remote sensing and machine learning techniques"
              bodyText="ESS applies cutting-edge remote sensing and machine learning techniques to analyze vegetation indices such as NDVI, MSAVI, NDRE, and ReCI, providing precise estimations of biomass accumulation and carbon storage. By combining multi-source satellite data, ESS ensures high temporal and spatial accuracy in detecting deforestation, land degradation, and sustainable land management practices. This data-driven approach supports farmers, agribusinesses, and environmental organizations in meeting carbon credit certification requirements while promoting climate-smart agricultural practices. By offering transparent, reliable, and verifiable carbon monitoring solutions, ESS empowers stakeholders to maximize their sustainability impact and contribute to global climate change mitigation efforts."
              rightPosition={true}
            />
          )}
        </Box>
      </Grid>



      <Grid item xs={12} style={{ backgroundColor: Primary }}>
        <Box
          style={{
            position: "relative",
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
          }}
        >
          {windowSize.width < 1300 ? (
            <SmallerCard
              imageURL={cm1}
              titleText="Earth Scan Systems (ESS) utilizes satellite data to provide robust vegetation monitoring services essential for carbon credit claims."
              bodyText=" These satellites offer a comprehensive dataset covering historical trends, high-resolution vegetation assessments, and real-time monitoring to track carbon sequestration efforts. Landsat 5 provides a long-term record of vegetation changes since 1984, while Landsat 8 enhances monitoring with improved spectral capabilities. Sentinel-2, with its high spatial resolution and frequent revisit times, allows for detailed monitoring of vegetation health, canopy structure, and land cover dynamics. Sentinel-3, equipped with advanced ocean and land monitoring sensors, supports large-scale biomass estimation and ecosystem health assessments. This integrated approach ensures accurate, science-backed quantification of carbon sequestration, enabling stakeholders to validate their carbon offset initiatives with confidence."
            />
          ) : (
            <FinalCard
              imageURL={cm1}
              titleText="Earth Scan Systems (ESS) utilizes satellite data to provide robust vegetation monitoring services essential for carbon credit claims."
              bodyText="These satellites offer a comprehensive dataset covering historical trends, high-resolution vegetation assessments, and real-time monitoring to track carbon sequestration efforts. Landsat 5 provides a long-term record of vegetation changes since 1984, while Landsat 8 enhances monitoring with improved spectral capabilities. Sentinel-2, with its high spatial resolution and frequent revisit times, allows for detailed monitoring of vegetation health, canopy structure, and land cover dynamics. Sentinel-3, equipped with advanced ocean and land monitoring sensors, supports large-scale biomass estimation and ecosystem health assessments. This integrated approach ensures accurate, science-backed quantification of carbon sequestration, enabling stakeholders to validate their carbon offset initiatives with confidence."
              leftPosition={true}
            />
          )}
        </Box>
      </Grid> */}



     

      {/* END NEW PASED */}

      {/* <Grid item xs={12}  style={{ backgroundColor: Primary }}>
        <Box
          style={{
            position: "relative",
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "50px",
            width:
              windowSize.width < 992
                ? "100%"
                : windowSize.width < 1025
                ? "90%"
                : windowSize.width < 1960
                ? "70%"
                : "55%",
          }}
        >
          {windowSize.width < 1300 ? (
            <SmallerCard
              imageURL={service_3}
              titleText="Monitor, Measure, and Monetize: Elevate Your Forest Management with Our Advanced Monitoring for Carbon Credits and Sustainability"
              bodyText="Our advanced forest monitoring services are designed to empower stakeholders in the management and conservation of forests, aligning with the dual goals of earning carbon credits and promoting sustainable development. Utilizing state-of-the-art technology, we provide precise monitoring and data analysis, enabling forest owners and managers to track carbon sequestration, assess forest health, and detect changes over time. This data not only facilitates the generation of carbon credits, offering financial incentives for conservation efforts, but also supports informed decision-making for sustainable forest management. Our approach ensures that forests are valued not just for their timber but as vital carbon sinks and biodiversity reservoirs, contributing to global environmental goals and sustainable development objectives."
            />
          ) : (
            <FinalCard
              imageURL={service_3}
              titleText="Monitor, Measure, and Monetize: Elevate Your Forest Management with Our Advanced Monitoring for Carbon Credits and Sustainability"
              bodyText="Our advanced forest monitoring services are designed to empower stakeholders in the management and conservation of forests, aligning with the dual goals of earning carbon credits and promoting sustainable development. Utilizing state-of-the-art technology, we provide precise monitoring and data analysis, enabling forest owners and managers to track carbon sequestration, assess forest health, and detect changes over time. This data not only facilitates the generation of carbon credits, offering financial incentives for conservation efforts, but also supports informed decision-making for sustainable forest management. Our approach ensures that forests are valued not just for their timber but as vital carbon sinks and biodiversity reservoirs, contributing to global environmental goals and sustainable development objectives."
           
              rightPosition={true}
            />
          )}
        </Box>
      </Grid>


      <Grid item xs={12} style={{ backgroundColor: Primary }}>
        <Box
          style={{
            position: "relative",
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "100%"
                : windowSize.width < 1025
                ? "90%"
                : windowSize.width < 1960
                ? "70%"
                : "55%",
          }}
        >
          {windowSize.width < 1300 ? (
            <SmallerCard
              imageURL={service_2}
              titleText="Empower Sustainable Development: Innovative Land and Water Management Services Tailored for Tomorrow"
              bodyText="Our company excels in delivering state-of-the-art land and water management services, integrating remote sensing, Geographic Information Systems (GIS), and automation to champion sustainable development. Utilizing remote sensing, we capture detailed environmental data, which, when analysed through our sophisticated GIS, offers comprehensive insights into land and water use efficiency. Automation plays a pivotal role in translating these insights into actionable strategies, streamlining processes for optimal resource management. This synergy of technologies enables our clients to make informed decisions, ensuring the sustainable utilization and conservation of natural resources. Our commitment is to empower businesses, communities, and governments to not only meet their development objectives but also to do so in a way that safeguards the environment for future generations."
            />
          ) : (
            <FinalCard
              imageURL={service_2}
              titleText="Empower Sustainable Development: Innovative Land and Water Management Services Tailored for Tomorrow"
              bodyText="Our company excels in delivering state-of-the-art land and water management services, integrating remote sensing, Geographic Information Systems (GIS), and automation to champion sustainable development. Utilizing remote sensing, we capture detailed environmental data, which, when analysed through our sophisticated GIS, offers comprehensive insights into land and water use efficiency. Automation plays a pivotal role in translating these insights into actionable strategies, streamlining processes for optimal resource management. This synergy of technologies enables our clients to make informed decisions, ensuring the sustainable utilization and conservation of natural resources. Our commitment is to empower businesses, communities, and governments to not only meet their development objectives but also to do so in a way that safeguards the environment for future generations."
              leftPosition={true}
            />
          )}
        </Box>
      </Grid>
      */}

        
    
      {/* <Grid item xs={12} style={{ backgroundColor: Primary }}>
        <Box
          pt={5}
          pb={1}
          pr={2}
          style={{
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "10px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <Box
            style={{
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: "#f27e30" }}
            >
              <strong>INDUSTRIES</strong>
            </Typography>
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold" }}
            >
              <strong>
                Discover effective solutions for the top industries plus more
                upon request
              </strong>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: Primary }}>
        <Box
          style={{
            borderRadius: "50px",
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "95%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
          }}
        >
          <CustomCarousel />
        </Box>
      </Grid> */}
    </Grid>
    </div>
  );
}
