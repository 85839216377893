import { useEffect, useState } from "react";
import { Box, Typography, Grid, Card, CardContent, Button } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import { Oval } from "react-loader-spinner";
import { ASSET_IMAGE, Primary, Secondary, THEME_TEXT_COLOR, TUTORIAL_IMAGE } from "../utils/constants";

export default function TutorialComponent() {
  const windowSize = useWindowSize();
  // const [tutorialData, setTutorialData] = useState(
  //   [{
  //     id:1,
  //     "heading":"1. Create an Account",
  //     "image":`${TUTORIAL_IMAGE}/1.png`,
  //     "inner_heading":"Request an Account",
  //     "desc":"Go to http://localhost:3000/registration and click on request an account button, user will be presented with a prompt."
  //   },
  //   {
  //     id:2,
  //     "heading":"",
  //     "image":`${TUTORIAL_IMAGE}/2.png`,
  //     "inner_heading":"Registration Details",
  //     "desc":"User will be presented with a popup to create and account, after entering necessary details user can submit the form and it will be available for admin to review the request."
  //   },
  //   {
  //     id:3,
  //     "heading":"2. Login to ESS Crop Monitoring",
  //     "image":`${TUTORIAL_IMAGE}/3.png`,
  //     "inner_heading":"",
  //     "desc":"After receiving username and password, user needs to enter the required information to view Monitoring Dashboard."
  //   },
  //   {
  //     id:4,
  //     "heading":"",
  //     "image":`${TUTORIAL_IMAGE}/4.png`,
  //     "inner_heading":"Monitoring Dashboard",
  //     "desc":"After successful login, User will be presented with GIS Map. On the Map User can see their own region and advance monitoring tools"
  //   }, 
  //   {
  //     id:5,
  //     "heading":"Monitoring Dashboard",
  //     "image":`${TUTORIAL_IMAGE}/5.png`,
  //     "inner_heading":"",
  //     "desc":"User can see list of tools on the left of the screen, as described in the image below."+
  //     " \n\n From top to bottom: \n\n 1. Search option: User can search by location name / LatLng. \n"+
  //     " \n 2. Zoom control: User can control zoom level of the GIS Map \n"+
  //     " \n 3. Draw Field: User can digitize any field on GIS Map to start monitoring. \n"+
  //     " \n 4. Upload Field: User can upload kml and their own field and start monitoring.\n"+
  //     " \n 5. Comparison View: User can compare statistics on different dates i.e., User can choose different date and compare different indices NDVI, MSAVI, NDRE, NDMI and RECI Left / Right on the screen as explain in the Secion Comparison View.\n"
  //   },
  //   {
  //     id:6,
  //     "heading":"",
  //     "image":`${TUTORIAL_IMAGE}/6.png`,
  //     "inner_heading":"Upload KML",
  //     "desc":"User can upload KML to start monitoring, our smart system will detect the user field and display it on the GIS Map. GIS Map will display all of the necessary information to take care of your field."
  //   },
  //   {
  //     id:5,
  //     "heading":"",
  //     "image":`${TUTORIAL_IMAGE}/7.png`,
  //     "inner_heading":"My fields",
  //     "desc":"Our advance algorithms create user profile by keeping the user monitored fields in cloud and present user to keep the track of each and every fiels, User can simply click on listed field and it will display latest available data for the field. \n User can click on detailed button and it will take you the field."
  //   },
  //   {
  //     id:7,
  //     "heading":"",
  //     "image":`${TUTORIAL_IMAGE}/8.png`,
  //     "inner_heading":"Monitoring dashboard",
  //     "desc":"Our AI Driven system, create user customized dashboard where: \n "+
  //     "\n 1. User can view everything on one screen. including Field information like Area, Crop grown and sowing date for record keeping (explained in section Field details). \n "+
  //     "\n 2. User can select any sentinel date or go with the any custom date. \n "+
  //     "\n 3. User can view weather analytics, including historical (2m resolution) weather and 14-days forcast. \n "+
  //     "\n 4. User can Select among NDVI, MSAVI, NDRE, NDMI and RECI for monitoring. \n "+
  //     "\n 5. User can calculate crop water requirements for the focused field. \n "+
  //     "\n 6. User can ask question to our advance AI Bot for further assistance <img src='https://dmenjsnbha54b.cloudfront.net/images/full-bot.png' alt='ESS-BOT' width='25px'> \n " 
  //   },{
  //     id:8,
  //     "heading":"",
  //     "image":`${TUTORIAL_IMAGE}/9.png`,
  //     "inner_heading":"Fields details",
  //     "desc":"For record keeping, user can add necessary information to view anytime."
  //   },
  //   {
  //     id:9,
  //     "heading":"",
  //     "image":`${TUTORIAL_IMAGE}/11.png`,
  //     "inner_heading":"AI Analytics",
  //     "desc":"User can calculate and display AI analytics by simply clicking on Zoning button on in the menu "
  //   },

  //   {
  //     id:10,
  //     "heading":"",
  //     "image":`${TUTORIAL_IMAGE}/10.png`,
  //     "inner_heading":"Comparison View",
  //     "desc":""
  //   },
  //   ]
  // );

  const [tutorialData, setTutorialData] = useState([
    {
      id: 1,
      heading: "1. Create an Account",
      image: `${TUTORIAL_IMAGE}/1.png`,
      inner_heading: "Request an Account",
      desc: "Navigate to https://escan-systems.com/registration and click on the 'Request an Account' button. A prompt will appear for you to proceed."
    },
    {
      id: 2,
      heading: "",
      image: `${TUTORIAL_IMAGE}/2.png`,
      inner_heading: "Registration Details",
      desc: "A popup will appear to create an account. Fill in the required details and submit the form. Your request will be sent to the admin for review."
    },
    {
      id: 3,
      heading: "2. Login to ESS Crop Monitoring",
      image: `${TUTORIAL_IMAGE}/3.png`,
      inner_heading: "",
      desc: "Once you receive your username and password, use them to log in and access the ESS Crop Monitoring."
    },
    {
      id: 4,
      heading: "",
      image: `${TUTORIAL_IMAGE}/4.png`,
      inner_heading: "Monitoring Dashboard",
      desc: "After logging in, you will see a GIS map displaying your region. This dashboard provides advanced monitoring tools."
    },
    {
      id: 5,
      heading: "3. Monitoring Dashboard Features",
      image: `${TUTORIAL_IMAGE}/5.png`,
      inner_heading: "",
      desc: `
        The dashboard includes various tools accessible on the left side of the screen: \n
        1. Search : Look up locations by name or coordinates (Lat/Lng). \n
        2. Zoom Control : Adjust the zoom level of the GIS map. \n
        3. Draw Field : Digitize any field on the GIS map for monitoring. \n
        4. Upload Field : Import KML files of your fields for analysis. \n
        5. Comparison View : Compare statistics such as NDVI, MSAVI, NDRE, NDMI, and RECI for different dates side by side.`
    },
    {
      id: 6,
      heading: "",
      image: `${TUTORIAL_IMAGE}/6.png`,
      inner_heading: "Upload KML",
      desc: "Upload KML files to visualize your fields on the GIS map. Our system will display all necessary information to help manage your fields effectively."
    },
    {
      id: 5, // did this on purpose
      heading: "",
      image: `${TUTORIAL_IMAGE}/7.png`,
      inner_heading: "My Fields",
      desc: "Our system keeps track of your monitored fields in the cloud. You can click on any listed field to view the latest data.  \n For detailed information, click the 'Details' button."
    },
    {
      id: 7,
      heading: "",
      image: `${TUTORIAL_IMAGE}/8.png`,
      inner_heading: "Monitoring Dashboard Insights",
      desc: `
        The AI-driven dashboard provides: \n
        1. Field details like area, crop type, and sowing date for record-keeping. \n
        2. Options to select sentinel or custom dates for analysis. \n
        3. Weather analytics, including historical data and a 14-day forecast. \n
        4. Indices for monitoring (e.g., NDVI, MSAVI, NDRE, NDMI, RECI). \n
        5. Crop water requirement calculations. \n
        6. Assistance via an advanced AI bot (Green bot) <img src='https://dmenjsnbha54b.cloudfront.net/images/full-bot.png' alt='ESS-BOT' width='25px'>.  \n`
    },
    {
      id: 8,
      heading: "",
      image: `${TUTORIAL_IMAGE}/9.png`,
      inner_heading: "Field Details",
      desc: "Maintain comprehensive records by adding relevant information about your fields."
    },
    {
      id: 9,
      heading: "",
      image: `${TUTORIAL_IMAGE}/11.png`,
      inner_heading: "AI Analytics",
      desc: "Click on the 'Zoning' button in the menu to calculate and display AI-driven analytics."
    },
    {
      id: 10,
      heading: "",
      image: `${TUTORIAL_IMAGE}/10.png`,
      inner_heading: "Comparison View",
      desc: "Compare field statistics across different dates using visual tools for insights."
    }, {
      id: 5,
      heading: "4. Agri Insights",
      image: `${TUTORIAL_IMAGE}/in.png`,
      inner_heading: "",
      desc: ``
    },
  ]);


  



  useEffect(() => {
    // fetch("https://escan-systems.com/api/privacy-policy")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data && data.sections) {
    //       setPrivacyData(data);
    //     } else {
    //       console.error("Unexpected data format:", data);
    //     }
    //   })
    //   .catch((error) => console.error("Error fetching privacy policy:", error));
  }, []);

  return (
    <div id="Privacy">
      <Grid container component="main">
        <Grid item xs={12} style={{ backgroundColor: Primary }}>
          <Box
            pt={5}
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              marginTop: "92px",
              marginBottom: "2px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: "#f27e30", fontSize: '2rem' }}
            >
              <strong>Earth Scan Systems Crop Monitoring Guide</strong>
            </Typography>
            
            <Typography
                  variant="body1"
                  component="div"
                  color={"grey"}
                  mb={3}
                  textAlign={"left"}
                  style={{ fontWeight: "bold" }}
                >
                  <strong>Updated on: {"July 25, 2024"}</strong>
                </Typography>

        


          </Box>
        </Grid>

        <Grid item xs={12} style={{ backgroundColor:Primary}}>
          <Box 
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              
              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%", 
            }}
          >
            
        
            <>
            {tutorialData.map((section, index) => (
              <div key={index}>
               
                  <Typography
                    variant="h6"
                    component="div"
                    color={"white"}
                    style={{ fontWeight: "bold", color: "#f27e30",  marginBottom: "10px",marginTop:"10px"}}
                  >
                    <strong>{section.heading}</strong>
                  </Typography>
  

                <Card
                  style={{
                    padding: "5px",  
                    backgroundColor:Primary,
                    boxShadow:'none'


                  }}
                >
                  <CardContent>
 
                    <Typography
                      variant="body1"
                      component="div"
                      color={THEME_TEXT_COLOR}
                      mb={1}
                      textAlign={"left"}
                      style={{ fontWeight: "bold" }}
                    >
                      <strong>
                        {section.inner_heading}
                      </strong>
                    </Typography>

                    <Typography
                      variant="body1"
                      component="div"
                      color={THEME_TEXT_COLOR}
                      mb={1}
                      textAlign={"left"}
                      style={{ fontSize:'1rem' }}
                    >
                      {
                        (section.id == 5 || section.id == 7 ) ? <>  <strong
                          dangerouslySetInnerHTML={{
                            __html: section.desc.replace(/\n/g, "<br />"),
                          }}
                        />
                        </> : <>
                          <strong>
                            {section.desc}
                          </strong>
                        </>
                      }
                     
                    </Typography>
 
                  </CardContent>
                </Card>
                {
                  (section.id == 5 || section.id == 9  ) ? <>
                    <Box
                      style={{
                        borderRadius: "20px",
                        backgroundImage:
                          `url(${section.image})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: "center",
                        height: windowSize.width < 1950 ? "50vh" : "35vh",
                      }}
                    />
                  </> : <>
                  {
                   (section.id==7 || section.id == 10) ?<>
                    
                    <Box
                      style={{
                        borderRadius: "20px",
                        backgroundImage:
                          `url(${section.image})`,
                        backgroundSize: "contain", backgroundRepeat: 'no-repeat',
                        backgroundPosition: "center",
                        height:"60vh",
                      }}
                    />
                    
                    </>:<>
                    
                    <Box
                      style={{
                        borderRadius: "20px",
                        backgroundImage:
                          `url(${section.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: windowSize.width < 1950 ? "50vh" : "35vh",
                      }}
                    />
                    
                    </>
                  }
                  

                  </>
                }
                

              </div>
            ))}






          </>

          </Box>
        </Grid>

        
      </Grid>
    </div>
  );
}
