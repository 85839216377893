 
import React, { useEffect, useState } from "react";
 
import Maps from './Maps';
import ContactUs from './ContactUs';
import CustomHeader from '../home/NewHeader';
import CustomFooter from '../home/FooterWhite';
export default function CompleteContact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
    return (
      <>
        {/* <Header /> */}
        <CustomHeader />
        <ContactUs />
        <Maps /> 
        <CustomFooter /> 
        {/*
      */}
      
        {/* <Footer /> */}
      </>
    );
  }
  