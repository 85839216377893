import React, { useState } from "react";
import { Container, Grid, TextField, Button, Typography as TGP, Box, Stack, CardContent } from "@mui/material";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
import ForgotPasswordModal from "../utils/ForgotPasswordModal";
import { useAuth } from "../hooks/useAuth";
import { Oval } from "react-loader-spinner";
import { Col, Typography } from "antd";
import RequestAccountModal from "../utils/RequestAccountModal";
import { useRouter } from "../routes/hooks";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { trackEvent } from "../utils/analytics";
import moment from "moment";
import { Secondary,THEME_TEXT_COLOR } from "../utils/constants";
const LoginCard = () => {
  const router = useRouter();

  const { loginAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [openRequestAccountModal, setOpenRequestAccountModal] = React.useState(false);
  const [isLoginPage, setIsLoginPage] = useState(true);
  const [isOTPLayout, setIsOTPLayout] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [otp, setOtp] = useState('');

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const handleResendOtp = () => {
    // Resend OTP logic goes here
    console.log('Resend OTP triggered');
    setTimer(30); // Reset the timer
    setIsResendDisabled(true); // Disable the button again
  };

  React.useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setIsResendDisabled(false);
    }
  }, [timer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(name)

    if (name == "password") {
      const passwordLength = value.length >= 8;
      if (!passwordLength) {
        setShowErrorMessage("Password must be of length 8 or greater")
      } else {
        setShowErrorMessage("")
      }
    }

    // if(formData.password == formData.confirmPassword){

    //   setShowErrorMessage("")

    // }else{

    //   setShowErrorMessage("Password and confirm Password do not match")

    // }

    setFormData({ ...formData, [name]: value });
  };

  const [errors, setErrors] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState("");
  const [showNameErrorMessage, setShowNameErrorMessage] = useState("");
  const [showOTPErrorMessage, setShowOTPErrorMessage] = useState("");



  const loginUser = async () => {
    setShowLoader(true);
    if (isLoginPage) {
      const endPoint = "https://escan-systems.com/api/signin";
      const data = { email, password };
      try {

        trackEvent("User Interaction", "Login Button Clicked", `${JSON.stringify(email)} clicked login button at ${moment().format('YYYY-MM-DD HH:mm:ss')}`);

        const response = await axios.post(endPoint, data);
        localStorage.setItem("limit", response.data.area.exceedsLimit)
        localStorage.setItem("showModal", response.data.area.exceedsLimit)
        localStorage.setItem("monitoredArea", response.data.area.totalArea)
        await loginAuth({ token: response.data.token, status: response.data.status, plan: response.data.plan, email: response.data.email });
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        toast.error("Invalid credentials", {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark", containerId: "messageContainer"
        });
      }
    } else {

      if (isOTPLayout) {

        const { firstName, lastName, email, password, confirmPassword } = formData;

        const finalData = {
          name: `${firstName} ${lastName}`.trim(),
          email,
          password,
          otp
        };
        if (!otp) {
          setShowOTPErrorMessage("Security verification code is required");
          setShowLoader(false);
          return;
        } else {
          setShowOTPErrorMessage("");
        }


        const endPoint = "https://escan-systems.com/api/verify-otp";
        //const data = { email, password };
        try {
          const response = await axios.post(endPoint, finalData);
          //console.log(response.data.message)
          // if(response.data.message == "OTP generated successfully."){
          //   setIsLoginPage(false)
          //   setIsOTPLayout(true)
          //   toast.success("OTP has been sent on your email", {
          //    autoClose: 5000,
          //    hideProgressBar: true,
          //    closeOnClick: true,
          //    pauseOnHover: false,
          //    draggable: false,
          //    progress: undefined,
          //    theme: "dark",
          //  });

          // }
          if (response.data.success == true) {
            toast.success(response.data.message, {
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark", containerId: "messageContainer"
            });
            setIsOTPLayout(false)
            setIsLoginPage(true)
          } else {
            toast.error(response.data.message, {
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark", containerId: "messageContainer"
            });
          }

          setShowLoader(false);
        } catch (error) {
          setShowLoader(false);
          toast.error("Something went wrong", {
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark", containerId: "messageContainer"
          });
        }

      } else {


        const { firstName, lastName, email, password, confirmPassword } = formData;

        const passwordMatch = password === confirmPassword;
        if (!formData.firstName || !formData.lastName) {
          setShowNameErrorMessage("Name can not be empty")
          setShowLoader(false);
          return;
        } else {
          setShowNameErrorMessage("")
        }
        if (!formData.password || !formData.confirmPassword) {
          setShowErrorMessage("Password can not be empty");
          setShowLoader(false);
          return;
        } else if (!passwordMatch) {
          setShowErrorMessage("Password and confirm password do not match");
          setShowLoader(false);
          return;
        } else {
          setShowErrorMessage("");
        }

        if (!formData.email) {

          setShowEmailErrorMessage('Please enter a valid email address.');
          setShowLoader(false);
          return;

        } else {
          setShowEmailErrorMessage("")
        }

        //  const passwordLength = password.length >= 8;

        // if (!passwordMatch || !passwordLength) {

        //   setErrors({ passwordMatch: !passwordMatch, passwordLength: !passwordLength });

        //   return;

        // }

        const finalData = {
          name: `${firstName} ${lastName}`.trim(),
          email,
          password,
        };


        const endPoint = "https://escan-systems.com/api/generate-otp";
        //const data = { email, password };
        try {
          const response = await axios.post(endPoint, finalData);
          //console.log(response.data.message)
          if (response.data.message == "OTP generated successfully.") {


            console.log(response.data.message)
            setIsLoginPage(false)
            setIsOTPLayout(true)
            toast.success("Verification code has been sent on your email", {
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
              containerId: "messageContainer"
            });

          }
          // await loginAuth({ token: response.data.token });
          setShowLoader(false);
        } catch (error) {
          setShowLoader(false);
          toast.error("Invalid credentials", {
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark", containerId: "messageContainer"
          });
        }
      }
    }

  };

  return (
    <>
      <Container style={{ width: "100%", }}>
        <Grid container spacing={3} mt={2}>

          {
            isLoginPage ? <>

              <Grid item xs={12}>
                <TextField
                  id="outlined-email-input"
                  label="Email"
                  placeholder="example@gmail.com *"
                  fullWidth
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  autoComplete="off"


                  sx={{
                    input: {
                      color: {THEME_TEXT_COLOR},
                      background: {Secondary},
                      borderRadius: '15px',
                    },
                    '& .MuiOutlinedInput-root': {

                      '&.Mui-focused fieldset': {
                        borderColor: 'rgb(135 120 73)',
                      },
                    },
                  }}

                  InputLabelProps={{
                    style: { color: THEME_TEXT_COLOR, fontSize: "15px" },
                  }}
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  fullWidth
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  autoComplete="off"
                  sx={{
                    input: {
                      color: {THEME_TEXT_COLOR},
                      background: {Secondary},
                      borderRadius: '15px',
                    },
                    '& .MuiOutlinedInput-root': {

                      '&.Mui-focused fieldset': {
                        borderColor: 'rgb(135 120 73)',
                      },
                    },
                  }}

                  InputLabelProps={{
                    style: { color: {THEME_TEXT_COLOR}, fontSize: "15px" },
                  }}
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Grid>

            </> :

              <>
                {
                  isOTPLayout ?

                    <>
                      <Grid item xs={12} sm={12}>


                        <Grid container item xs={12} sm={12} alignItems="center" justifyContent="space-between" mt={1} mb={3}>
                          <ArrowBackIcon
                            onClick={() => {
                              setIsOTPLayout(false)
                              setIsLoginPage(false)
                            }}
                            style={{ color: '#fff', padding: 1, marginLeft: 10, cursor: 'pointer' }} />
                          <TGP
                            mr={3}
                            ml={3}
                            variant="h5"
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '0.9rem',
                              color: {THEME_TEXT_COLOR},
                              textAlign: 'center',
                              flexGrow: 1,
                            }}
                          >
                            Verify verification code sent at your email address

                          </TGP>
                        </Grid>


                        <TextField
                          id="outlined-first-name-input"
                          placeholder="Enter verification code"
                          fullWidth
                          name="otp"
                          length="6"
                          autoComplete="off"
                          onChange={(event) => {
                            const value = event.target.value.replace(/[^0-9]/g, '');
                            if (value.length <= 6) {
                              setOtp(value);
                            }
                          }}
                          value={otp}
                          sx={{
                            input: {
                              color: {THEME_TEXT_COLOR},
                              background: {Secondary},
                              borderRadius: '5px',
                            },
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: 'rgb(135 120 73)',
                              },
                            },
                          }}

                          InputLabelProps={{
                            style: { color: {THEME_TEXT_COLOR}, fontSize: "15px" },
                          }}
                          InputProps={{ style: { borderRadius: "5px" } }}
                        />


                        <Typography color="error" variant="caption" style={{ color: "#ff6b6be0", paddingLeft: '2px', paddingTop: "2px" }} >
                          {showOTPErrorMessage}
                        </Typography>

                      </Grid>

                      {/* <div style={{ marginBottom: '20px', marginTop: "20px" }}>
                        <Typography variant="body2" >
                          {isResendDisabled ? `Retry OTP in ${timer}s` : 'You can now resend the OTP'}
                        </Typography>
                      </div>
                      <Button
                        variant="contained"
                        onClick={handleResendOtp}
                        disabled={isResendDisabled}
                        style={{
                          backgroundColor: isResendDisabled ? '#6c757d' : '#b79e5d',
                          color: {THEME_TEXT_COLOR},
                          padding: '10px 20px',
                          borderRadius: '15px',
                        }}
                      >
                        Resend OTP
                      </Button> */}
                    </> :

                    <>


                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-first-name-input"
                          label="First Name"
                          fullWidth
                          name="firstName"
                          autoComplete="off"
                          onChange={handleChange}
                          value={formData.firstName}
                          sx={{
                            input: {
                              color: {THEME_TEXT_COLOR},
                              background: {Secondary},
                              borderRadius: '15px',
                            },
                            '& .MuiOutlinedInput-root': {

                              '&.Mui-focused fieldset': {
                                borderColor: 'rgb(135 120 73)',
                              },
                            },
                          }}

                          InputLabelProps={{
                            style: { color: {THEME_TEXT_COLOR}, fontSize: "15px" },
                          }}
                          InputProps={{ style: { borderRadius: "15px" } }}
                        />
                        <Typography color="error" variant="caption" style={{ color: "#ff6b6be0", paddingLeft: '2px', paddingTop: "2px" }} >
                          {showNameErrorMessage}
                        </Typography>

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-last-name-input"
                          label="Last Name"
                          name="lastName"
                          fullWidth
                          autoComplete="off"
                          onChange={handleChange}
                          value={formData.lastName}
                          sx={{
                            input: {
                              color: {THEME_TEXT_COLOR},
                              background: {Secondary},
                              borderRadius: '15px',
                            },
                            '& .MuiOutlinedInput-root': {

                              '&.Mui-focused fieldset': {
                                borderColor: 'rgb(135 120 73)',
                              },
                            },
                          }}

                          InputLabelProps={{
                            style: { color: {THEME_TEXT_COLOR}, fontSize: "15px" },
                          }}
                          InputProps={{ style: { borderRadius: "15px" } }}
                        />



                      </Grid>
                      <Grid>


                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-email-input"
                          label="Email"
                          placeholder="example@gmail.com *"
                          fullWidth
                          value={formData.email}
                          onChange={handleChange}
                          name="email"
                          autoComplete="off"


                          sx={{
                            input: {
                              color: {THEME_TEXT_COLOR},
                              background: {Secondary},
                              borderRadius: '15px',
                            },
                            '& .MuiOutlinedInput-root': {

                              '&.Mui-focused fieldset': {
                                borderColor: 'rgb(135 120 73)',
                              },
                            },
                          }}

                          InputLabelProps={{
                            style: { color: {THEME_TEXT_COLOR}, fontSize: "15px" },
                          }}
                          InputProps={{ style: { borderRadius: "15px" } }}
                        />

                        <Typography color="error" variant="caption" style={{ color: "#ff6b6be0", paddingLeft: '2px', paddingTop: "2px" }} >
                          {showEmailErrorMessage}
                        </Typography>

                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-password-input"
                          label="Password"
                          type="password"
                          name="password"
                          fullWidth
                          value={formData.password}
                          onChange={handleChange}
                          autoComplete="off"
                          sx={{
                            input: {
                              color: {THEME_TEXT_COLOR},
                              background: {Secondary},
                              borderRadius: '15px',
                            },
                            '& .MuiOutlinedInput-root': {

                              '&.Mui-focused fieldset': {
                                borderColor: 'rgb(135 120 73)',
                              },
                            },
                          }}

                          InputLabelProps={{
                            style: { color: {THEME_TEXT_COLOR}, fontSize: "15px" },
                          }}
                          InputProps={{ style: { borderRadius: "15px" } }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-password-input"
                          label="Confirm password"
                          type="password"
                          name="confirmPassword"
                          fullWidth
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          autoComplete="off"
                          sx={{
                            input: {
                              color: {THEME_TEXT_COLOR},
                              background: {Secondary},
                              borderRadius: '15px',
                            },
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: 'rgb(135 120 73)',
                              },
                            },
                          }}

                          InputLabelProps={{
                            style: { color: {THEME_TEXT_COLOR}, fontSize: "15px" },
                          }}
                          InputProps={{ style: { borderRadius: "15px" } }}
                        />

                        <Typography color="error" variant="caption" style={{ color: "#ff6b6be0", paddingLeft: '2px', paddingTop: "2px" }} >
                          {showErrorMessage}
                        </Typography>

                      </Grid>


                    </>
                }

              </>
          }

          {
            isOTPLayout ? <> </> : <>

              <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>

                <Typography style={{ color: "#877849", cursor: 'pointer' }} onClick={() => setIsLoginPage(!isLoginPage)}>
                  {
                    isLoginPage ? <> Create an account? </> : <> Already have an account? </>
                  }
                </Typography>


                <Typography style={{ color: "#877849", cursor: 'pointer' }} onClick={() => { router.push("/how-to") }}>
                  How to Monitor?
                </Typography>


                <Typography style={{ color: "#877849", cursor: 'pointer' }} onClick={() => setOpenForgotPasswordModal(true)}>
                  Forgot Password?
                </Typography>


              </Grid>

            </>
          }




          <Grid item xs={12} mb={3}>
            <Button
              disabled={showLoader}
              variant="contained"
              style={{
                background: "#877849",
                color: {THEME_TEXT_COLOR},
                marginTop: "10px",
                width: "100%",
                padding: "12px",
                borderRadius: "15px",
                fontWeight: "bold",
                fontSize: "15px",
                textTransform: "capitalize",
              }}
              onClick={loginUser}
            >
              Proceed
              <Oval
                visible={showLoader}
                height="20"
                radius="20"
                width="20"
                color="#fff"
                secondaryColor="#cccc"
                ariaLabel="revolving-dot-loading"
                wrapperStyle={{
                  marginLeft: 10,
                }}
                wrapperClass=""
              />
            </Button>
          </Grid>
        </Grid>
        <ToastContainer containerId="messageContainer" />
      </Container>
      <ForgotPasswordModal
        open={openForgotPasswordModal}
        setOpen={setOpenForgotPasswordModal}
      />
      <RequestAccountModal
        open={openRequestAccountModal}
        setOpen={setOpenRequestAccountModal}
      />
    </>
  );
};

export default LoginCard;
