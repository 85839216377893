import Toolbar from "@mui/material/Toolbar";
import { Box, Typography, Grid, CardContent, Card } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import { useWindowSize } from "@uidotdev/usehooks";
import FinalCard from "./FinalCard";
import SmallerCard from "./SmallerCard";
import CustomCarousel from "./CustomCarousel";
import PixelCard from "./PixelCard";
import service_1 from "../../public/images/service_1.png";
import service_2 from "../../public/images/service_2.png";
import service_3 from "../../public/images/service_3.png";
import Goals_IMG from "../../public/images/Sustainable-Development-Goals.svg";
 
import { useState } from "react";
import { ASSET_IMAGE, Primary, THEME_TEXT_COLOR } from "../utils/constants";


export default function SDGComponent() {
    const windowSize = useWindowSize();
    const Goals1 = "SGD/1.jpg";
    const Goals2 = "SGD/2.jpg";
    const Goals3 = "SGD/3.jpg";
    const Goals4 = "SGD/6.jpg";
    const Goals5 = "SGD/7.jpg";
    const Goals6 = "SGD/11.jpg";
    const Goals7 = "SGD/13.jpg";
    const Goals8 = "SGD/14.jpg";
    const Goals9 = "SGD/16.jpg";
const goals = [
    { id: 1, color: '#e5243b', text: 'No Poverty',desc: 'End poverty in all its forms everywhere.', img: ASSET_IMAGE+"/"+Goals1},
    { id: 2, color: '#dda63a', text: 'Zero Hunger', desc:"End hunger, achieve food security and improved nutrition and promote sustainable agriculture.", img: ASSET_IMAGE+"/"+Goals2 },
    { id: 3, color: '#4c9f38', text: 'Good Health and Well-being',desc:"Ensure healthy lives and promote well-being for all at all ages.", img: ASSET_IMAGE+"/"+Goals3  },
    { id: 6, color: '#26bde2', text: 'Clean Water and Sanitation',desc:"Ensure availability and sustainable management of water and sanitation for all.", img: ASSET_IMAGE+"/"+Goals4 },
    { id: 7, color: '#fcc30b', text: 'Affordable and Clean Energy',desc:"Ensure access to affordable, reliable, sustainable and modern energy for all.", img: ASSET_IMAGE+"/"+Goals5 },
    { id: 11, color: '#f89d2a', text: 'Sustainable Cities and Communities',desc:"Make cities and human settlements inclusive, safe, resilient and sustainable.", img: ASSET_IMAGE+"/"+Goals6 },
    { id: 13, color: '#3f7e44', text: 'Climate Action', desc:"Take urgent action to combat climate change and its impacts.",img: ASSET_IMAGE+"/"+Goals7},
    { id: 14, color: '#0a97d9', text: 'Life Below Water', desc:"Conserve and sustainably use the oceans, seas and marine resources for sustainable development.",img: ASSET_IMAGE+"/"+Goals8 },
    { id: 16, color: '#00689d', text: 'Peace, Justice and Strong Institutions', desc:"Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.",img: ASSET_IMAGE+"/"+Goals9},
  ];
  const [hoveredGoal, setHoveredGoal] = useState(null);  

  
    return (
        <div id="sdg">
            <Grid container component="main">
 
                <Grid item xs={12} style={{ backgroundColor: Primary }}>
                    <Box
                        style={{
                            position: "relative",
                            borderRadius: "50px",
                            margin: "0 auto",
                            marginTop: "40px",
                            marginBottom: "40px",
                            
                            width:
                            windowSize.width < 992
                              ? "100%"
                              : windowSize.width < 1025
                                ? "90%"
                                : windowSize.width < 1960
                                  ? "65%"
                                  : "50%",
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="div"
                            color={"white"}
                            style={{ fontWeight: "bold", color: "#f27e30", marginBottom: 40,fontFamily:'Gilroy-Bold' }}
                        >
                            <strong>Sustainable Development Goals </strong>
                        </Typography>
                        <Typography
                            variant="body1"
                            component="div"
                            textAlign={"justify"}
                            mb={5}
                            style={{ color: THEME_TEXT_COLOR,fontFamily:'Gilroy-Medium' }}
                        >
                           At Earth Scan Systems (ESS), we are committed to advancing the United Nations Sustainable Development Goals (SDGs) through innovative geospatial and remote sensing technologies. Our mission aligns closely with several key SDGs by providing data-driven solutions that enable sustainable environmental management and informed decision-making.
                        </Typography>
                        {/* <Box style={{ width: windowSize.width < 992 ? "60%" : "70%", margin: '0 auto' }}>
                            <img
                                src={Goals_IMG}
                                width="100%"
                                alt="Sustainable Development Goals"
                            />
                        </Box> */}

                        <Box>
                            <Grid container spacing={0} justifyContent="center">

                                {goals.map((goal) => (
                                    <Grid item   key={goal.id}>
                                        <Box
                                            sx={{
                                                color: '#fff',
                                                height: '200px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                borderRadius: '8px',
                                                p: 2,
                                            }}
                                        >

                                            <Card
                                            
                                                sx={{ 
                                                    backgroundColor: goal.color,
                                                    color: '#fff',
                                                    cursor: 'pointer',
                                                    transition: 'transform 0.3s ease-in-out',
                                                    borderRadius: '12px',
                                                    '&:hover': {
                                                        transform: 'scale(1.05)',
                                                        transition: 'transform 0.3s ease-in-out',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setHoveredGoal(prevHoveredGoal => prevHoveredGoal === goal.id ? null : goal.id);
                                                }}
                                            >
                                                <CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <img
                                                        src={goal.img}
                                                        alt={goal.text}
                                                        style={{
                                                            width: '150px',
                                                            height: '150px',
                                                            marginBottom: '10px',
                                                        }}
                                                    />

                                                    {hoveredGoal === goal.id && (


                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column', // Align image and text horizontally
                                                                alignItems: 'center',
                                                                gap: 2,
                                                                color: '#fff',
                                                                p: 2,
                                                                borderRadius: '8px',
                                                            }}
                                                        >
                                                            <Typography variant="h6"><strong>Goal {goal.id}</strong></Typography>
                                                            <Typography variant="body1">{goal.desc}</Typography>
                                                        </Box>
                                                    )}
                                                </CardContent>
                                            </Card>

                                           
                                        </Box>
                                    </Grid>
                                ))}

                            </Grid>
                        </Box>


                    </Box>
                </Grid>


            </Grid>
        </div>
    );
}
  