"use client"
 
import * as React from 'react';
import { AnimatePresence, motion, useInView } from 'framer-motion';
 
export function TypingEffect({ text = 'Typing Effect' }) {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false });
  return (
    <span style={{ color: "rgb(242 126 48)" ,fontFamily:'Gilroy-Medium'}}>
      {text.split('').map((letter, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: index * 0.00 }}
        >
          {letter}
        </motion.span>
      ))}
    </span>
  );
}

 

export function GradualSpacing({ text = 'Gradual Spacing' }) {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true });
    return (
        <strong ref={ref} >
            {/* <AnimatePresence> */}
            {isInView &&
                text.split("").map((letter, index) => (
                    <motion.span
                        key={index}
                        initial={{ opacity: 0, x: -5 }} // Less distance for quicker movement
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.00 }} // Faster animation
                    >
                        {letter}
                    </motion.span>
                ))}
            {/* </AnimatePresence> */}
        </strong>
    );
}
