import * as React from 'react';
import { Box, Button, Chip, Grid, Paper, Typography } from '@mui/material';
import T1 from "../../public/images/T1.png";
import STDATA from "../../public/images/STDATA.png";
import Trend from "../../public/images/Trend.png";
import RISK from "../../public/images/RISK.png";
import TA from "../../public/images/TA.webp";
import Sattelites from "../../public/images/sattelites.png";
import fms from "../../public/images/fms.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Primary, Secondary, THEME_TEXT_COLOR } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import axios from 'axios';
import Stepper from '../utils/Stepper';
import "./stepper.css";

function AppStepper() {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [data, setData] = React.useState([]);

    const [activeStep, setActiveStep] = React.useState(0);

    // Determine the status of each step based on the active step.
    const getStatus = (index) => {
        if (index < activeStep) return 'completed';
        if (index === activeStep) return 'active';
        return 'disabled';
    };

    const handleStepClick = (index) => {
        setActiveStep(index);
    };

    //   const steps = [
    //     { number: "1", title: "Add Your Fields" },
    //     { number: "2", title: "Access Satellite Data" },
    //     { number: "3", title: "Analyze Trends" },
    //     {
    //       number: "4",
    //       title: "Check Current Risks",
    //       description:
    //         "Use all our tools to detect any risks to your plants during the growing season. Stay ahead of problems before they happen.",
    //       active: true,
    //     },
    //     { number: "5", title: "Take Action" },
    //     { number: "6", title: "Share Data" },
    //   ];
    const steps = [
        {
            title: "Add Your Fields",
            description:
                "Upload a file with land plot boundaries or draw them. Add info about current and previous seasons.",
            imgSrc: T1,
            alt: "draw or add field in account",
        },
        {
            title: "Access Satellite Data",
            description:
                "Get the latest satellite images right when they capture your field. Use NDVI and other vegetation indices to see how your crops are doing and fix issues fast.",
            imgSrc: STDATA,
            alt: "vegetation monitoring using agriculture platform",
        },
        {
            title: "Analyze Trends",
            description:
                "Dive into historical data on vegetation and weather. Spot patterns and use them to make smarter decisions next time.",
            imgSrc: Trend, alt: "historical data graphs based on agro analytics",
        },
        {
            title: "Check Current Risks",
            description:
                "Use all our tools to detect any risks to your plants during the growing season. Stay ahead of problems before they happen.",
            imgSrc: RISK, alt: "risks detected via satellite crop monitoring",
        },
        {
            title: "Take Action",
            description:
                "Send scouts to the land plot or plan your field activities based on your monitoring and analysis. Track the outcomes and see the results of your decisions.",
            imgSrc: TA, alt: "field planning with a crop monitoring platform",
        },
        // {
        //   title: "Share Data",
        //   description:
        //     "Prepare a custom report for the current field state and share it with stakeholders.",
        //     imgSrc: T1,      alt: "custom reports on crop health analysis",
        // },
    ];
    const isMobile = windowSize.width < 768;

    return (
        <>

            <Grid item xs={12} style={{ backgroundColor: Primary, }}>
                <Box
                    pt={5}
                    pb={5}
                    pr={2}
                    pl={2}
                    style={{
                        margin: "0 auto",
                        marginTop: "40px",
                        marginBottom: "10px",
                        width:
                            windowSize.width < 992
                                ? "100%"
                                : windowSize.width < 1025
                                    ? "90%"
                                    : windowSize.width < 1960
                                        ? "70%"
                                        : "55%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "start",
                    }}
                >

                    <Typography
                        variant="h5"
                        component="div"
                        color={"white"}
                        style={{ fontWeight: "bold", color: "#f27e30", fontFamily: 'Gilroy-Bold' }}
                    >
                        <strong>Streamline Farming in Easy Steps</strong>
                    </Typography>
                </Box>
            </Grid>
            <Box
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "20px",
                    margin: "0 auto",

                    width:
                        windowSize.width < 992
                            ? "100%"
                            : windowSize.width < 1025
                                ? "90%"
                                : windowSize.width < 1960
                                    ? "68%"
                                    : "55%",
                }}
            >


                {/* <div className="hidden md:flex w-full  flex-col items-center gap-[10px]">
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://play.google.com/store/apps/details?id=com.mynayatel&hl=en"
    >
      <img
        alt="qr code "
        loading="lazy"
        width={130}
        height={130}
        decoding="async"
        data-nimg={1}
        className="mt-[-77px] w-[130px] h-[130px] rounded-[8px] "
        style={{
          color: "transparent",
          boxShadow: "0px 0px 13.703px 5.71px rgba(90, 125, 226, 0.11)"
        }}
        sizes="100vw"
        srcSet="/_next/image?url=%2FImages%2Fmy_nayatel_qr2.webp&w=640&q=75 640w, /_next/image?url=%2FImages%2Fmy_nayatel_qr2.webp&w=750&q=75 750w, /_next/image?url=%2FImages%2Fmy_nayatel_qr2.webp&w=828&q=75 828w, /_next/image?url=%2FImages%2Fmy_nayatel_qr2.webp&w=1080&q=75 1080w, /_next/image?url=%2FImages%2Fmy_nayatel_qr2.webp&w=1200&q=75 1200w, /_next/image?url=%2FImages%2Fmy_nayatel_qr2.webp&w=1920&q=75 1920w, /_next/image?url=%2FImages%2Fmy_nayatel_qr2.webp&w=2048&q=75 2048w, /_next/image?url=%2FImages%2Fmy_nayatel_qr2.webp&w=3840&q=75 3840w"
        src="/_next/image?url=%2FImages%2Fmy_nayatel_qr2.webp&w=3840&q=75"
      />
    </a>
    <p className="text-h8 text-dark-grey font-medium text-center">
      (Scan to download My Nayatel App)
    </p>
  </div> */}


                <div className="collapse-tabs row">
                    {/* Tabs Section */}
                    <div className="col-lg-6">
                        {steps.map((step, index) =>
                            isMobile && step.title === "Take Action" ? null : (
                                <div
                                    key={index}
                                    className={`tab ${activeStep === index ? "active" : ""}`}
                                    onClick={() => setActiveStep(index)}
                                >
                                    <div className="bg-block p-4 p-lg-6 sm ">
                                        <h6><strong> {step.title} </strong></h6>
                                        <p className="description">{step.description}</p>
                                    </div>
                                </div>
                            )
                        )}


                    </div>

                    {/* Image Section */}
                    <div className="col-lg-6 mt-4 mt-lg-0">
                        {steps.map((step, index) => (
                            isMobile && step.title === "Take Action" ? null : (
                                <img
                                  
                                    key={index}
                                    src={step.imgSrc}
                                    alt={step.alt}
                                    width={630}
                                    height={650}
                                    style={{
                                        height: "650px",
                                        display: activeStep === index ? "block" : "none",
                                        objectFit: step.title === "Analyze Trends" || step.title === "Access Satellite Data" ? "contain" : "cover",
                                        borderRadius: '16px',
                                        border: '1px solid #333'
                                    }}
                                    className={`sm-image fluid tab-image lazy loaded ${activeStep === index ? "active" : ""}`}
                                />
                            )
                        ))}
                    </div>

                </div>
                {/* <div className="w-full flex flex-col items-center relative bg-center bg-cover bg-no-repeat rounded-[45px] gap-[4px] mt-[20px]">

                    <div className="hidden md:flex w-full mt-[10px] gap-[100px] flex-1 relative px-[60px]">
                        <div className="flex-[1.5] flex flex-col md:gap-[35px] h-full justify-center  top-[8rem] mb-[40px]">
                            <div className="flex flex-col  mynayatel-parent-container">
                                <div className="flex flex-1 relative cursor-pointer image-in-view-mynayatel   ">
                                    <div className="flex flex-1 gap-[17px]  ml-[35px] justify-start py-[20px] px-[16px] mb-[5px] rounded-[12px]" style={{ backgroundColor: Secondary }}>

                                        <div className="flex flex-col flex-1">
                                            <p className=" text-h5 font-medium text-black">
                                                Add Your Fields
                                            </p>
                                            <p className="text-dark-grey text-h7 font-medium opacity-to-hide-mynayatel">
                                                Add Your Fields
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-1 relative cursor-pointer image-in-view-mynayatel   ">
                                    <div className="flex flex-1 gap-[17px]  ml-[35px] justify-start py-[20px] px-[16px] mb-[5px] rounded-[12px] " style={{ backgroundColor: Secondary }}>

                                        <div className="flex flex-col flex-1">
                                            <p className=" text-h5 font-medium text-black">Access Satellite Data</p>
                                            <p className="text-dark-grey text-h7 font-medium opacity-to-hide-mynayatel">
                                                Access Satellite Data
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-1 relative cursor-pointer image-in-view-mynayatel   ">
                                    <div className="flex flex-1 gap-[17px]  ml-[35px] justify-start py-[20px] px-[16px] mb-[5px] rounded-[12px]  " style={{ backgroundColor: Secondary }}>

                                        <div className="flex flex-col flex-1">
                                            <p className=" text-h5 font-medium text-white">Analyze Trends</p>
                                            <p className="text-white text-h7 font-medium opacity-to-show-mynayatel">
                                                Analyze Trends 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-1 relative cursor-pointer image-not-in-view-mynayatel   ">
                                    <div className="flex flex-1 gap-[17px]  ml-[35px] justify-start py-[20px] px-[16px] mb-[5px] rounded-[12px] " style={{ backgroundColor: Secondary }}>

                                        <div className="flex flex-col flex-1">
                                            <p className=" text-h5 font-medium text-black">
                                                Check Current Risks
                                            </p>
                                            <p className="text-dark-grey text-h7 font-medium opacity-to-hide-mynayatel">
                                                Check Current Risks
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div> */}


            </Box>


        </>

    );
}

export default AppStepper;