import InvestorsView from ".";
import CustomFooter from "../home/CustomFooter";
import CustomHeader from "../home/NewHeader";
import BreadCrumbs from "../utils/BreadCrumbs";
import './style.css';
import sattelites from "../../public/images/sattelites.png";
//import CEO from "../../public/images/CEO.jpg";
import CEO from "../../public/images/CEO-IMG.jpg";
 

import { Button } from "@mui/material";
import { useRouter } from "../routes/hooks";
import { Box, Typography, Grid, Tooltip } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import { Primary, Secondary, THEME_HEADING_COLOR, THEME_TEXT_COLOR } from "../utils/constants";
import { GradualSpacing, TypingEffect } from "../utils/Rotate";
export default function AboutPage() {
  const router = useRouter();
  const windowSize = useWindowSize();

  return (
    <>


      <CustomHeader />


      <Grid container style={{ backgroundColor: Primary, }}>

        
      <Grid item xs={12} style={{ backgroundColor: Secondary }} >
        <Box
          style={{
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "100%"
                : windowSize.width < 1025
                  ? "95%"
                  : windowSize.width < 1960
                    ? "70%"
                    : "60%",
            display: "flex",
            flexDirection: windowSize.width < 992 ? "column-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box style={{ width: windowSize.width < 992 ? "95%" : "50%" }}>
            <Typography
              variant="h5"
              component="div"
              color={THEME_TEXT_COLOR}
              mb={3}
              mt={15}
              style={{ fontWeight: "bold" }}
            >
              <strong style={{ color: THEME_TEXT_COLOR, fontFamily: 'Gilroy-Bold' }}>
                {"Our Mission: "}
                <TypingEffect text="To deliver real-time environmental insights through Earth Observation and AI-powered analytics." />
                <GradualSpacing text=" We empower our clients with data-driven tools for smarter decision-making, enabling sustainability and global positive change through innovative solutions." />

              </strong>
            </Typography>

          </Box>
          <Box style={{ width: windowSize.width < 992 ? "100%" : "50%", position: "relative" }}>

            <img
              src={`${sattelites}`}
              style={{
                maxWidth: "100%",
                height: "auto",
                transition: "transform 0.5s ease",
                transformOrigin: "center", cursor: 'pointer'
              }}
              usemap="#image-map"
              alt="earth scan system"
            />


          </Box>
        </Box>
      </Grid>


      <Grid item xs={12} style={{ backgroundColor: Primary }}>
        <Box
          pt={5}
          pb={5}
          pr={2}
          pl={2}
          style={{
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "2px",
            width:
              windowSize.width < 992
                ? "100%"
                : windowSize.width < 1025
                  ? "90%"
                  : windowSize.width < 1960
                    ? "70%"
                    : "55%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Typography
            variant="h5"
            component="div"
            color={"white"}
            mb={1}
            style={{ fontWeight: "bold", color: "#f27e30", fontFamily: 'Gilroy-Bold' }}
          >
            <strong>What we do</strong>
          </Typography>
          <Typography
            variant="body1"
            component="div"
            textAlign={"justify"}
            mb={5}
            style={{ color: THEME_TEXT_COLOR, fontFamily: 'Gilroy-Medium' }}
          >

            At Earth Scan Systems (ESS), we harness remote sensing, IoT, and AI-driven analytics to drive sustainable development and resource efficiency.
            Our advanced technology provides real-time environmental intelligence, helping businesses, governments, and organizations make data-driven decisions for a more sustainable future.
            By integrating high-resolution satellite data, sensor networks, and predictive analytics, we deliver actionable insights on climate change, land use, and resource management.
            ESS empowers industries to monitor, optimize, and reduce their environmental impact, ensuring a smarter, greener planet.
            With ESS, you’re not just leveraging cutting-edge technology—you’re investing in a sustainable future.


          </Typography>

          <Typography
            variant="h5"
            component="div"
            color={"white"}
            mb={1}
            style={{ fontWeight: "bold", color: "#f27e30", fontFamily: 'Gilroy-Bold' }}
          >
            <strong>Vision</strong>
          </Typography>
          <Typography
            variant="body1"
            component="div"
            textAlign={"justify"}
            mb={5}
            style={{ color: THEME_TEXT_COLOR, fontFamily: 'Gilroy-Medium' }}
          >

            A world where technology enables sustainability, fostering resilient communities, businesses, and governments. Through innovation, collaboration, and ethical practices, we strive to create a lasting positive impact on our planet for future generations.
          </Typography>






        </Box>
      </Grid>

      <Grid item xs={12} style={{ backgroundColor: Primary, }}>

        <Box
          style={{
            margin: "0 auto",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "100%"
                : windowSize.width < 1025
                  ? "90%"
                  : windowSize.width < 1960
                    ? "68%"
                    : "55%",
            display: "flex",
            flexDirection: windowSize.width < 992 ? "column" : "row",

          }}
        >
          <Box id="meet" style={{ width: windowSize.width < 992 ? "90%" : "90%", marginRight: 30 }}>
            
          <Typography
            variant="h5"
            component="div"
            color={"white"}
            mb={3}
            style={{ fontWeight: "bold", fontFamily: 'Gilroy-Bold' }}
          >
            <strong>Meet the founder</strong>
          </Typography>

            <Typography
              variant="h5"
              component="div"
              color={THEME_TEXT_COLOR}
              mb={3}

              style={{ fontWeight: "bold" }}
            >
              <strong style={{ color: THEME_TEXT_COLOR, fontFamily: 'Gilroy-Bold' }}>
                {"Dr Mobushir Riaz Khan"}

              </strong>
            </Typography>

            <Typography
              variant="p"
              component="div"
              color={THEME_TEXT_COLOR}
              mb={3}

              style={{ fontWeight: "bold" }}
            >
              <strong style={{ color: THEME_TEXT_COLOR, fontFamily: 'Gilroy-Bold' }}>
                {"Chief Executive Officer & Founder"}

              </strong>
            </Typography>


            <Typography
              variant="p"
              component="div"
              color={THEME_TEXT_COLOR}
              mb={3}
            >

              Dr. Mobushir Riaz Khan is the founder of Earth Scan Systems (ESS) and a leading expert in geospatial science, digital agriculture, and natural resource analytics. With a PhD in Remote Sensing and GIS from the University of Twente (Netherlands), he brings over a decade of international experience across Australia, South Asia, China, North Africa, and Europe, working with organizations such as ACIAR, FAO, and the Asian Development Bank. <br /><br />
              Dr. Khan combines domain expertise with strong programming and data science capabilities to build practical solutions for land, water, and environmental management. Through ESS, he has led the development of advanced AI-powered tools such as the Crop Monitoring System, Land & Water Assessment Platform, and GreenBot, using satellite imagery, machine learning, and IoT data to guide field-specific decisions. His work in areas like evapotranspiration modelling, groundwater analytics, and carbon credit monitoring exemplifies the fusion of environmental science and computational intelligence.<br />
              Driven by innovation and impact, Dr. Khan continues to position ESS at the forefront of precision agriculture and sustainability technology.



            </Typography>


          </Box>
          {/* */}

          <Box style={{ width: windowSize.width < 992 ? "100%" : "50%", position: "relative", marginLeft: 30 }}>

            <img
              src={`${CEO}`}
              style={{

                transition: "transform 0.5s ease",
                transformOrigin: "center"
              }}
              className=" h-[max(50vh,500px)] rounded-[15px] "

              alt="earth scan system"
            />


          </Box>

        </Box>





      </Grid>

      </Grid>

      <CustomFooter />


    </>
  );
}
