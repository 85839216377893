import Toolbar from "@mui/material/Toolbar";
import { Box, Typography, Grid } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import { useWindowSize } from "@uidotdev/usehooks";
import { Height } from "@mui/icons-material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PersonIcon from '@mui/icons-material/Person';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import React from "react";
import { Primary, THEME_TEXT_COLOR } from "../utils/constants";

export default function InvestorsView() {

  const [openForm, setOpenFrom] = React.useState(false);
  const [openLoginForm, setOpenLoginFrom] = React.useState(false);
  const [userToken, setUserToken] = React.useState("");
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    org: ""
  });

  const [formDataLogin, setFormDataLogin] = React.useState({
    email: "",
    password: "",

  });

  const [disableButton, setDisableButton] = React.useState(false);

  const handleClose = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
    });
    setOpenFrom(false);
  };

  const handleLoginClose = () => {

    setFormDataLogin({
      email: "",
      password: "",
    });
    setOpenLoginFrom(false);

  }

  const showToast = (message, type = "success") => {
    toast[type](message, {
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleSubmitButton = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    const { name, email, phoneNumber, org } = formData;

    if (!name.trim() || !email.trim() || !phoneNumber.trim()) {
      showToast("Please fill all fields", "error");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showToast("Invalid email format", "error");
      return;
    }

    try {
      const response = await axios.post("https://escan-systems.com/api/requestAccess", {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        org: org
      });
      if (response.status === 200) {
        showToast("Your request for account is sent successfully", "success");
        handleClose();
      } else {
        showToast("Error occurred. Please try again later", "error");
      }
    } catch (error) {
      showToast("Error occurred. Please try again later", "error");
    }
    setDisableButton(false);
  };


  const galleryVideos = [

    { url: "/videos/concept1.mp4", title: "Concept 1" },
    { url: "/videos/concept2.mp4", title: "Concept 2" },
    { url: "/videos/concept3.mp4", title: "Concept 3" },
    { url: "/videos/demo1.mp4", title: "Demo 1" },
    { url: "/videos/demo2.mp4", title: "Demo 2" },
    // { url: "/videos/app_demo1.mp4", title: "App Demo 1" },
  ];


  const handleSubmitButtonLogin = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    const { email, password } = formDataLogin;


    try {
      setDisableButton(false);
      const endPoint = "https://escan-systems.com/api/signin";
      const data = { email, password };
      try {
        const response = await axios.post(endPoint, data);
        if (response.data.login) {
          setUserToken(response.data.token)
          console.log(response.data.token)
          setOpenLoginFrom(false)
        } else {
          setUserToken("")
          toast.error("Invalid credentials", {
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
          });
        }

        //await loginAuth({ token: response.data.token });
        setDisableButton(false);
      } catch (error) {
        setDisableButton(false);
        toast.error("Invalid credentials", {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      showToast("Error occurred. Please try again later", "error");
    }
    setDisableButton(false);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleInputChangeLogin = (e) => {
    const { name, value } = e.target;
    // console.log(name,value)
    setFormDataLogin({
      ...formDataLogin,
      [name]: value,
    });
  };



  const windowSize = useWindowSize();
  return (
    <>

      <Dialog
        open={openForm}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          sx: {
            width: "100%",
            backgroundColor: "#212930",
            padding: 3,
            borderRadius: "16px",
          },
          onSubmit: handleSubmitButton,
        }}
      >
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            color={THEME_TEXT_COLOR}
          >
            <Typography variant="h5" component={"div"}>
              Request Account
            </Typography>
          </Box>


        </DialogTitle>
        <DialogContent sx={{ marginTop: "5px" }}>
          <DialogContentText
            display={"flex"}
            justifyContent={"center"}
            mb={2}
            color={{THEME_TEXT_COLOR}}
          >
            Enter following details to request account for Investors Showcase.
          </DialogContentText>
          <TextField
            placeholder="John Doe"
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: {THEME_TEXT_COLOR},
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            placeholder="john@domain.com"
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: {THEME_TEXT_COLOR},
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            placeholder="03XXXXXXXXX"
            margin="dense"
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: {THEME_TEXT_COLOR},
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />

          <TextField
            placeholder=""
            margin="dense"
            id="org"
            name="org"
            label="Organization"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: {THEME_TEXT_COLOR},
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.org}
            onChange={handleInputChange}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="submit" style={{ backgroundColor: "rgb(135, 120, 73)", color: 'white', fontWeight: 'bold' }} disabled={disableButton}>
            Request Account
          </Button>

          <Button variant="contained" onClick={() => {
            setOpenFrom(false)
            setOpenLoginFrom(true)
          }} style={{ backgroundColor: "green", color: 'white', fontWeight: 'bold' }}>
            Already Have Access?
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openLoginForm}
        onClose={handleLoginClose}
        PaperProps={{
          component: "form",
          sx: {
            width: "100%",
            backgroundColor: "#212930",
            padding: 3,
            borderRadius: "16px",
          },
          onSubmit: handleSubmitButtonLogin,
        }}
      >
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            color={{THEME_TEXT_COLOR}}
          >
            <Typography variant="h5" component={"div"}>
              Investors Showcase Account
            </Typography>
          </Box>


        </DialogTitle>
        <DialogContent sx={{ marginTop: "5px" }}>
          <DialogContentText
            display={"flex"}
            justifyContent={"center"}
            mb={2}
            color={{THEME_TEXT_COLOR}}
          >
            Enter following details to access for Investors Showcase.
          </DialogContentText>

          <TextField
            placeholder="john@domain.com"
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: {THEME_TEXT_COLOR},
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formDataLogin.email}
            onChange={handleInputChangeLogin}
          />



          <TextField
            placeholder=""
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: {THEME_TEXT_COLOR},
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formDataLogin.password}
            onChange={handleInputChangeLogin}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoginClose}>Cancel</Button>
          <Button variant="contained" type="submit" style={{ backgroundColor: "rgb(135, 120, 73)", color: 'white', fontWeight: 'bold' }} disabled={disableButton}>
            Proceed
          </Button>


        </DialogActions>
      </Dialog>


      <ToastContainer />




      {
        userToken.length > 0 ? <>

          <div id="">
            <Grid
              container
              component="main"
              style={{ backgroundColor: Primary, height: '100vh' }}
            >
              <Grid item xs={12} style={{ backgroundColor: Primary }}>
                <Box
                  pt={5}
                  pb={5}
                  pr={2}
                  pl={2}
                  style={{
                    margin: "0 auto",
                    marginTop: "40px",
                    marginBottom: "10px",
                    width:
                      windowSize.width < 992
                        ? "95%"
                        : windowSize.width < 1025
                          ? "85%"
                          : windowSize.width < 1960
                            ? "65%"
                            : "50%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                  }}
                >
                  {/* <Typography
                    variant="h5"
                    component="div"
                    textAlign="center"
                    mb={5}
                    style={{ color: "rgb(193, 207, 219)" }}
                  >
                    Investors Gallery
                  </Typography> */}

                  <Grid container spacing={2}>
                    {galleryVideos.map((video, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              width: "100%",      // Full width of the container
                              maxWidth: "400px",   // Optional: limits the max width of the card
                              margin: "auto",      // Centers the card
                              padding: "10px",

                              borderRadius: "15px",        // Rounded corners for the card
                            }}
                          >
                            <video
                              width="100%"        // Video will take 100% of the card width
                              controls
                              style={{
                                borderRadius: "10px",  // Rounded corners for the video
                                display: "block",      // Ensures the video stays inside the card
                              }}
                            >
                              <source src={"https://escan-systems.com" + video.url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>

                            <Typography
                              variant="subtitle1"
                              color={THEME_TEXT_COLOR}
                              textAlign="center"
                              mt={2}
                            >
                              {video.title}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>


        </> : <>

          <div id="">
            <Grid container component="main" style={{ backgroundColor: Primary, height: '100vh' }}>

              <Grid item xs={12} style={{ backgroundColor: Primary }}>
                <Box
                  pt={5}
                  pb={5}
                  pr={2}
                  pl={2}
                  style={{
                    margin: "0 auto",
                    marginTop: "40px",
                    marginBottom: "10px",
                    width:
                      windowSize.width < 992
                        ? "95%"
                        : windowSize.width < 1025
                          ? "85%"
                          : windowSize.width < 1960
                            ? "65%"
                            : "50%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                  }}
                >

                  <Typography
                    variant="body1"
                    component="div"
                    textAlign={"justify"}
                    mb={5}
                    style={{ color: THEME_TEXT_COLOR, lineHeight: '30px' , fontFamily:'Gilroy-Medium'}}
                  >

                    Thank you for your interest in partnering with Earth Scan Systems (ESS). We deeply appreciate your consideration of our innovative approach for sustainable environmental solutions. Your potential involvement will play a pivotal role in driving our mission forward—empowering industries, governments, and communities to make informed decisions that foster environmental sustainability and economic growth.
                    As we embark on this journey together, we value your trust in our vision. To ensure the confidentiality of the information shared during this process, we kindly ask that you review and agree to the terms of our Non-Disclosure Agreement (NDA). This agreement outlines the following key terms:
                    <br /> <br />
                    •	You agree to keep all confidential information shared by ESS strictly confidential and use it solely for evaluating a potential involvement.
                    <br />•	You will not disclose any confidential information to third parties without our written consent.
                    <br />•	You acknowledge that the confidentiality obligations will remain in effect for three (3) years from the date of disclosure otherwise mentioned differently on some products under development.
                    <br />•	The NDA does not obligate either party to enter into a business transaction, and it is governed by the laws of [Insert Jurisdiction].
                    <br /><br />By clicking the link below, you confirm your agreement to these terms, protecting both your interests and the proprietary information of Earth Scan Systems.
                    <br /><br /> <Button variant="contained" type="submit" style={{ backgroundColor: "rgb(135 120 73 / 0%)", color: 'rgb(242, 126, 48)', fontWeight: 'bold' ,fontFamily:'Gilroy-Medium' }} onClick={() => { setOpenFrom(!openForm) }}>
                      [Click here to agree to the Non-Disclosure Agreement]
                    </Button>
                    <br /><br />We look forward to building a meaningful partnership that drives both sustainable progress and shared success.
                    <br /><br /> Warm regards,
                    <br /><br />The Earth Scan Systems Team


                  </Typography>

                </Box>
              </Grid>



            </Grid>
          </div></>

      }


    </>

  );
}
