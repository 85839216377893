import { Box } from "@mui/material";
import CustomCarousel from "./CustomCarousel";
import CustomCard from "./CustomCard";
import { useWindowSize } from "@uidotdev/usehooks";

import PRECISION_AGRICULTURE1 from "../../public/images/weather_image.jpg";
import PRECISION_AGRICULTURE2 from "../../public/images/2.jpg";
import PRECISION_AGRICULTURE3 from "../../public/images/3.jpg";
import { Primary } from "../utils/constants";
export default function Registration() {
  const size = useWindowSize();
  const slides = [
    {
      title: "Manage your fields remotely",
      content:
        "Monitor the state of your crops right from the office, learn about the slightest changes on-the-spot, and make fast and reliable decisions on field treatment",
      image:PRECISION_AGRICULTURE2,
      },
    {
      title: "Save on costs by up to 30% with VRA approach",
      content:
        "This tool can identify up to 7 zones with different productivity rates and create differential fertilizing, sowing, and irrigation maps, thus saving you a lot of time and resources.",
        image:PRECISION_AGRICULTURE3,
      },
    {
      title: "Full-fledged weather analytics at your fingertips",
      content:
        "Correlate weather with vegetation indices which are the tell-tale signs of how weather conditions influence the health of your crops.",
        image:PRECISION_AGRICULTURE1,
    },
  ];
  return (
    <Box style={{ display: "flex" }}>
      <Box
        style={{
          display : size.width >  1024 ? "block"  : "none",
          width: "50%",
          
        }}
      >
      <CustomCarousel slides={slides} />
      </Box>
      
      
      <Box style={{ width: size.width >  1024 ? "50%"  : "100%", background:Primary ,height:'100vh',display:'flex',alignItems:'center'}}>   
        <CustomCard />
      </Box>
    </Box>
  );
}
