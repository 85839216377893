import * as React from 'react';
import { useWindowSize } from "@uidotdev/usehooks";
import {
    Box, Grid,
    Typography,
    Card,
    CardContent,
    Button,
    Switch,
    Stack,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery,  Dialog,
    DialogContent,
    TextField
} from "@mui/material";
import ESS_LOGO from "../../public/images/ESS-06.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import RecommendIcon from '@mui/icons-material/Recommend';
import axios from "axios";
import { Primary, Secondary, THEME_TEXT_COLOR } from '../utils/constants';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export default function Plans() {
  const location = useLocation();
  const navigate = useNavigate();
 // console.log("Current Route:", location.pathname);
    const StyledCard = styled(Card)(({ theme, isPro }) => ({
        borderRadius: 20,
        height: "100%",
       backgroundColor: isPro ? Primary : Primary,
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
         //   transform: "translateY(-10px)",
              backgroundColor: Secondary,
            boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)"
        }
    }));

   


 
    const [isYearly, setIsYearly] = React.useState(false);
    const [advancePrice, setAdvancePrice] = React.useState(0.20 + "/Ha");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const windowSize = useWindowSize();
    const [isAdvanceClicked, setIsAdvanceClicked] = React.useState(false);
    const [HaValue, setHAValue] = React.useState();
    const [HaError, setHaError] = React.useState("");
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [userStatus, setUserStatus] = React.useState(1);
    const { status, setStatus } = useAuth();

    const getUserDetails = async () => {
      let token = localStorage.getItem("token");
      token = token.replace(/^"(.*)"$/, "$1");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://escan-systems.com/api/userDetails",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
  
      await axios
        .request(config)
        .then((response) => {
          setUserStatus(response.data.status);
          setStatus(response.data.status)
          localStorage.setItem("status",response.data.status)
          // console.log(response.data.status);
          // setAccountInformation(response.data)
          // console.log(response,accountInformation)
        })
        .catch((error) => {
  
        });
    }



    React.useEffect(() => {
      getUserDetails()
      if (location.pathname == "/plan-subscribe/expire") {
    
        if(userStatus == 0 || userStatus == "0"){
          
          navigate("/monitor");
        }
      }
    }, [location.pathname]); 
    // const _clickToProceed = async ()=>{
    
                                                    
    //     if(!isAdvanceClicked && plan.title=="ESS Basic"){
    //         checkOutWallet(plan.price,plan.title,1)
    //     }else{
            
    //         if(HaError == "Hectare cannot be less than 5"){

    //         }else{
                
    //             if(isAdvanceClicked){
    //                 setShowConfirmation(true)
    //             }

    //             setIsAdvanceClicked(true)
                  
    //         }
    //     }
        
    // }
    // }
    const checkOutWallet = async (price,plan,user) => {
        const planSplit = plan.split(" ")[1]; 
        if(plan == "ESS Advance"){
            //setAdvancePrice(100)
            setIsAdvanceClicked(!isAdvanceClicked)
            price = advancePrice;
        }
       
        const endPoint = `https://escan-systems.com/api/pay/${user}/${planSplit}/${price}`;
        const data = { email:JSON.parse(localStorage.getItem("email")) };
        try {
          const response = await axios.post(endPoint, data);
          //console.log(response);
          const newWindow = window.open(response.data.url, '_blank', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null

        } catch (error) {
         
        }

    }

    const plans = [

        {
            title: "ESS Basic",
            display:"ESS Bulk",
            price: isYearly ? 100 : 100,
            features: [
                "Maximum Area 1000 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "Crop monitoring learning module"
            ],
            icon: <StarIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />
        },
        {
            title: "ESS Advance",
            display:"ESS Premium",
            price: isYearly ? advancePrice :advancePrice,
            features: [
                "Minimum Area 5 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "AI Analytics for VRA",
                "OpenAI Driven Decision Support",
            ],
            icon: <svg  style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }}  xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
            <g clip-path="url(#clip0_419_224)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1101 0.461304C6.3275 0.461304 0 6.78881 0 14.5714C0 22.354 6.3275 28.6815 14.1101 28.6815C21.8927 28.6815 28.2202 22.354 28.2202 14.5714C28.2202 6.78881 21.8927 0.461304 14.1101 0.461304Z" fill="#FEC006"/>
            <path d="M6.77377 16.5171V11.4298C6.36039 11.2259 6.0848 10.8015 6.0848 10.3109C6.0848 9.62745 6.63047 9.07076 7.31944 9.07076C8.00841 9.07076 8.56509 9.62745 8.56509 10.3164C8.56509 10.4983 8.52651 10.6802 8.44935 10.8455L11.3596 13.9928L13.1564 10.3991C12.969 10.1786 12.8643 9.89752 12.8643 9.59989C12.8643 8.91643 13.421 8.35974 14.1099 8.35974C14.7989 8.35974 15.3556 8.91643 15.3556 9.59989C15.3556 9.89752 15.2453 10.1786 15.0635 10.3991L16.8603 13.9928L19.776 10.8455C19.6989 10.6802 19.6603 10.4983 19.6603 10.3164C19.6603 9.62745 20.217 9.07076 20.9059 9.07076C21.5949 9.07076 22.1516 9.62745 22.1516 10.3164C22.1516 10.8015 21.8705 11.2259 21.4626 11.4298V16.5227H6.77377V16.5171ZM21.8595 17.5478H6.36039C6.07378 17.5478 5.84229 17.7793 5.84229 18.066V20.2762C5.84229 20.5628 6.07378 20.7943 6.36039 20.7943H21.865C22.1516 20.7943 22.3831 20.5628 22.3831 20.2762V18.066C22.3776 17.7793 22.1461 17.5478 21.8595 17.5478Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_419_224">
            <rect width="28.2202" height="28.2202" fill="white" transform="translate(0 0.461304)"/>
            </clipPath>
            </defs>
            </svg>,
            isPro: true
        }
    ];

    return ( 
        <Grid container component="main" xs={12} style={{ backgroundColor: Primary }}> 
            <Dialog
              open={showConfirmation}
              onClose={() =>{ 
                setShowConfirmation(false)
              }}
            >
              <DialogContent sx={{ background: Primary, padding: 0 }}>
                <Box
                  id="2"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '40vh', // Full viewport height
                    width: '100%' // Full width of the viewport
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: 'column',

                    }}
                  >
                    <Stack
                      direction={{ xs: "column", md: "row", }}
                      sx={{ mb: 2 }}
                      spacing={4}
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      <Box
                        key={1}
                        sx={{
                          flex: 1,
                          maxWidth: { xs: "100%", md: "750px" },
                          
                        }}
                      >
                        <StyledCard>
                          <CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              p: 4
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mb: 3,
                                backgroundColor: "#30B130",
                                height: "50px",
                                width: "50px",
                                borderRadius: "50%",
                                margin: '10px auto'
                              }}
                            >
                             <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4695 0.232963C15.8241 0.561287 15.8454 1.1149 15.5171 1.46949L6.14206 11.5945C5.97228 11.7778 5.73221 11.8799 5.48237 11.8748C5.23253 11.8698 4.99677 11.7582 4.83452 11.5681L0.459523 6.44311C0.145767 6.07557 0.18937 5.52327 0.556912 5.20951C0.924454 4.89575 1.47676 4.93936 1.79051 5.3069L5.52658 9.68343L14.233 0.280522C14.5613 -0.0740672 15.1149 -0.0953599 15.4695 0.232963Z" fill="white"/>
    </svg>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: 'column',
                                alignItems: "center",
                                gap: 2,
                                mb: 3
                              }}
                            >

                              <Typography
                                variant="h5"
                                sx={{
                                  fontWeight: "bold",
                                  color: "#fff"
                                }}
                              >
                                {`Dear Valued Customer`}

                              </Typography>


                              <Box
                                sx={{
                                  height: '1px',
                                  width: '80%',
                                  margin: '10px',
                                  background: '#fff',
                                  backgroundColor: '#fff'
                                }}
                              />
                              <Typography
                                variant="h5"
                                sx={{
                                  fontWeight: "bold",
                                  color: "#fff",
                                  textAlign: 'center'
                                }}
                              >
                                 {`Please note that you have chosen ${HaValue} Ha and the cost of it is $${advancePrice}`}
                                 <br /> <br />
                                {`Following is the breakdown for your bill:`}
                              </Typography>

                              <Typography
                                variant="h5"
                                sx={{
                                  fontWeight: "bold",
                                  color: "#fff",
                                  textAlign: 'center'
                                }}
                              >
                                {` 0.2 * ${HaValue} Ha = $${advancePrice} `}

                              </Typography>
                              <Box
                                sx={{
                                  borderRadius: '10%',
                                  flexDirection: 'row',
                                  marginTop: 2,
                                  display: 'flex',
                                  justifyContent: 'space-evenly',
                                  alignItems: 'center',
                                  marginLeft: 2,
                                  cursor: 'pointer'
                                }}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "1.2rem",
                                    background: "#877849",
                                    color: "#fff", borderRadius: '10px',
                                    display: "flex",
                                    alignItems: "center", fontWeight: "bold", marginRight: 2,
                                    justifyContent: "space-between", textAlign: 'center',
                                      padding: '8px 18px 8px 18px'
                                  }}
                                 


                                   
                                  onClick={() => {  checkOutWallet(advancePrice,"ESS Advance",1)  }}

                                >


                                  Proceed


                                </Typography>


                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "1.2rem",
                                    color: "#fff", borderRadius: '10px',
                                    display: "flex",
                                    alignItems: "center", fontWeight: "bold", marginLeft: 2,
                                    justifyContent: "space-between", textAlign: 'center',
                                    backgroundColor: "#DF1B41", padding: '8px 18px 8px 18px'
                                  }}
                                  onClick={() =>{ 
                                    setShowConfirmation(false)
                                  }}

                                >

                                  Close


                                </Typography>
                              </Box>

                            </Box>
                          </CardContent>
                        </StyledCard>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </DialogContent>

            </Dialog>
            <Box
                sx={{
                    minHeight: "100vh",
                    width: '100%',
                    py: 8,
                    px: { xs: 2, md: 6 }
                }}
            >


                <img src={ESS_LOGO} width={"200px"} />

                <Box id="2">

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center", flexDirection: 'column',
                            mb: 6
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="h5"
                            sx={{ fontWeight: "bold", color: "#fff", mt: 5, mb: 10, textAlign: 'center' }}
                        >
                            You can subscribe to any of the plans below to continue monitoring your fields
                        </Typography>

                        <Stack
                            direction={{ xs: "column", md: "row", }}
                            sx={{mb:2}}
                            spacing={4}
                            justifyContent="center"
                            alignItems="stretch"
                        >
                            {plans.map((plan) => (
                                <Box
                                    key={plan.title}
                                    sx={{
                                        flex: 1,
                                        maxWidth: { xs: "100%", md: "350px" }, 
                                    }}
                                >
                                    
                                    <StyledCard isPro={plan.isPro}>

                                     
                                        <CardContent
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                height: "100%",
                                                p: 4
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 2,
                                                    mb: 3
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        color: plan.isPro ? "#ffd700" : "#fff",
                                                        fontSize: "2rem"
                                                    }}
                                                >
                                                    {plan.icon}
                                                </Box>
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontWeight: "bold",
                                                        color: THEME_TEXT_COLOR
                                                    }}
                                                >
                                                    {plan.display}
                                                </Typography>
                                            </Box>

                                            <Typography
                                                variant="h4"
                                                sx={{ fontWeight: "bold", color:THEME_TEXT_COLOR, mb: 3 }}
                                            >
                                                ${plan.price}
                                                <Typography
                                                    component="span"
                                                    sx={{ fontSize: "1rem", color: "#bbb" }}
                                                >
                                                    /{isYearly ? "year" : "month"}
                                                </Typography>
                                            </Typography>

                                            <List sx={{ flexGrow: 1, mb: 3 }}>
                                                {plan.features.map((feature) => (
                                                    <ListItem key={feature} sx={{ px: 0 }}>
                                                        <ListItemIcon>
                                                            <CheckIcon style={{ color: "#4caf50" }} />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={feature}
                                                            sx={{ color: "#fff" }}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>

                                            <Typography
                                              id='btnProceed'
                                                component="span"
                                                sx={{
                                                    fontSize: "1rem",
                                                    color: THEME_TEXT_COLOR,
                                                    display: "flex",
                                                    alignItems: "center",fontWeight: "bold", 
                                                    justifyContent: "space-between",
                                                }}

                                                // onClick={()=>{
                                                //   if(!isAdvanceClicked && plan.title=="ESS Basic"){
                                                //     checkOutWallet(plan.price,plan.title,1)
                                                // }else{
                                                    
                                                //     if(HaError == "Hectare cannot be less than 5"){

                                                //     }else{
                                                        
                                                //         if(isAdvanceClicked){
                                                //             setShowConfirmation(true)
                                                //         }

                                                //         setIsAdvanceClicked(true)
                                                          
                                                //     }
                                                // }
                                                // }}
                                               
                                            >
                                                

                                                {
                                                    (isAdvanceClicked && plan.title == "ESS Advance") ? <>

                                                        <TextField
                                                            placeholder="Enter hectare in numbers"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={HaValue}
                                                            onChange={(event) => {
                                                                
                                                                const value = event.target.value.replace(/[^0-9]/g, "");  
                                                                if (value && Number(value) >= 5) {
                                                                  setAdvancePrice((0.2 * value).toFixed(2));
                                                                  setHAValue(value);
                                                                

                                                                  setHaError(""); 
                                                                } else if (value) {
                                                                  setShowConfirmation(false)
                                                                  setHaError("Hectare cannot be less than 5"); 
                                                                  setHAValue(HaValue);  
                                                                }

                                                              }}

                                                            sx={{
                                                                background: "transparent",
                                                                borderRadius: "8px",
                                                                width: "80%", // Adjust width
                                                                "& .MuiOutlinedInput-root": {
                                                                    color: THEME_TEXT_COLOR, // Text color
                                                                    "& fieldset": {
                                                                        borderColor: Secondary, // Border color
                                                                    },
                                                                    "&:hover fieldset": {
                                                                        borderColor:Secondary, // Hover state
                                                                    },
                                                                },
                                                                "& .MuiInputBase-input::placeholder": {
                                                                    color: THEME_TEXT_COLOR, // Placeholder color
                                                                    opacity: 1,
                                                                },
                                                            }}
                                                        />
                                                       

                                                    </> : <><b id="getItNow" 
                                                      onClick={()=>{
                                                  if(!isAdvanceClicked && plan.title=="ESS Basic"){
                                                    setIsAdvanceClicked(false)

                                                    setShowConfirmation(false)
                                                    checkOutWallet(plan.price,plan.title,1)
                                                }else{
                                                    
                                                    if(HaError == "Hectare cannot be less than 5"){

                                                    }else{
                                                        
                                                        if(isAdvanceClicked){
                                                            setShowConfirmation(true)
                                                        }

                                                        setIsAdvanceClicked(true)
                                                          
                                                    }
                                                }
                                                }}
                                                    >{`Get it Now`}</b></>


                                                }
                                             
                                              
                                                <Box
                                                    sx={{
                                                        background: '#fff',
                                                        borderRadius: '50%',
                                                        width: 40,
                                                        height: 40,
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        alignItems: 'center',
                                                        marginLeft: 2,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={()=>{
                                                      if(!isAdvanceClicked && plan.title=="ESS Basic"){
                                                   
                                                        checkOutWallet(plan.price,plan.title,1)
                                                    }else{
                                                        
                                                        if(HaError == "Hectare cannot be less than 5"){
    
                                                        }else{
                                                            
                                                            if(isAdvanceClicked){
                                                                setShowConfirmation(true)
                                                            }
    
                                                            setIsAdvanceClicked(true)
                                                              
                                                        }
                                                    }
                                                    }}
                                                >
                                                    <ArrowBackIcon style={{ color: "#000", transform: "rotate(180deg)" }} />
                                                </Box>
                                            </Typography>

                                            {
                                                plan.title == "ESS Advance" ? <>
                                                    <Typography
                                                        component="span"
                                                        sx={{

                                                            textAlign: 'center',
                                                            fontSize: "1rem",
                                                            color: "#DF1B41",
                                                            display: "flex",
                                                            alignItems: "center", fontWeight: "bold",
                                                            justifyContent: "space-between",
                                                        }}

                                                    >{HaError}</Typography>

                                                </> : <></>
                                            }
                                               
                                            


                                             
                                        </CardContent>

                                    </StyledCard>
                                </Box>
                            ))}
                        </Stack>
                    </Box>



                </Box>
            </Box>




        </Grid>
    );
}
