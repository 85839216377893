import ContactUs from "../contact/ContactUs";
import Maps from "../contact/Maps";
import Apps from "./Apps";
import CustomFooter from "./CustomFooter";
import CustomHeader from "./NewHeader";
//import CustomHeader from "./CustomHeader";
import HomeComponent1 from "./HomeComponent1";
import HomeComponent2 from "./HomeComponent2";
import HomeComponent3 from "./HomeComponent3";
import HomeComponent4 from "./HomeComponent4";
import PlanComponent from "./PlanComponent";
import SDGComponent from "./SDGComponent";
import BlogCard from "./Blog";
import AppStepper from "./AppStepper";
import AppleScrollAnimation from "../utils/ScrollAnimator";

export default function Homepage() {
  return (
    <>
 
      <CustomHeader/>
      {/* <CustomHeader /> */}
      <HomeComponent1 /> 
      <HomeComponent2 />
    


{/*    <AppleScrollAnimation
        imgUrl='https://images.unsplash.com/photo-1620121478247-ec786b9be2fa?q=80&w=3132&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        subheading='Subheading 1'
        heading='Heading 1'
      />
 
*/}
      <BlogCard/>
      <AppStepper/>
      <SDGComponent/>
     
      {/* <HomeComponent4 /> */}
      <CustomFooter/>

      {/* 
       */}


       {/* <HomeComponent3 /> */}
      {/* <Apps /> */}
      {/* */}


    </>
  );
}
