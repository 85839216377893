import Toolbar from "@mui/material/Toolbar";
import { Box, Typography, Grid, Container, Paper } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import { useWindowSize } from "@uidotdev/usehooks";
import { Height } from "@mui/icons-material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PersonIcon from '@mui/icons-material/Person';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import { Link } from "react-router-dom";
import { Primary } from "../utils/constants";
export default function CarbonMonitoringView() {

  const [openForm, setOpenFrom] = React.useState(false);
  const [openLoginForm, setOpenLoginFrom] = React.useState(false);
  const [userToken, setUserToken] = React.useState("");
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    org: ""
  });

  const [formDataLogin, setFormDataLogin] = React.useState({
    email: "",
    password: "",

  });

  const [disableButton, setDisableButton] = React.useState(false);

  const handleClose = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
    });
    setOpenFrom(false);
  };

  const handleLoginClose = () => {

    setFormDataLogin({
      email: "",
      password: "",
    });
    setOpenLoginFrom(false);

  }

  const showToast = (message, type = "success") => {
    toast[type](message, {
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleSubmitButton = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    const { name, email, phoneNumber, org } = formData;

    if (!name.trim() || !email.trim() || !phoneNumber.trim()) {
      showToast("Please fill all fields", "error");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showToast("Invalid email format", "error");
      return;
    }

    try {
      const response = await axios.post("https://escan-systems.com/api/requestAccess", {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        org: org
      });
      if (response.status === 200) {
        showToast("Your request for account is sent successfully", "success");
        handleClose();
      } else {
        showToast("Error occurred. Please try again later", "error");
      }
    } catch (error) {
      showToast("Error occurred. Please try again later", "error");
    }
    setDisableButton(false);
  };


  const galleryVideos = [

    { url: "/videos/concept1.mp4", title: "Concept 1" },
    { url: "/videos/concept2.mp4", title: "Concept 2" },
    { url: "/videos/concept3.mp4", title: "Concept 3" },
    { url: "/videos/demo1.mp4", title: "Demo 1" },
    { url: "/videos/demo2.mp4", title: "Demo 2" },
    // { url: "/videos/app_demo1.mp4", title: "App Demo 1" },
  ];


  const handleSubmitButtonLogin = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    const { email, password } = formDataLogin;


    try {
      setDisableButton(false);
      const endPoint = "https://escan-systems.com/api/signin";
      const data = { email, password };
      try {
        const response = await axios.post(endPoint, data);
        if (response.data.login) {
          setUserToken(response.data.token)
          console.log(response.data.token)
          setOpenLoginFrom(false)
        } else {
          setUserToken("")
          toast.error("Invalid credentials", {
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
          });
        }

        //await loginAuth({ token: response.data.token });
        setDisableButton(false);
      } catch (error) {
        setDisableButton(false);
        toast.error("Invalid credentials", {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      showToast("Error occurred. Please try again later", "error");
    }
    setDisableButton(false);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleInputChangeLogin = (e) => {
    const { name, value } = e.target;
    // console.log(name,value)
    setFormDataLogin({
      ...formDataLogin,
      [name]: value,
    });
  };



  const windowSize = useWindowSize();
  return (
    <>

      <Dialog
        open={openForm}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          sx: {
            width: "100%",
            backgroundColor: "#212930",
            padding: 3,
            borderRadius: "16px",
          },
          onSubmit: handleSubmitButton,
        }}
      >
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            color={"white"}
          >
            <Typography variant="h5" component={"div"}>
              Request Account
            </Typography>
          </Box>


        </DialogTitle>
        <DialogContent sx={{ marginTop: "5px" }}>
          <DialogContentText
            display={"flex"}
            justifyContent={"center"}
            mb={2}
            color={"white"}
          >
            Enter following details to request account for Investors Showcase.
          </DialogContentText>
          <TextField
            placeholder="John Doe"
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "white",
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            placeholder="john@domain.com"
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "white",
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            placeholder="03XXXXXXXXX"
            margin="dense"
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "white",
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />

          <TextField
            placeholder=""
            margin="dense"
            id="org"
            name="org"
            label="Organization"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "white",
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formData.org}
            onChange={handleInputChange}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="submit" style={{ backgroundColor: "rgb(135, 120, 73)", color: 'white', fontWeight: 'bold' }} disabled={disableButton}>
            Request Account
          </Button>

          <Button variant="contained" onClick={() => {
            setOpenFrom(false)
            setOpenLoginFrom(true)
          }} style={{ backgroundColor: "green", color: 'white', fontWeight: 'bold' }}>
            Already Have Access?
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openLoginForm}
        onClose={handleLoginClose}
        PaperProps={{
          component: "form",
          sx: {
            width: "100%",
            backgroundColor: "#212930",
            padding: 3,
            borderRadius: "16px",
          },
          onSubmit: handleSubmitButtonLogin,
        }}
      >
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            color={"white"}
          >
            <Typography variant="h5" component={"div"}>
              Investors Showcase Account
            </Typography>
          </Box>


        </DialogTitle>
        <DialogContent sx={{ marginTop: "5px" }}>
          <DialogContentText
            display={"flex"}
            justifyContent={"center"}
            mb={2}
            color={"white"}
          >
            Enter following details to access for Investors Showcase.
          </DialogContentText>

          <TextField
            placeholder="john@domain.com"
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "white",
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formDataLogin.email}
            onChange={handleInputChangeLogin}
          />



          <TextField
            placeholder=""
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            autoComplete="off"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "white",
                background: "black",
                borderRadius: "15px",
              },
            }}
            InputLabelProps={{
              style: { color: "#9FB4C7", fontSize: "15px" },
            }}
            InputProps={{ style: { borderRadius: "15px" } }}
            value={formDataLogin.password}
            onChange={handleInputChangeLogin}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoginClose}>Cancel</Button>
          <Button variant="contained" type="submit" style={{ backgroundColor: "rgb(135, 120, 73)", color: 'white', fontWeight: 'bold' }} disabled={disableButton}>
            Proceed
          </Button>


        </DialogActions>
      </Dialog>


      <ToastContainer />


      <Grid container >
        <Grid
          item
          xs={12} 
          style={{
            height: "270px",
            marginTop:'-7em', 
            background:"url('https://dmenjsnbha54b.cloudfront.net/images/ess8.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
 
          <Box
            style={{

              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Typography
              variant="h6"
              component="div"
              color={"white"}
              mb={3}
              mt={20}
              style={{ fontWeight: "bold",color:'white !important' }}
            >
              <strong   className="white-text">{"Carbon Monitoring"}</strong>
            </Typography>
          </Box>

        </Grid>
      </Grid>
 
      <div id="">
        <Grid container component="main" style={{ backgroundColor: Primary,  mt:'5em'}}>

          <Grid item xs={12} style={{ backgroundColor: Primary }}>
            <Box
              pt={5}
              pb={1}
              pr={2}
              pl={2}
              style={{
                margin: "0 auto",
              
                marginBottom: "10px",
                width:
                  windowSize.width < 992
                    ? "95%"
                    : windowSize.width < 1025
                      ? "85%"
                      : windowSize.width < 1960
                        ? "65%"
                        : "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
              }}
            >

              <Typography variant="h5" fontWeight="bold" sx={{ color: "#ffff" }}>
                Explore Our AI-Driven Carbon Tracking Solutions
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, color: "#ffff", lineHeight: 2 }}>
              ESS presents cutting-edge satellite analytics and AI-driven insights to track carbon sequestration, vegetation trends, and land-use changes for VCS and Gold Standard compliance. <br />
              <br/>Historical Vegetation Cover Analysis<br/>
              Tracking Vegetation Changes Over Decades. <br />
              ✔️ 1990–1999 Data → Analyze historical vegetation trends and long-term land-use changes.<br />
              ✔️ 2000–2010 Data → Observe recent variations in vegetation fraction cover to assess ecosystem restoration, deforestation, or afforestation impact.<br />
                {/* ✔️  NDRE.<br />
                ✔️  ReCI.<br /> */}<br />
                📌 Why It Matters? <br />
                ✔️ Understand long-term land-use patterns.<br />
                ✔️ Identify regions of carbon sequestration and deforestation.<br />
                ✔️ Validate historical land conservation efforts.<br /><br />

                🔘 [<Link to={"https://escan-systems.com/carbon-monitoring/2"}>Report 1990–1999 Vegetation Trends</Link>]<br />
                🔘 [<Link to={"https://escan-systems.com/carbon-monitoring/1"}>Report 2000–2010 Vegetation Trends</Link>]


              </Typography>

 
           

            </Box>
          </Grid>



        </Grid>
      </div>


      <div id="">
        <Grid container component="main" style={{ backgroundColor: Primary,  }}>

          <Grid item xs={12} style={{ backgroundColor: Primary }}>
            <Box
              
              pb={5}
              pr={2}
              pl={2}
              style={{
                margin: "0 auto", 
                marginBottom: "10px",
                width:
                  windowSize.width < 992
                    ? "95%"
                    : windowSize.width < 1025
                      ? "85%"
                      : windowSize.width < 1960
                        ? "65%"
                        : "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
              }}
            >

              <Typography variant="h5" fontWeight="bold" sx={{ color: "#ffff" }}>
              Agri Insights: AI-Powered Carbon & Vegetation Index Monitoring <br />
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, color: "#ffff", lineHeight: 2 }}>
              Comprehensive Carbon & Vegetation Analysis (2017–Present): <br />
                ✔️  Vegetation Health & Productivity (NDVI – Normalized Difference Vegetation Index).<br />
                ✔️ Soil-Adjusted Vegetation Growth (MSAVI – Modified Soil-Adjusted Vegetation Index)<br />
                ✔️ Nutrient & Plant Health Stress Detection (NDRE – Normalized Difference Red Edge Index)<br />
                ✔️ Chlorophyll Content & Carbon Estimation (ReCI – Red-Edge Chlorophyll Index)<br />
                ✔️ Water Availability & Drought Impact (NDMI – Normalized Difference Moisture Index)<br />
                Visual Insights Available:<br />
                •	Time-series graphs showing vegetation dynamics.<br />
                •	AI-generated spatial patterns of index variations.<br />
                •	Comparative analysis of carbon sequestration trends.<br />
                <br />
                🔘 [<Link to={"https://escan-systems.com/carbon-monitoring/3"}>Open Agri Insights</Link>]
    </Typography>

{/* 
              <Link
                to={`/carbon-monitoring/1`}
                style={{
                  color: "white",
                  backgroundColor: "rgb(135, 120, 73)",  
                  marginTop: "10px",

                  padding: "12px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "15px",
                  textTransform: "capitalize",
                  textAlign: "center",
                  display: "inline-block", 
                  textDecoration: "none",  
                }}

                onClick={()=>{}}
              >
                Try Now
              </Link> */}


            </Box>
          </Grid>



            </Grid>
          </div>



    </>

    // <Box
    //   sx={{
    //     position: "relative",
    //     minHeight: "100vh",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     overflow: "hidden",
    //     backgroundColor: "#12181E",
    //     color: "white",
    //   }}
    // >
    //   <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
    //     <Grid container spacing={4} alignItems="center">
    //       {/* Left Side - Text Content */}
    //       <Grid item xs={12} md={6}>
    //         <Box sx={{ pb: 5 }}>
    //           <img
    //             src="https://eos.com/wp-content/uploads/2025/02/lv-logo.svg"
    //             alt="lv-logo"
    //             width={176}
    //             height={78}
    //             style={{ height: "auto" }}
    //           />
    //         </Box>
    //         <Typography variant="h4" fontWeight="bold">
    //           Remote sensing and machine learning techniques
    //         </Typography>
    //         <Typography variant="body1" sx={{ mt: 2, fontWeight: "bold", color: "#ffff" }}>
    //         ESS applies cutting-edge remote sensing and machine learning techniques to analyze vegetation 
    //         indices such as NDVI, MSAVI, NDRE, and ReCI, providing precise estimations of biomass accumulation and carbon storage. 
    //         By combining multi-source satellite data, ESS ensures high temporal and spatial accuracy in detecting deforestation, land degradation, and sustainable land management practices.
    //         This data-driven approach supports farmers, agribusinesses, and environmental organizations in meeting carbon credit certification requirements while promoting climate-smart agricultural practices.
    //         By offering transparent, reliable, and verifiable carbon monitoring solutions, 
    //         ESS empowers stakeholders to maximize their sustainability impact and contribute to global climate change mitigation efforts.
    //         </Typography>
             
    //         <Button
    //           variant="contained"
    //           sx={{ mt: 3, backgroundColor: "#FF914D", color: "#fff", fontWeight: "bold", px: 4, py: 1.5 }}
    //           href="https://eos.com/landviewer/"
    //           target="_blank"
    //         >
    //           Try Now
    //         </Button>
    //       </Grid>

    //       {/* Right Side - Image Content */}
    //       <Grid item xs={12} md={6} sx={{ position: "relative" }}>
    //         <Box
    //           component="img"
    //           src="https://dmenjsnbha54b.cloudfront.net/images/ess8.png"
    //           alt="live satellite imagery"
    //           sx={{  borderRadius: "10px",marginRight:30 }}
    //         />
    //       </Grid>
    //     </Grid>
    //   </Container>
    // </Box>


  );
}
