import { Card, CardContent, Grid, Typography, TextField, Button, Box } from "@mui/material";
import { Primary, Secondary, THEME_TEXT_COLOR } from "../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import * as React from "react";
import axios from "axios";

export default function Maps() {
    const [formData, setFormData] = React.useState({
        email: "",
        name: "",
        phone: "",
        message: "",
      });
      const [errors, setErrors] = React.useState({});

      const [captchaValue, setCaptchaValue] = React.useState(null);
      const [loading, setLoading] = React.useState(false);
      const [responseMessage, setResponseMessage] = React.useState(""); 


      const validateField = (name, value) => {
        let error = "";
    
        switch (name) {
          case "email":
            if (!value) {
              error = "Email is required";
            } else if (!/^\S+@\S+\.\S+$/.test(value)) {
              error = "Invalid email format";
            }
            break;
    
          case "name":
            if (!value) {
              error = "Name is required";
            } else if (value.length < 2) {
              error = "Name must be at least 2 characters";
            }
            break;
    
          case "phone":
            if (!value) {
              error = "Phone number is required";
            } else if (!/^\d{15}$/.test(value)) {
              error = "Phone must be a 10-digit number";
            }
            break;
    
          case "message":
            if (!value) {
              error = "Message is required";
            } else if (value.length < 10) {
              error = "Message must be at least 10 characters";
            }
            break;
    
          default:
            break;
        }
    
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      };
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        validateField(e.target.name, e.target.value);
      };
 
    const validateForm = () => {
        let isValid = true;

        Object.keys(formData).forEach((key) => {
            validateField(key, formData[key]);
            if (!formData[key]) isValid = false;
        });

        return isValid;
    };

      const handleSubmit = async (e) => {
        e.preventDefault();
        // if (!captchaValue) {
        //   setResponseMessage("Please verify the CAPTCHA.");
        //   return;
        // } 

        if(!validateForm()){
            setResponseMessage("Please review all errors, before sending a message");
            return;
        }
    
        setLoading(true);
        setResponseMessage("");
    
        try {
          const response = await axios.post("https://escan-systems.com/api/send-email", {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            query: formData.message 
          });
    
          setResponseMessage(response.data.message);
          setFormData({ email: "", name: "", phone: "", message: "" });  
        } catch (error) {
          setResponseMessage("Error sending email");
          console.error("Error:", error);
        }
    
        setLoading(false);
      };    
    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                minHeight="90vh"
                style={{ background: Secondary, display: "flex", flexDirection: "column",  }}
            >
                {/* <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
             
                </Box> */}

                <Card sx={{ maxWidth: 1000, width: "90%", mx: "auto", p: 4, borderRadius: 3, marginTop: 40, boxShadow: ' 0px 0px 24px 11px rgba(90, 125, 226, 0.1), var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)' }}>
                    <Grid container spacing={2}>
                        {/* Left Side - Contact Form */}
                        <Grid item xs={12} md={6}>
                            <CardContent>

                                <Typography variant="h5" fontWeight="bold" gutterBottom>
                                    Contact Us
                                </Typography>


                                {/* <Typography variant="body2" fontWeight="bold" sx={{ mt: 2 }}>
                                        Name
                                    </Typography> */}
                                <TextField
                                    id="outlined-email-input"
                                    label="Email"
                                    placeholder="example@gmail.com *"
                                    fullWidth
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    error={!!errors.email}
                                    helperText={errors.email}

                                    sx={{
                                        input: {
                                            color: { THEME_TEXT_COLOR },
                                            background: { Secondary },
                                            borderRadius: '15px',
                                            input: {
                                                color: { THEME_TEXT_COLOR },
                                                background: { Secondary },
                                                borderRadius: '15px',

                                            },
                                        },
                                        '& .MuiOutlinedInput-root': {

                                            '&.Mui-focused fieldset': {
                                                borderColor: 'rgb(135 120 73)',
                                            },
                                        },
                                    }}

                                    InputLabelProps={{
                                        style: { color: THEME_TEXT_COLOR, fontSize: "15px" },
                                    }}
                                    InputProps={{ style: { borderRadius: "5px" } }}
                                />


                                <TextField
                                    id="outlined-email-input"
                                    label="Name"
                                    placeholder="example@gmail.com *"
                                    fullWidth
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    error={!!errors.name}
                                    helperText={errors.name}

                                    sx={{
                                        input: {
                                            color: { THEME_TEXT_COLOR },
                                            background: { Secondary },
                                            borderRadius: '15px',
                                        },
                                        '& .MuiOutlinedInput-root': {

                                            '&.Mui-focused fieldset': {
                                                borderColor: 'rgb(135 120 73)',
                                            },
                                        },
                                    }}

                                    InputLabelProps={{
                                        style: { color: THEME_TEXT_COLOR, fontSize: "15px" },
                                    }}
                                    InputProps={{ style: { borderRadius: "5px", marginTop: '5px' } }}
                                />



                                <TextField
                                    id="outlined-email-input"
                                    label="Phone"
                                    placeholder="example@gmail.com *"
                                    fullWidth

                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    error={!!errors.phone}
                                    helperText={errors.phone}

                                    sx={{
                                        input: {
                                            color: { THEME_TEXT_COLOR },
                                            background: { Secondary },
                                            borderRadius: '15px',
                                            input: {
                                                color: { THEME_TEXT_COLOR },
                                                background: { Secondary },
                                                borderRadius: '15px',

                                            },
                                        },
                                        '& .MuiOutlinedInput-root': {

                                            '&.Mui-focused fieldset': {
                                                borderColor: 'rgb(135 120 73)',
                                            },
                                        },
                                    }}

                                    InputLabelProps={{
                                        style: { color: THEME_TEXT_COLOR, fontSize: "15px" },
                                    }}
                                    InputProps={{ style: { borderRadius: "5px", marginTop: '5px' } }}
                                />


                                <TextField
                                    id="outlined-email-input"
                                    label="Your Query / Message"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    placeholder="Enter Your Query / Message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    error={!!errors.message}
                                    helperText={errors.message}

                                    sx={{
                                        input: {
                                            color: { THEME_TEXT_COLOR },
                                            background: { Secondary },
                                            borderRadius: '15px',
                                        },
                                        '& .MuiOutlinedInput-root': {

                                            '&.Mui-focused fieldset': {
                                                borderColor: 'rgb(135 120 73)',
                                            },
                                        },
                                    }}

                                    InputLabelProps={{
                                        style: { color: THEME_TEXT_COLOR, fontSize: "15px" },
                                    }}
                                    InputProps={{ style: { borderRadius: "5px", marginTop: '5px',marginBottom:'5px' } }}
                                />


                                {/* <ReCAPTCHA
                                    sitekey="6Lfbgt4qAAAAAH3WmjgqjSf2dUB_VgcGaYVV_oJ7"
                                    // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                    onChange={(value) => setCaptchaValue(value)}
                                /> */}


                                <Button
                                    disabled={loading} 
                                    variant="contained"
                                    style={{
                                        background: "#877849",
                                        color: { THEME_TEXT_COLOR },
                                        marginTop: "10px",
                                        width: "100%",
                                        padding: "12px",
                                        borderRadius: "5px",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={handleSubmit}
                                // onClick={loginUser}
                                >
                                    Send
                                </Button>

                                
                                {/* Response Message */}
                                {responseMessage && (
                                    <Typography style={{color:responseMessage.includes("Error") ? "red !important" : "green !important"}}  sx={{ mt: 2 }}>
                                        {responseMessage}
                                    </Typography>
                                )}

                            </CardContent>
                        </Grid>

                        {/* Right Side - Image Placeholder */}
                        <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {/* <Box
                                sx={{
                                    width: 120,
                                    height: 120,
                                    bgcolor: "#ddd",
                                    borderRadius: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "relative",
                                }}
                            >
                                
                            </Box> */}

 
                            <iframe 
                           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d990736.5845427822!2d145.05313529999998!3d-37.9725665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC%2C%20Australia!5e1!3m2!1sen!2s!4v1742487044218!5m2!1sen!2s" 
                                style={{
                                    width: '100%', height: '100%', allowfullscreen: "",
                                    loading: "lazy", referrerpolicy: "no-referrer-when-downgrade",
                                    borderRadius: 8
                                }}
                            ></iframe>
                        </Grid>
                    </Grid>
                </Card>

            </Grid>

            {/*    
       
       */}
        </>

    )
}