import Toolbar from "@mui/material/Toolbar";
import { Box, Typography, Grid, CardContent, Card } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import * as React from 'react';
import { 
    Button,
    Switch,
    Stack,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery
  } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import FinalCard from "./FinalCard";
import SmallerCard from "./SmallerCard";
import CustomCarousel from "./CustomCarousel";
import PixelCard from "./PixelCard";
import service_1 from "../../public/images/service_1.png";
import service_2 from "../../public/images/service_2.png";
import WeatherAnalytics from "../../public/images/WeatherAnalytics.png";
import MobileApp from "../../public/images/MobileApp.png";
import dashboardERPMain from "../../public/images/dashboard-erp-main.png";
import dashboardERP from "../../public/images/dashboard-erp.png";
import dashboardSub from "../../public/images/dashboard-erp-1.png";
import carbon1 from "../../public/images/carbon1.png";
import carbon2 from "../../public/images/carbon2.png";
import carbon3 from "../../public/images/carbon3.png";
import carbonMain from "../../public/images/carbon-main.png";
import Zoning from "../../public/images/Zoning.png"; 
import supplyChain from "../../public/images/supplyChain.jpg"; 
import konnect from "../../public/images/konnect.png";  
import ck from "../../public/images/ck.png"; 
import service_3 from "../../public/images/service_3.png";
import Goals_IMG from "../../public/images/Sustainable-Development-Goals.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import RecommendIcon from '@mui/icons-material/Recommend'; 
import { useState } from "react";
import { ASSET_IMAGE, Primary, Secondary, THEME_HEADING_COLOR, THEME_TEXT_COLOR } from "../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import CustomFooter from "./CustomFooter";
import CustomHeader from "./NewHeader";
//import CustomHeader from "./CustomHeader";  
import Service11 from "../../public/images/Service1.png";
import PlanComponent from "./PlanComponent";
 

export default function ServiceDetail() {
    const location = useLocation(); 
    const navigate = useNavigate();
    const [isYearly, setIsYearly] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const windowSize = useWindowSize();
    const [serviceData, setServiceData] = React.useState([]);

    const Goals1 = "SGD/1.jpg";
    const Goals2 = "SGD/2.jpg";
    const Goals3 = "SGD/3.jpg";
    const Goals4 = "SGD/6.jpg";
    const Goals5 = "SGD/7.jpg";
    const Goals6 = "SGD/11.jpg";
    const Goals7 = "SGD/13.jpg";
    const Goals8 = "SGD/14.jpg";
    const Goals9 = "SGD/16.jpg";

    const goals = [
        { id: 1, color: '#e5243b', text: 'No Poverty', desc: 'End poverty in all its forms everywhere.', img: ASSET_IMAGE + "/" + Goals1 },
        { id: 2, color: '#dda63a', text: 'Zero Hunger', desc: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture.", img: ASSET_IMAGE + "/" + Goals2 },
        { id: 3, color: '#4c9f38', text: 'Good Health and Well-being', desc: "Ensure healthy lives and promote well-being for all at all ages.", img: ASSET_IMAGE + "/" + Goals3 },
        { id: 6, color: '#26bde2', text: 'Clean Water and Sanitation', desc: "Ensure availability and sustainable management of water and sanitation for all.", img: ASSET_IMAGE + "/" + Goals4 },
        { id: 7, color: '#fcc30b', text: 'Affordable and Clean Energy', desc: "Ensure access to affordable, reliable, sustainable and modern energy for all.", img: ASSET_IMAGE + "/" + Goals5 },
        { id: 11, color: '#f89d2a', text: 'Sustainable Cities and Communities', desc: "Make cities and human settlements inclusive, safe, resilient and sustainable.", img: ASSET_IMAGE + "/" + Goals6 },
        { id: 13, color: '#3f7e44', text: 'Climate Action', desc: "Take urgent action to combat climate change and its impacts.", img: ASSET_IMAGE + "/" + Goals7 },
        { id: 14, color: '#0a97d9', text: 'Life Below Water', desc: "Conserve and sustainably use the oceans, seas and marine resources for sustainable development.", img: ASSET_IMAGE + "/" + Goals8 },
        { id: 16, color: '#00689d', text: 'Peace, Justice and Strong Institutions', desc: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.", img: ASSET_IMAGE + "/" + Goals9 },
    ];
    const [hoveredGoal, setHoveredGoal] = useState(null);

    const StyledCard = styled(Card)(({ theme, isPro }) => ({
        borderRadius: 20,
        height: "100%",
        backgroundColor: isPro ? Primary : Primary,
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
            transform: "translateY(-10px)",
            backgroundColor: Secondary,
            boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)"
        }
    }));



    const plans = [

        {
            title: "ESS Basic",
            display:"ESS Bulk",
            price: isYearly ? 100 : 100,
            features: [
               "Maximum Area 1000 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "Crop monitoring learning module"
            ],
            icon: <StarIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />
        },
        {
            title: "ESS Advance",
            display:"ESS Premium",
            price: isYearly ? 0.20 + "/ Ha" : 0.20 + "/Ha",
            features: [
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "AI Analytics for VRA",
                "OpenAI Driven Decision Support",
            ],
            icon: <svg  style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }}  xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
            <g clip-path="url(#clip0_419_224)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1101 0.461304C6.3275 0.461304 0 6.78881 0 14.5714C0 22.354 6.3275 28.6815 14.1101 28.6815C21.8927 28.6815 28.2202 22.354 28.2202 14.5714C28.2202 6.78881 21.8927 0.461304 14.1101 0.461304Z" fill="#FEC006"/>
            <path d="M6.77377 16.5171V11.4298C6.36039 11.2259 6.0848 10.8015 6.0848 10.3109C6.0848 9.62745 6.63047 9.07076 7.31944 9.07076C8.00841 9.07076 8.56509 9.62745 8.56509 10.3164C8.56509 10.4983 8.52651 10.6802 8.44935 10.8455L11.3596 13.9928L13.1564 10.3991C12.969 10.1786 12.8643 9.89752 12.8643 9.59989C12.8643 8.91643 13.421 8.35974 14.1099 8.35974C14.7989 8.35974 15.3556 8.91643 15.3556 9.59989C15.3556 9.89752 15.2453 10.1786 15.0635 10.3991L16.8603 13.9928L19.776 10.8455C19.6989 10.6802 19.6603 10.4983 19.6603 10.3164C19.6603 9.62745 20.217 9.07076 20.9059 9.07076C21.5949 9.07076 22.1516 9.62745 22.1516 10.3164C22.1516 10.8015 21.8705 11.2259 21.4626 11.4298V16.5227H6.77377V16.5171ZM21.8595 17.5478H6.36039C6.07378 17.5478 5.84229 17.7793 5.84229 18.066V20.2762C5.84229 20.5628 6.07378 20.7943 6.36039 20.7943H21.865C22.1516 20.7943 22.3831 20.5628 22.3831 20.2762V18.066C22.3776 17.7793 22.1461 17.5478 21.8595 17.5478Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_419_224">
            <rect width="28.2202" height="28.2202" fill="white" transform="translate(0 0.461304)"/>
            </clipPath>
            </defs>
            </svg>,
            isPro: true
        }
    ];

  
    React.useEffect(() => {

        if (location.pathname == "/service-detail/1") { 

            setServiceData({
                title: "Earth Scan System - Crop Monitoring",
                mainImage: Service11,
                subtitle: " Our Precision Crop Monitoring Solutions provide real-time, AI-powered insights to optimize farm productivity, manage risks, and improve sustainability.",
                serviceLink: "",
                servicePageHeader: "How Our Crop Monitoring Platform Makes Your Farm Work Efficient:",
                features: [
                    {
                        title: "Satellite-Based Crop Monitoring (Every 5-8 Days)",
                        direction: "left",
                        image: Service11,
                        details: [
                            "AI-driven analysis for crop health, irrigation, and yield prediction",
                            "Automated alerts for disease risk and stress detection",
                            "Variable rate application maps for fertilization and irrigation optimization"
                        ]
                    },
                    {
                        title: "Agrometeorology & Weather Analytics",
                        direction: "right", image: WeatherAnalytics,
                        details: [
                            "14-day hyperlocal weather forecasts (90m resolution)",
                            "Historical weather data since 1979 for climate trend analysis",
                            "Cold & heat stress alerts to support farm planning",
                            "Integration with Automatic Weather Stations (AWS)"
                        ]
                    },
                    {
                        title: "Mobile App for Field Scouting",
                        direction: "left", image: MobileApp,
                        details: [
                            "GPS-enabled app for offline field data collection",
                            "Real-time issue reporting with expert recommendations",
                            "Automated navigation to problem areas for faster response"
                        ]
                    }
                ]

            })
        } else if (location.pathname == "/service-detail/2") {
            setServiceData({
                title: "Carbon Credit Monitoring & Reporting Services",
                mainImage: carbonMain,
                subtitle:
                    "At ESS, we help businesses, landowners, and organizations track and report carbon sequestration efforts with VCS and Gold Standard compliance, ensuring transparency and accuracy in carbon credit generation.",
                serviceLink: "",
                servicePageHeader:
                    "How Our Carbon Credit Monitoring Platform Ensures Accuracy & Compliance:",
                features: [
                    {
                        title: "AI-Driven Carbon Credit Monitoring",
                        direction: "left", image: carbon1,
                        details: [
                            "Utilize satellite and remote sensing technology to track carbon sequestration",
                            "Align with Verified Carbon Standard (VCS) methodologies for accurate credit estimation",
                            "Provide consistent, reliable data for compliance and reporting"
                        ]
                    },
                    {
                        title: "Sustainability Compliance & Reporting",
                        direction: "right", image: carbon3,
                        details: [
                            "Automated carbon reporting to meet VCS and Gold Standard requirements",
                            "Land-use classification tracking with geospatial analytics",
                            "Support for corporate ESG goals and regulatory compliance"
                        ]
                    },
                    {
                        title: "Long-Term Carbon Stock Assessment",
                        direction: "left", image: carbon2,
                        details: [
                            "Historical and real-time monitoring of carbon stock changes",
                            "Periodic reporting for carbon credit validation",
                            "Data-driven insights for audit and certification requirements"
                        ]
                    }
                ]
            })
        } else if (location.pathname == "/service-detail/3") {
            setServiceData({
                title: "Digital Agriculture Solutions",
                mainImage: Service11,
                subtitle:
                    "We offer AI-driven digital solutions that enhance efficiency, sustainability, and profitability across the agricultural value chain.",
                serviceLink: "",
                servicePageHeader:
                    "How Our Digital Agriculture Solutions Transform Farming Efficiency:",
                features: [
                    {
                        title: "AI-Powered Decision Support Systems",
                        direction: "left", image: Zoning,
                        details: [
                            "Custom-built AI models for yield prediction, pest forecasting, and farm optimization",
                            "Automated insights to enhance crop management and cost efficiency",
                            "Predictive analytics for smarter supply chain planning"
                        ]
                    },
                    {
                        title: "Geospatial Analytics & Remote Sensing",
                        direction: "right", image: Service11,
                        details: [
                            "High-resolution satellite imagery for land classification and crop assessment",
                            "Advanced vegetation indices (NDVI, NDMI, NDRE, MSAVI, ReCl) for growth monitoring",
                            "Soil variability mapping to optimize nutrient application"
                        ]
                    },
                    {
                        title: "Supply Chain & Market Intelligence",
                        direction: "left", image: supplyChain,
                        details: [
                            "End-to-end digital tracking from farm to market",
                            "Blockchain-based traceability solutions for food security and compliance",
                            "Market intelligence insights to optimize pricing and risk management"
                        ]
                    }
                ]
            })
        } else if (location.pathname == "/service-detail/4") {
            setServiceData({
                title: "Smart Farm Management – CropERP",
                mainImage:dashboardERPMain,
                subtitle:
                    "CropERP is a comprehensive farm management system that integrates all aspects of farm operations, from land preparation to market sales.",
                serviceLink: "",
                servicePageHeader:
                    "How CropERP Enhances Smart Farm Management & Efficiency:",
                features: [ 
                    { 
                        title: "End-to-End Farm Management",
                        direction: "left", image: dashboardERP,
                        details: [
                            "Track crop records, inventory, labor, and finances in one platform",
                            "Farm history and land records management for long-term insights",
                            "Automated reporting & analytics to improve decision-making"
                        ]
                    },
                    {
                        title: "GIS-Integrated Crop Mapping",
                        direction: "right", image: dashboardSub,
                        details: [
                            "Visualize farm performance using real-time spatial mapping",
                            "Identify field variability in soil health, crop growth, and resource allocation"
                        ]
                    }
                ]
            })
        }
        else if (location.pathname == "/service-detail/5") {
            setServiceData({
                title: "IoT-Enabled Agriculture – CropKonnect",
                mainImage: ck,
                subtitle:
                    "CropKonnect is an IoT-based agriculture solution that collects real-time soil and environmental data to enhance precision farming.",
                serviceLink: "",
                servicePageHeader:
                    "How CropKonnect Enhances Precision Farming with IoT Technology:",
                features: [
                    {
                        title: "Real-Time Environmental Monitoring",
                        direction: "left", image: konnect,
                        details: [
                            "Capture soil moisture, temprature, and atmospheric conditions using smart sensors",
                            "Seamless cloud-based data transmission for real-time farm insights",
                            "Centralized visualization"
                        ]
                    },
                    {
                        title: "Data-Driven Precision Agriculture",
                        direction: "left", image: konnect,
                        details: [
                            "Analyze data for optimized irrigation and fertilization strategies",
                            "Identify early warning signs of crop stress and make informed decisions"
                        ]
                    }
                ]
            })
        }
    }, [location.pathname]); 

    return (
        <>
            <CustomHeader />

            <Grid
            className="mainServiceHeader"
                container
                justifyContent="center"
                alignItems="center"
                height={"250px"}
                style={{
                    background: `url(${serviceData.mainImage})`,
                    position: "absolute", top: 0,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    padding: 10  ,  backgroundRepeat: 'no-repeat'
                 
                }}
            >

                <Box
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity here (0.5 = 50%)

                    }}
                />


                <Box
                    style={{

                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    {/* <Typography
                        variant="h6"
                        component="div"
                        color={THEME_TEXT_COLOR}
                        mb={3}
                        mt={10}
                        style={{ fontWeight: "bold", color: 'white !important' }}
                    >
                        <strong className="white-text">{"Carbon Monitoring"}</strong>
                    </Typography> */}
                </Box>

            </Grid>

            <div id="sdg" style={{ 

            }}>

                <Grid container component="main">

                    <Grid item xs={12} style={{ backgroundColor: Primary }}>
                        <Box
                            style={{
                                position: "relative",
                                borderRadius: "50px",
                                margin: "0 auto",
                                marginTop: "5em",
                                marginBottom: "40px",
                                width:
                                    windowSize.width < 992
                                        ? "95%"
                                        : windowSize.width < 1025
                                            ? "85%"
                                            : windowSize.width < 1960
                                                ? "65%"
                                                : "50%",
                            }}
                        >

                            <Box>


                                <Typography
                                    variant="h6"
                                    component="div"
                                    textAlign={"justify"}
                                    className="serviceLevelPage" 
                                  
                                    style={{ color: 'white !important',paddingTop:5 }}
                                >

                                    {/* <CheckIcon style={{ color: THEME_TEXT_COLOR, marginRight: 20, marginBottom: 5, fontFamily: 'Gilroy-Medium' }} /> */}

                                    <strong className="serviceLevelPage" style={{ color: 'white !important', fontFamily: 'Gilroy-Bold' }}>
                                        {serviceData.title}:{" "}

                                    </strong>

                                </Typography>

                                <Typography
                                    variant="body1"
                                    component="div"
                                    textAlign={"justify"}
                                    className="serviceLevelPage"
                                    mt={2}
                                    padding={1}
                                    style={{ color: 'white' }}
                                >


                                    {serviceData.subtitle}

                                </Typography>

                                {!(location.pathname === "/service-detail/5" || location.pathname === "/service-detail/4" ) && (
                                    <Button
                                        id="learn_more"
                                        size="small"
                                        sx={{ marginRight: 2, float: "left !important", mt: 2, mb: 2, width: "30%" }}
                                        onClick={() => {
                                            if(location.pathname === "/service-detail/2"){
                                                navigate("/carbon-monitoring")
                                            }else{
                                                navigate("/registration")
                                            }
                                           
                                        }}
                                    >
                                        {"Try Now"}
                                    </Button>
                                )}
                               

                               {
                                    location.pathname === "/service-detail/1" ? <>


                                        <img
                                            src={`${ASSET_IMAGE}/globe.png`}
                                            style={{ paddingBottom:5,paddingTop:10}}
                                            className="w-full max-w-[500px] h-auto transition-transform duration-500 transform origin-center cursor-pointer"
                                            useMap="#image-map"
                                            alt="earth scan system"
                                        />
                                    </> : <></>

                                }
                                <Typography
                                    variant="h6"
                                    component="div"
                                    textAlign={"justify"}
                                    mt={location.pathname === "/service-detail/1"?10:20}
                                    mb={5}
                                    textAlign={"center"}

                                >


                                    <strong style={{ color: THEME_TEXT_COLOR, fontFamily: 'Gilroy-Bold' }}>
                                        {serviceData.servicePageHeader}{" "}

                                    </strong>

                                </Typography>

                               

                                {serviceData && serviceData.features && serviceData.features.length > 0 ? (
                                    serviceData.features.map((feature, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "20px",
                                                marginTop: index === 0 ? "0px" : "20px",
                                                flexDirection: index % 2 === 0 ? windowSize.width < 992 ? "column-reverse" : "row" : "row-reverse",
                                            }}
                                        >

                                            {
                                                feature.title == "Sustainability Compliance & Reporting" ? <>

                                                    <img
                                                        src={feature.image}
                                                        alt={feature.title}
                                                        style={{
                                                            width: "528px",
                                                            height: "350px",
                                                            flexShrink: 0,
                                                            border: "2px solid #ccc",
                                                            borderRadius: "10px",
                                                        }}
                                                    />


                                                </> : <>

                                                    <img
                                                        src={feature.image}
                                                        alt={feature.title}
                                                        style={{
                                                            width: location.pathname === "/service-detail/1" ? "700px" : "628px",
                                                            height: "350px",
                                                            flexShrink: 0,
                                                            border: "2px solid #ccc",
                                                            borderRadius: "10px",
                                                        }}
                                                    /></>



                                            }

                                           
                                            <Card
                                                style={{
                                                    padding: "20px",
                                                    borderRadius: "10px",
                                                    backgroundColor: Secondary,
                                                    width: "53.33%",
                                                    minWidth: "270px",
                                                }}
                                            >
                                                <CardContent>
                                                    <Typography
                                                        variant="h6"
                                                        color={THEME_HEADING_COLOR}
                                                        mb={2}
                                                        style={{ fontWeight: "bold", fontFamily: "Gilroy-Bold" }}
                                                    >
                                                        <strong>{feature.title}</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        color={THEME_TEXT_COLOR}
                                                        mb={2}
                                                        style={{ fontFamily: "Gilroy-Medium" }}
                                                    >
                                                        {feature.details.map((point, idx) => (
                                                            <span key={idx}>
                                                                <CheckIcon id="checkIconServices" /> {point} <br />
                                                            </span>
                                                        ))}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    ))
                                ) : (
                                    <p>Loading features...</p>  
                                )}




                                {/* <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                 
                                    <img
                                        src={Service11}
                                        alt="title"
                                        style={{
                                            width:"628px",
                                            height: "350px",
                                            flexShrink: 0,
                                            border: "2px solid #ccc", // Add a border
                                            borderRadius: "10px", // Optional for rounded corners
                                        }}
                                    />

                            
                                    <Card
                                        style={{
                                            padding: "20px",
                                            borderRadius: "10px",
                                            backgroundColor: Secondary,
                                            width: "53.33%",
                                            minWidth: "270px",
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h6"
                                                color={THEME_HEADING_COLOR}
                                                mb={2}
                                                style={{ fontWeight: "bold", fontFamily: "Gilroy-Bold" }}
                                            >
                                                <strong>Satellite-Based Crop Monitoring (Every 5-8 Days)</strong>
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color={THEME_TEXT_COLOR}
                                                mb={2}
                                                style={{ fontFamily: "Gilroy-Medium" }}
                                            >
                                                 <CheckIcon id="checkIconServices"/>AI-driven analysis for crop health, irrigation, and yield prediction. <br/>
                                                 <CheckIcon id="checkIconServices"/>Automated alerts for disease risk and stress detection.<br/>
                                                 <CheckIcon id="checkIconServices" />Variable rate application maps for fertilization and irrigation optimization.

                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </div>


                                <div style={{ display: "flex", alignItems: "center", gap: "20px" ,marginTop:'20px'}}>
                                 
                                
                         
                                 <Card
                                     style={{
                                         padding: "20px",
                                         borderRadius: "10px",
                                         backgroundColor: Secondary,
                                         width: "53.33%",
                                         minWidth: "270px",
                                     }}
                                 >
                                     <CardContent>
                                         <Typography
                                             variant="h6"
                                             color={THEME_HEADING_COLOR}
                                             mb={2}
                                             style={{ fontWeight: "bold", fontFamily: "Gilroy-Bold" }}
                                         >
                                             <strong>Agrometeorology & Weather Analytics</strong>
                                         </Typography>
                                         <Typography
                                             variant="body1"
                                             color={THEME_TEXT_COLOR}
                                             mb={2}
                                             style={{ fontFamily: "Gilroy-Medium" }}
                                         >
                                              <CheckIcon id="checkIconServices"/>Agrometeorology & Weather Analytics. <br/>
                                              <CheckIcon id="checkIconServices"/>Historical weather data since 1979 for climate trend analysis.<br/>
                                              <CheckIcon id="checkIconServices" />Cold & heat stress alerts to support farm planning.<br/>
                                              <CheckIcon id="checkIconServices" />Integration with Automatic Weather Stations (AWS)
                                         </Typography>
                                     </CardContent>
                                 </Card>

                                 <img
                                     src={Service11}
                                     alt="title"
                                     style={{
                                         width:"628px",
                                         height: "350px",
                                         flexShrink: 0,
                                         border: "2px solid #ccc", // Add a border
                                         borderRadius: "10px", // Optional for rounded corners
                                     }}
                                 />


                             </div>

                             <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                 
                                 <img
                                     src={Service11}
                                     alt="title"
                                     style={{
                                         width:"628px",
                                         height: "350px",
                                         flexShrink: 0,
                                         border: "2px solid #ccc", // Add a border
                                         borderRadius: "10px", // Optional for rounded corners
                                     }}
                                 />

                         
                                 <Card
                                     style={{
                                         padding: "20px",
                                         borderRadius: "10px",
                                         backgroundColor: Secondary,
                                         width: "53.33%",
                                         minWidth: "270px",
                                     }}
                                 >
                                     <CardContent>
                                         <Typography
                                             variant="h6"
                                             color={THEME_HEADING_COLOR}
                                             mb={2}
                                             style={{ fontWeight: "bold", fontFamily: "Gilroy-Bold" }}
                                         >
                                             <strong>Mobile App for Field Scouting</strong>
                                         </Typography>
                                         <Typography
                                             variant="body1"
                                             color={THEME_TEXT_COLOR}
                                             mb={2}
                                             style={{ fontFamily: "Gilroy-Medium" }}
                                         >
                                              <CheckIcon id="checkIconServices"/>GPS-enabled app for offline field data collection. <br/>
                                              <CheckIcon id="checkIconServices"/>Real-time issue reporting with expert recommendations.<br/>
                                              <CheckIcon id="checkIconServices" />Automated navigation to problem areas for faster response.

                                         </Typography>
                                     </CardContent>
                                 </Card>
                             </div> */}



                            </Box>

                            {
                                location.pathname === "/service-detail/1" ? <>  <PlanComponent /> </> : <></>
                            }


                            
                            {!(location.pathname === "/service-detail/5" || location.pathname === "/service-detail/4") && (
                                    <Button
                                        id="learn_more"
                                        size="small"
                                        sx={{ marginRight: 2, float: "left !important", mt: 2, mb: 2, width: "30%" }}
                                        onClick={() => {
                                            if(location.pathname === "/service-detail/2"){
                                                navigate("/carbon-monitoring")
                                            }else{
                                                navigate("/registration")
                                            }
                                           
                                        }}
                                    >
                                        {"Try Now"}
                                    </Button>
                                )}

                            {/* <Button id="learn_more" size="small" sx={{ marginRight: 2, mt: 2, mb: 2, width: "30%" }} onClick={() => {

                                //navigate(data[title].link) 

                            }} >{"Try Now"}</Button> */}


                        </Box>
                    </Grid>


                </Grid>
            </div>

            <CustomFooter />
        </>

    );
}
  