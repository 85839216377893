import { useRoutes, Navigate } from "react-router-dom";
import { useAuth } from "./components/hooks/useAuth";
import MapView from "./components/advisory";
import Homepage from "./components/home/Homepage";
import Registration from "./components/registration/LoginPage";
import PrivacyPolicy from "./components/privacypolicy";
import { Helmet } from "react-helmet";
import InvestorsPage from "./components/Investors-View/InvestorsPage";
import TutorialComponent from "./components/how-to/TutorialComponent";
import Tutorial from "./components/how-to";
import Plans from "./components/advisory/Plan";
import Success from "./components/advisory/Success";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import CarbonMonitoring from "./components/advisory/CarbonMonitoring";
import CompleteContact from "./components/contact/CompleteContact";
import Carbon from "./components/Carbon-View/CarbonPage";
import ServiceDetail from "./components/home/ServiceDetail";
import AboutPage from "./components/AboutUs/About";



const ProtectedRoute = ({ children }) => {
  const { user, status } = useAuth();

  if (!user) {
    return <Navigate to="/registration" />;
  }
  if(status == 1 || status == "1"){
  //  console.log("TRUE");

  return <Navigate to="/plan-subscribe/expire" /> 
    
  }

 // console.log("USEr",user,status)
  return children;
};

const AppRouter = () => {
  useEffect(() => {
    ReactGA.initialize("G-V99PK8WZNH");
    ReactGA.send("pageview"); 
  }, []);

  const { user } = useAuth();
  <Helmet>
    <meta property="og:title" content="Earth Scan System | One stop solutions for Earth problems" />
    <meta property="og:description" content="ESS aims to create a beneficial impact by leveraging geospatial data alongside tailored algorithms." />
    <meta property="og:image" content="https://escan-systems.com/static/media/ESS-06.ca2868adecf111629108.png" />
    <meta property="og:url" content="https://escan-systems.com/" />
    <meta property="og:type" content="website" />
   
  </Helmet>
  const routes = useRoutes([
    { path: `${process.env.PUBLIC_URL}`, element: <Homepage /> },
    { path: `${process.env.PUBLIC_URL}/investors-showcase`, element: <InvestorsPage /> },
    { path: `${process.env.PUBLIC_URL}/registration`,   element: user ? <Navigate to="/monitor" /> : <Registration />, },
    {
      path: `/monitor`,
      element: (
        <ProtectedRoute>
          <MapView />
        </ProtectedRoute>
      ),
    },
    {
      path: `/plan-subscribe`,
      element: (
        <ProtectedRoute>
          <Plans />
        </ProtectedRoute>
      ),
    },

    {
      path: `/plan-subscribe/expire`,
      element: (
         
          <Plans />
        
      ),
    },

    {
      path: `/blog`,
      element: <Navigate to="/blog/" replace />,
    },

    {
      path: `/carbon-monitoring/:param1`,
      element: (
        <ProtectedRoute>
          <CarbonMonitoring />
        </ProtectedRoute>
      ),
    },
    {
      path: `/carbon-monitoring`,
      element: (
         
          <Carbon/>
       
      ),
    },

    { path: "*", element: <Navigate to={`${process.env.PUBLIC_URL}`} /> },
    { path: '/privacy-policy', element: <PrivacyPolicy  /> },
    { path: '/how-to', element: <Tutorial  /> },
    { path: '/success', element: <Success  /> },
    { path: `/contact-us`, element: <CompleteContact /> },
    { path: `/service-detail/:param1`, element: <ServiceDetail /> },
    { path: `/about`, element: <AboutPage /> },
  ]);

  return routes;
};

export default AppRouter;
