import * as React from 'react';
import { Box, Button, Chip, Grid, Paper, Typography } from '@mui/material';
import Service1 from "../../public/images/Service1.png";
import Service2 from "../../public/images/Service2.png";
import Service3 from "../../public/images/Service3.png";
import ck from "../../public/images/ck.png";
import fms from "../../public/images/fms.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Primary, Secondary, THEME_TEXT_COLOR } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import axios from 'axios';


function BlogCard() {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [data, setData] = React.useState([]);


    React.useEffect(() => {
        const fetchPosts = async () => {

            try {

                const response = await axios.get("https://escan-systems.com/blog/wp-json/custom/v1/posts", {
                    headers: {
                        username: "ESS",
                        password: "p!ingP0n%"
                    }
                });
                //console.log("response.",response.data)
                //setPosts(response.data);
                const formattedData = response.data.map(post => ({
                    title: post.title,
                    items: [post.excerpt.replace(/&hellip;/g, "...")],
                    image: post.image || "default-image.jpg",
                    button: "Start Monitoring",
                    params: post.id,
                    link: post.link,
                    category: post.categories[0].toUpperCase(),
                    author: post.author,
                }));

                setData(formattedData);
            } catch (err) {
                //  setError(err.response?.data || err.message);
            }

        };

        fetchPosts();
    }, []);

    return (
        <>

            <Grid item xs={12} style={{ backgroundColor: Primary }}>
                <Box
                    pt={5}
                    pb={5}
                    pr={2}
                    pl={2}
                    style={{
                        margin: "0 auto",
                        marginTop: "40px",
                        marginBottom: "10px",
                        width:
                            windowSize.width < 992
                                ? "100%"
                                : windowSize.width < 1025
                                    ? "90%"
                                    : windowSize.width < 1960
                                        ? "70%"
                                        : "55%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "start",
                    }}
                >

                    <Typography
                        variant="h5"
                        component="div"
                        color={"white"}
                        style={{ fontWeight: "bold", color: "#f27e30", fontFamily: 'Gilroy-Bold' }}
                    >
                        <strong>From Innovation to Application — Learn with ESS</strong>
                    </Typography>
                </Box>
            </Grid>
            <Box
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "20px",
                    margin: "0 auto",
                    width:
                        windowSize.width < 992
                            ? "100%"
                            : windowSize.width < 1025
                                ? "90%"
                                : windowSize.width < 1960
                                    ? "68%"
                                    : "55%",
                }}
            >

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {data.map((item, index) => (
                        <Grid item xs={4} sm={4} md={4} key={index} style={{ backgroundColor: "#fff" }}>
                            <Paper
                                elevation={3}
                                style={{
                                    padding: '16px',
                                    backgroundColor: Secondary,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    borderRadius: "8px",
                                    boxShadow: 'none', position: "relative"
                                }}
                            >
                                <img
                                    src={item.image}
                                    alt={item.title}
                                    style={{ height: '250px' }}
                                />

                                <Chip
                                    variant="outlined"
                                    label={item.category}
                                    size="small"
                                    sx={{ width: 100, marginTop: 2 }}
                                    style={{
                                        color: THEME_TEXT_COLOR,
                                        borderColor: Primary,
                                        background: Primary
                                    }}

                                />
                                <Typography
                                    variant="h4"
                                    component="div"
                                    fontSize={"18px"}
                                    mb={2}
                                    mt={2}
                                    style={{ fontWeight: "bold", color: THEME_TEXT_COLOR, fontFamily: "Gilroy-Medium" }}
                                >
                                    <strong> {item.title} </strong>
                                </Typography>

                                <Typography
                                    variant="body1"
                                    style={{ fontSize: "14px", lineHeight: "20px", fontFamily: "Gilroy-Medium" }}
                                >
                                    {item.items[0]}
                                </Typography>

                                <Typography
                                    variant="h4"
                                    component="div"
                                    fontSize={"18px"}
                                    mb={2}
                                    mt={2}
                                    style={{ fontWeight: "bold", color: THEME_TEXT_COLOR, fontFamily: "Gilroy-Medium" }}
                                >

                                </Typography>

                                <Box
                                    sx={{
                                        background: Primary,
                                        borderRadius: '50%',
                                        width: 30,
                                        height: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        bottom: 8,
                                        right: 16,
                                        marginTop: 2
                                    }}
                                    onClick={() => {

                                        window.open(item.link, '_blank');
                                    }}
                                >
                                    <ArrowBackIcon
                                        style={{ color: "#000", transform: "rotate(180deg)", width: 20, height: 20 }}
                                    />
                                </Box>



                            </Paper>

                        </Grid>
                    ))}
                </Grid>

            </Box>


        </>

    );
}

export default BlogCard;