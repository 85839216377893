import InvestorsView from ".";
import CustomFooter from "../home/CustomFooter";
import CustomHeader from "../home/NewHeader"; 
import BreadCrumbs from "../utils/BreadCrumbs";
import './style.css';
import ESS_LOGO from "../../public/images/ESS-06.png";
import { Button } from "@mui/material";
import { useRouter } from "../routes/hooks";

export default function InvestorsPage() {
  const router = useRouter();

  return (
    <>
     
      {/* <div className="main">

        <div className="inner-main">
          <div className="container">
            <img src={ESS_LOGO} width={"250px"} />

            <h1>
              Page is
              <br />
              Underconstruction
            </h1>
            <Button variant="contained"
              onClick={() => router.push('/')}

              style={{ background: 'rgb(177 148 108)', fontStyle: 'bold', color: 'white', marginTop: '10px', width: '30%', textTransform: 'capitalize' }}>
              Go Back
            </Button>
          </div>
        </div>
      </div> */}
      
      <CustomHeader/>
      <BreadCrumbs text={"Investors' Showcase"} />
      <InvestorsView />
      <CustomFooter />

    </>
  );
}
 