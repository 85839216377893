import * as React from 'react';
import { useWindowSize } from "@uidotdev/usehooks";
import {
    Box, Grid,
    Typography,
    Card,
    CardContent,
    Button,
    Switch,
    Stack,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery,  Dialog,
    DialogContent,
    TextField
} from "@mui/material";
import ESS_LOGO from "../../public/images/ESS-06.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import RecommendIcon from '@mui/icons-material/Recommend';
import axios from "axios";
import { useParams } from 'react-router-dom';

export default function CarbonMonitoring() {
    const StyledCard = styled(Card)(({ theme, isPro }) => ({
        borderRadius: 20,
        height: "100%",
        backgroundColor: isPro ? "#212a33" : "#11151b",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
            transform: "translateY(-10px)",
            boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)"
        }
    }));

    const [isYearly, setIsYearly] = React.useState(false);
    const [advancePrice, setAdvancePrice] = React.useState(0.20 + "/Ha");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const windowSize = useWindowSize();
    const [isAdvanceClicked, setIsAdvanceClicked] = React.useState(false);
    const [HaValue, setHAValue] = React.useState();
    const [HaError, setHaError] = React.useState("");
    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const checkOutWallet = async (price,plan,user) => {
        const planSplit = plan.split(" ")[1]; 
        if(plan == "ESS Advance"){
            //setAdvancePrice(100)
            setIsAdvanceClicked(!isAdvanceClicked)
            price = advancePrice;
        }
       
        const endPoint = `https://escan-systems.com/api/pay/${user}/${planSplit}/${price}`;
        const data = { email:JSON.parse(localStorage.getItem("email")) };
        try {
          const response = await axios.post(endPoint, data);
          //console.log(response);
          const newWindow = window.open(response.data.url, '_blank', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null

        } catch (error) {
         
        }

    }

    const plans = [

        {
            title: "ESS Basic",
            price: isYearly ? 100 : 100,
            features: [
                "Minimum Area 650 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "Crop monitoring learning module"
            ],
            icon: <StarIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />
        },
        {
            title: "ESS Advance",
            price: isYearly ? advancePrice :advancePrice,
            features: [
                "Minimum Area 5 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "AI Analytics for VRA",
                "OpenAI Driven Decision Support",
            ],
            icon: <RecommendIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />,
            isPro: true
        }
    ];


    const { param1 } = useParams();
     React.useEffect(() => {
     
        console.log(param1)
        const iframe = document.getElementById("mainIframe");

        if (iframe) {
          iframe.onload = () => {
            try {
              const iframeDoc = iframe.contentWindow.document;
              const otherhead = iframeDoc.getElementsByTagName("head")[0];
    
              const css = document.createElement("link");
              css.type = "text/css";
              css.rel = "stylesheet";
              css.href = "./carbon.css";
    
              otherhead.appendChild(css);
            } catch (error) {
              //https://ee-mobkhan2.projects.earthengine.app/view/ess8
              console.error("Cross-origin issue: Cannot access iframe content.",error);
            }
          };
        }
    
      }, [ ]);

    return (
 
      <iframe
      id="mainIframe"
      name="mainIframe"
      src={
        param1 == 2
          ? "https://ee-mobkhan2.projects.earthengine.app/view/ess8"
          : param1 == 3
          ? "https://ee-mobkhan2.projects.earthengine.app/view/agri-insights"  
          : "https://ee-mobkhan2.projects.earthengine.app/view/vfm"
      }
      style={{ width: "100%", height: "100vh", border: "0" }}
    ></iframe>
 
    );
}
