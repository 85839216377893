import CarbonMonitoringView from ".";
import CustomFooter from "../home/CustomFooter";
import CustomHeader from "../home/NewHeader"; 
import BreadCrumbs from "../utils/BreadCrumbs";
import './style.css';
import ESS_LOGO from "../../public/images/ESS-06.png";
import { Box, Button } from "@mui/material";
import { useRouter } from "../routes/hooks";
import { Grid, Typography } from "antd";

export default function Carbon() {
  const router = useRouter();

  return (
    <>
     
       
      <CustomHeader/> 
      <CarbonMonitoringView />
      <CustomFooter />

    </>
  );
}
 