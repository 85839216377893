import * as React from 'react';
import { Box, Grid, Link, Typography, Container, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useWindowSize } from "@uidotdev/usehooks";
import moment from 'moment/moment';
import { Primary, Secondary, THEME_TEXT_COLOR } from '../utils/constants';
import FOOTER from "../../public/images/footer.svg";
import ESS_LOGO_UPDATED from "../../public/images/ESS_UPDATED.png";

// Replace these with your own social media URLs
const socialMediaLinks = {
  facebook: '#',
  twitter: '#',
  instagram: '#',
};

const CustomFooter = () => {
  const windowSize = useWindowSize();
  return (
 
    <div className="bottom-0">
      <div className="flex   bg-no-repeat bg-top bg-cover" style={{ backgroundImage: `url(${FOOTER})` }}>
        <div className="w-full flex justify-center items-center">
          <div className="flex max-w-[808px] w-full px-5 md:px-10 md:max-w-[1104px] lg:max-w-[1736px] lg:px-[100px] 2xl:max-w-[1728px] 2xl:px-[150px]">
            <div className="pt-[84px] pb-[50px] flex flex-1 flex-col flex-wrap gap-[22px] md:flex-row md:justify-between md:gap-[66px]">

              {/* Logo and Description */}
              <div className="flex flex-col gap-[20px] flex-1">
                <img
                  alt="Nayatel logo"
                  loading="lazy"
                  width="376"
                  height="80"
                  decoding="async"
                  className="max-w-[150px] object-cover cursor-pointer"
                  style={{ color: "transparent" }}
                  srcSet={ESS_LOGO_UPDATED}
                  src={ESS_LOGO_UPDATED}
                />
                <p className="text-h7 text-dark-grey font-medium">
                  ESS - Transforming Data into Sustainable Solutions.
                </p>
              </div>

              {/* Explore Section */}
              <div className="hidden md:flex md:flex-col md:gap-[8px]">
                <p className="text-h7 text-charcol font-semibold mb-[2px]">Explore</p>
                <a className="text-h7 text-dark-grey font-medium" href="/about">About</a>
                <a className="text-h7 text-dark-grey font-medium" href="/blog/">Blog</a>
              </div>


              {/* <div className="hidden md:flex md:flex-col md:gap-[8px]">
            <p className="text-h7 text-charcol font-semibold mb-[2px]">Work with us</p>
            <a className="text-h7 text-dark-grey font-medium" target="_blank" rel="noopener" href="https://careers.nayatel.com/">Careers</a>
          </div> */}


              <div className="hidden md:flex md:flex-col md:gap-[8px]">
                <p className="text-h7 text-charcol font-semibold mb-[2px]">Support</p>
                <a className="text-h7 text-dark-grey font-medium" href="/contact-us">Contact</a>
                <a className="text-h7 text-dark-grey font-medium" href="/how-to">How to Use ESS Crop Monitoring?</a>
                {/* <a className="text-h7 text-dark-grey font-medium" href="/faqs">FAQs</a>
            */}
              </div>


              <div className="hidden md:flex md:flex-col md:gap-[8px]">
                <p className="text-h7 text-charcol font-semibold mb-[2px]">ESS Crop Monitoring System</p>
                <a className="text-h7 text-dark-grey font-medium" target="_blank" rel="noopener" href="/registration">Register</a>
                <a className="text-h7 text-dark-grey font-medium" target="_blank" rel="noopener" href="/registration">Log in</a>
              </div>

              {/* Legal Section */}
              <div className="hidden md:flex md:flex-col md:gap-[8px]">
                <p className="text-h7 text-charcol font-semibold mb-[2px]">Legal</p>
                <a className="text-h7 text-dark-grey font-medium" href="/privacy-policy">Privacy Policy</a>
              </div>


              {/* Connect Section */}
              <div className="hidden md:flex md:flex-col md:gap-[8px]">
                <div className="flex flex-col">
                  <p className="text-h5 text-charcol mb-[2px] font-bold">Connect</p>
                  <div className="flex gap-[9px] mt-[15px]">
                    {/* <a target="_blank" rel="noopener" href="https://www.facebook.com/people/Earth-Scan-Systems/61573810942990/">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path d="M7.14062 6.49219L15.2158 17.0501H16.4531L8.46981 6.49219H7.14062Z" fill="#333333" />
                        <path d="M11.8157 0.888672C5.78666 0.888672 0.898438 5.77689 0.898438 11.806C0.898438 17.8351 5.78666 22.7233 11.8157 22.7233C17.8448 22.7233 22.733 17.8351 22.733 11.806C22.733 5.77689 17.8448 0.888672 11.8157 0.888672ZM14.6024 18.3855L11.0815 13.7866L7.05852 18.3855H4.8223L10.038 12.4228L4.53754 5.22648H9.14282L12.3216 9.43055L15.9998 5.22648H18.2333L13.356 10.7998L19.0939 18.3846L14.6024 18.3855Z" fill="#333333" />
                      </svg>
                    </a> */}

                    <a target="_blank" rel="noopener" href="https://linkedin.com/in/escansystems">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                        height="23"
                        width="23"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 455 455"
                      >
                        <g>
                          <path
                            style={{ fillRule: "evenodd", clipRule: "evenodd" }}
                            d="M246.4,204.35v-0.665c-0.136,0.223-0.324,0.446-0.442,0.665H246.4z"
                          />
                          <path
                            style={{ fillRule: "evenodd", clipRule: "evenodd" }}
                            d="M0,0v455h455V0H0z M141.522,378.002H74.016V174.906h67.506V378.002z    M107.769,147.186h-0.446C84.678,147.186,70,131.585,70,112.085c0-19.928,15.107-35.087,38.211-35.087   c23.109,0,37.31,15.159,37.752,35.087C145.963,131.585,131.32,147.186,107.769,147.186z M385,378.002h-67.524V269.345   c0-27.291-9.756-45.92-34.195-45.92c-18.664,0-29.755,12.543-34.641,24.693c-1.776,4.34-2.24,10.373-2.24,16.459v113.426h-67.537   c0,0,0.905-184.043,0-203.096H246.4v28.779c8.973-13.807,24.986-33.547,60.856-33.547c44.437,0,77.744,29.02,77.744,91.398V378.002   z"
                          />
                        </g>
                      </svg>

                    </a>

                    <a target="_blank" rel="noopener" href="https://www.facebook.com/people/Earth-Scan-Systems/61573810942990/">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path d="M22.7994 11.8724C22.7994 17.321 18.8019 21.8381 13.5834 22.6578V15.0493H16.1208L16.6039 11.9015H13.5834V9.85905C13.5834 8.99749 14.0056 8.15868 15.3575 8.15868H16.7303V5.47848C16.7303 5.47848 15.4839 5.26559 14.293 5.26559C11.8057 5.26559 10.1809 6.77309 10.1809 9.50151V11.9006H7.41606V15.0484H10.1809V22.6569C4.9633 21.8362 0.964844 17.3201 0.964844 11.8724C0.964844 5.8433 5.85307 0.955078 11.8821 0.955078C17.9112 0.955078 22.7994 5.84239 22.7994 11.8724Z" fill="#333333" />
                      </svg>
                    </a>
                  </div>
                </div>    </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    // <div className="flex bg-no-repeat bg-top bg-cover" style={{ backgroundImage: `url(${FOOTER})` }}>

    //   <div class="w-full flex justify-center items-center">
    //     <div class="flex max-w-[808px] w-full px-5 md:px-10 md:max-w-[1104px] lg:max-w-[1736px] lg:px-[100px] 2xl:max-w-[1728px] 2xl:px-[150px]">

     
    // <Box
    //   sx={{
        
    //     bgcolor: Primary,
    //     color: THEME_TEXT_COLOR,
    //     color: THEME_TEXT_COLOR,fontFamily:'Gilroy-Medium',
    //     py: 3,
    //     borderTop: '1px solid',
    //     borderColor: 'divider',
    //   }}
    // >
    //   <Container maxWidth={false}
    //   sx={{
        
    //     width:
    //     windowSize.width < 992
    //       ? "100%"
    //       : windowSize.width < 1025
    //       ? "100%"
    //       : windowSize.width < 1960
    //       ? "100%"
    //       : "100%",
    //   }}
    //   >
    //     {/* <Grid container spacing={2} justifyContent="space-between">
    //       <Grid item xs={12} sm={6} md={3}>
    //         <Typography variant="h6" color={THEME_TEXT_COLOR} gutterBottom>
    //           Brand Name
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={6} sm={3} md={2}>
    //         <Typography variant="subtitle1" color={THEME_TEXT_COLOR} gutterBottom>
    //           PRODUCT
    //         </Typography>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>Features</Link>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>Integrations</Link>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>Pricing</Link>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>FAQ</Link>
    //       </Grid>
    //       <Grid item xs={6} sm={3} md={2}>
    //         <Typography variant="subtitle1" color={THEME_TEXT_COLOR} gutterBottom>
    //           COMPANY
    //         </Typography>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>About Us</Link>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>Careers</Link>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>Privacy Policy</Link>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>Terms of Service</Link>
    //       </Grid>
    //       <Grid item xs={6} sm={3} md={2}>
    //         <Typography variant="subtitle1" color={THEME_TEXT_COLOR} gutterBottom>
    //           DEVELOPERS
    //         </Typography>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>Public API</Link>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>Documentation</Link>
    //         <Link href="#" color="inherit" display="block" style={{color:'white'}}>Guides</Link>
    //       </Grid>
    //       <Grid item xs={6} sm={3} md={2}>
    //         <Typography variant="subtitle1" color={THEME_TEXT_COLOR} gutterBottom>
    //           SOCIAL MEDIA
    //         </Typography>
    //         <IconButton aria-label="Facebook" color="inherit" component="a" href={socialMediaLinks.facebook}>
    //           <FacebookIcon style={{color:'white'}} />
    //         </IconButton>
    //         <IconButton aria-label="Twitter" color="inherit" component="a" href={socialMediaLinks.twitter}>
    //           <TwitterIcon style={{color:'white'}}/>
    //         </IconButton>
    //         <IconButton aria-label="Instagram" color="inherit" component="a" href={socialMediaLinks.instagram}>
    //           <InstagramIcon style={{color:'white'}}/>
    //         </IconButton>
    //       </Grid>
    //     </Grid> */}
    //     <Grid container spacing={2} justifyContent="space-between">
    //       <Grid item xs={6} sm={6} md={6}>
    //         <Typography variant="body2" color={THEME_TEXT_COLOR} align="left" sx={{ pt: 1 }}>
    //           <strong>© {moment().year()} ESS. All rights reserved.</strong>
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={6} sm={6} md={6}  align="right" display="flex" justifyContent="space-evenly" >
    //         <Link href="/privacy-policy" color="inherit" display="block" style={{color:{THEME_TEXT_COLOR} , paddingTop: '4px',textDecoration:'none'}} >Privacy Policy</Link>
    //         <Link href="/how-to" color="inherit" display="block" style={{color:{THEME_TEXT_COLOR} , paddingTop: '4px',textDecoration:'none'}} >How to Use ESS Crop Monitoring?</Link>
    //         {/* <Link href="/how-to" color="inherit" display="block" style={{color:'white', paddingTop: '4px'}} >Contact </Link> */}
    //       </Grid>
          
    //     </Grid>
    //   </Container>
    // </Box>
    // </div>
    // </div></div>
  );
};

export default CustomFooter;