import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("token", null);
  const [email, setEmail] = useLocalStorage("email", null);
  const [plan, setPlan] = useLocalStorage("plan", null);
  const [status, setStatus] = useLocalStorage("status", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const loginAuth = async (data) => {
   // console.log("DATA",data)
    const token = data.token.replace(/['"]+/g, '')
    setUser(token);
    setEmail(data.email);
    setPlan(data.plan);
    setStatus(data.status);
     
    if(data.status == 1 || data.status == "1"){

      navigate("/plan-subscribe");
      
    }else{
 
      //navigate("/plan-subscribe");
      if(localStorage.getItem("track")==1 || localStorage.getItem("track")==2){
       // navigate( `/carbon-monitoring/${localStorage.getItem("track")}`);
       // localStorage.removeItem("track")
       navigate("/monitor");
      }else{
        navigate("/monitor");

      }

    }
   
  };


  const updateStatus = (newStatus) => {
    setStatus(newStatus);
  };

  // call this function to sign out logged in user
  const logoutAuth = () => {
    setUser(null);
    setStatus(null)
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      status,
      setStatus: updateStatus,
      loginAuth: loginAuth,
      logoutAuth: logoutAuth,
    }),
    [user,status]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};